/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';
import { Button, ButtonToolbar, CheckPicker, Form } from 'rsuite';

import PageBody from '../../../layout/PageBody/PageBody';

import { authStore } from '../../../../stores';

const AccountStyles = css`
  .Account {
    background: #fff;
    border-radius: 6px;
    padding: 15px;

    &-Label {
      width: 300px;
    }

    // &-Input {
    //   width: 300px;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    &-Button {
      width: 300px;
      // border: 2px solid #50fa7b;
      // color: blue;
      // background: none;
      // padding: 10px 15px;
      // border-radius: 6px;
      // outline: 0;
      // cursor: pointer;
      // font-weight: 600;
      // text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

interface IAccount {
  id: string | null;
  firstname: string | null;
  lastname: string | null;
  email: string | null;
  emailVerified: boolean | null;
  // updated: Date | null;
  // created: Date | null;
}

const initialState: IAccount = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  emailVerified: null,
  // updated: null,
  // created: null,
};

function Account() {
  const { isAuthenticated, authUser } = authStore;
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialState);

  console.log(authUser);

  const params = useParams();
  const userID = params?.userID || '';

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    setFormValue({
      ...formValue,
      id: authUser.attributes.sub,
      firstname: authUser.attributes.given_name,
      lastname: authUser.attributes.family_name,
      email: authUser.attributes.email,
      emailVerified: authUser.attributes.email_verified,
    });
  }, [authUser]);

  const onChangeAccount = () => {
    navigate(`/settings/${userID}/account/change-account`);
  };

  return (
    <PageBody
      headingText='Account Information'
      pageTitle='Application | DV Lottery Services'
      css={AccountStyles}
    >
      <div className='Account'>
        <Form
          ref={formRef}
          readOnly
          formValue={formValue}
          // layout='horizontal'
          className='Account-Form'
        >
          {/* {JSON.stringify(authUser)} */}
          <Form.Group controlId='id'>
            <Form.Control id='id' type='hidden' name='id' className='Account-Input' disabled />
          </Form.Group>
          <Form.Group controlId='firstname'>
            <Form.ControlLabel>Firstname</Form.ControlLabel>
            <Form.Control
              id='firstname'
              type='text'
              name='firstname'
              className='Account-Input'
              readOnly
            />
          </Form.Group>
          <Form.Group controlId='lastname'>
            <Form.ControlLabel>Lastname</Form.ControlLabel>
            <Form.Control
              id='lastname'
              type='text'
              name='lastname'
              className='Account-Input'
              readOnly
            />
          </Form.Group>
          <Form.Group controlId='email'>
            <Form.ControlLabel>Email</Form.ControlLabel>
            <Form.Control id='email' type='text' name='email' className='Account-Input' disabled />
          </Form.Group>
          <Form.Group controlId='emailVerified'>
            <Form.ControlLabel>Email Verified?</Form.ControlLabel>
            <Form.Control
              id='emailVerified'
              type='checkbox'
              checked={formValue.emailVerified === true}
              name='emailVerified'
              className='Account-Input'
              disabled
            />
            {!formValue.emailVerified && (
              <ButtonToolbar>
                <Button
                  type='button'
                  size='lg'
                  appearance='link'
                  className='Account-Button'
                  onClick={() => {
                    navigate(`/settings/${userID}/account/change-email/confirm`);
                  }}
                >
                  Verify email
                </Button>
              </ButtonToolbar>
            )}
          </Form.Group>
          {/* <Form.Group controlId='updated'>
            <Form.ControlLabel>Updated?</Form.ControlLabel>
            <Form.Control
              id='updated'
              type='date'
              name='updated'
              className='Account-Input'
              readOnly
            />
          </Form.Group>
          <Form.Group controlId='created'>
            <Form.ControlLabel>Created?</Form.ControlLabel>
            <Form.Control
              id='created'
              type='date'
              name='created'
              className='Account-Input'
              readOnly
            />
          </Form.Group> */}
          <Form.Group>
            <Button
              type='button'
              size='lg'
              appearance='primary'
              className='Account-Button'
              onClick={onChangeAccount}
            >
              Change Account Info
            </Button>
          </Form.Group>
          <Form.Group>
            <Button
              type='button'
              size='lg'
              appearance='primary'
              className='Account-Button'
              onClick={() => navigate(`/settings/${userID}/account/change-email/update`)}
            >
              Change Account Email
            </Button>
          </Form.Group>
          <Form.Group>
            <Button
              type='button'
              size='lg'
              appearance='primary'
              className='Account-Button'
              onClick={() => {
                navigate(`/settings/${userID}/account/change-password`);
              }}
            >
              Change Account Password
            </Button>
          </Form.Group>
        </Form>
        <br />
      </div>
    </PageBody>
  );
}

export default observer(Account);
