/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { Avatar, Button, Form, Placeholder } from 'rsuite';
import Uploader, { FileType } from 'rsuite/esm/Uploader';
import { Profile as ProfileModel } from '../../../../../../models';
import { Document } from '../../../../../elements/Document';
import { DocumentModel, fetchFiles } from '../../../../../../utils/DocumentUtils';

const ProfileStyles = css`
  // color: #fff;
  // background: #2a2c37;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #e5e5ea;

  .Profile {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

interface IProfileProps {
  data?: ProfileModel;
  order?: number;
  header?: any;
  toEdit?: string;
  plainText?: boolean;
}

const initialState: ProfileModel = {
  id: '',
  firstnameKH: null,
  lastnameKH: null,
  firstname: null,
  lastname: null,
  dateOfBirth: null,
  gender: null,
  driverLicenseNumber: null,
  driverLicenseDocument: null,
  nationalIdentificationNumber: null,
  nationalIdentificationDocument: null,
  passportNumber: null,
  passportDocument: null,
  passportIssueDate: null,
  passportExpirationDate: null,
  marriageStatus: null,
  marriageCertificateNumber: null,
  marriageCertificateDocument: null,
  numberOfDependent: null,
  passportPhotoDocument: null,
  otherDocuments: null,
  applicationID: '',
};

function Profile({
  data = initialState,
  order = 0,
  header = `Profile ${order + 1}`,
  toEdit = 'edit',
  plainText = false,
}: IProfileProps) {
  const [formValue, setFormValue] = useState(initialState);
  const [passportPhotoFiles, setPassportPhotoFiles] = useState([] as FileType[]);
  const [driverLicenseFiles, setDriverLicenseFiles] = useState([] as FileType[]);
  const [driverLicenseDocuments, setDriverLicenseDocuments] = useState([] as DocumentModel[]);
  const [nationalIdentificationFiles, setNationalIdentificationFiles] = useState([] as FileType[]);
  const [nationalIdentificationDocuments, setNationalIdentificationDocuments] = useState(
    [] as DocumentModel[],
  );
  const [passportFiles, setPassportFiles] = useState([] as FileType[]);
  const [passportDocuments, setPassportDocuments] = useState([] as DocumentModel[]);
  const [marriageCertificateFiles, setMarriageCertificateFiles] = useState([] as FileType[]);
  const [marriageCertificateDocuments, setMarriageCertificateDocuments] = useState(
    [] as DocumentModel[],
  );
  const [otherFiles, setOtherFiles] = useState([] as FileType[]);
  const [otherDocuments, setOtherDocuments] = useState([] as DocumentModel[]);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    // get docs
    const passportPhotoDocuments =
      data?.passportPhotoDocument === undefined || data?.passportPhotoDocument === null
        ? ([] as DocumentModel[])
        : [data?.passportPhotoDocument as DocumentModel];

    const driverLicenseDocuments =
      data?.driverLicenseDocument === undefined || data?.driverLicenseDocument === null
        ? ([] as DocumentModel[])
        : [data?.driverLicenseDocument as DocumentModel];

    const nationalIdentificationDocuments =
      data?.nationalIdentificationDocument === undefined ||
      data?.nationalIdentificationDocument === null
        ? ([] as DocumentModel[])
        : [data?.nationalIdentificationDocument as DocumentModel];

    const passportDocuments =
      data?.passportDocument === undefined || data?.passportDocument === null
        ? ([] as DocumentModel[])
        : [data?.passportDocument as DocumentModel];

    const marriageCertificateDocuments =
      data?.marriageCertificateDocument === undefined || data?.marriageCertificateDocument === null
        ? ([] as DocumentModel[])
        : [data?.marriageCertificateDocument as DocumentModel];

    const otherDocuments =
      data?.otherDocuments === undefined || data?.otherDocuments === null
        ? ([] as DocumentModel[])
        : (data?.otherDocuments as DocumentModel[]);

    // fetch stored files
    fetchFiles(passportPhotoDocuments).then((apiFiles) => {
      setPassportPhotoFiles(apiFiles);
    });

    fetchFiles(driverLicenseDocuments).then((apiFiles) => {
      setDriverLicenseFiles(apiFiles);
    });

    fetchFiles(nationalIdentificationDocuments).then((apiFiles) => {
      setNationalIdentificationFiles(apiFiles);
    });

    fetchFiles(passportDocuments).then((apiFiles) => {
      setPassportFiles(apiFiles);
    });

    fetchFiles(marriageCertificateDocuments).then((apiFiles) => {
      setMarriageCertificateFiles(apiFiles);
    });

    fetchFiles(otherDocuments).then((apiFiles) => {
      setOtherFiles(apiFiles);
    });

    // set documents and form states
    setDriverLicenseDocuments(driverLicenseDocuments);
    setNationalIdentificationDocuments(nationalIdentificationDocuments);
    setPassportDocuments(passportDocuments);
    setMarriageCertificateDocuments(marriageCertificateDocuments);
    setOtherDocuments(otherDocuments);

    // set form values
    setFormValue(data);
  }, [data, order]);

  const onEdit = () => {
    navigate(toEdit, { state: { data } });
  };

  if (formValue === ({} as ProfileModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={ProfileStyles}>
      <Form ref={formRef} readOnly formValue={formValue} fluid>
        <h4>{header}</h4>
        {/* {JSON.stringify(formValue)} */}
        <Form.Group controlId='id'>
          <Form.Control id='id' type='hidden' name='id' className='Profile-Input' />
        </Form.Group>
        <Form.Group controlId='passportPhotoDocument'>
          <Form.ControlLabel>Passport Photo:</Form.ControlLabel>
          {passportPhotoFiles && passportPhotoFiles.length > 0 ? (
            <img src={passportPhotoFiles[0]?.url} alt='passportPhoto' style={{ width: '200px' }} />
          ) : (
            <Placeholder.Graph width={200} height={200} active />
          )}
        </Form.Group>
        <Form.Group controlId='firstname'>
          <Form.ControlLabel>Firstname (EN):</Form.ControlLabel>
          <Form.Control id='firstname' type='text' name='firstname' className='Profile-Input' />
        </Form.Group>
        <Form.Group controlId='lastname'>
          <Form.ControlLabel>Lastname (EN):</Form.ControlLabel>
          <Form.Control id='lastname' type='text' name='lastname' className='Profile-Input' />
        </Form.Group>
        <Form.Group controlId='firstnameKH'>
          <Form.ControlLabel>Firstname (KH):</Form.ControlLabel>
          <Form.Control id='firstnameKH' type='text' name='firstnameKH' className='Profile-Input' />
        </Form.Group>
        <Form.Group controlId='lastnameKH'>
          <Form.ControlLabel>Lastname (KH):</Form.ControlLabel>
          <Form.Control id='lastnameKH' type='text' name='lastnameKH' className='Profile-Input' />
        </Form.Group>
        <Form.Group controlId='dateOfBirth'>
          <Form.ControlLabel>DOB:</Form.ControlLabel>
          <Form.Control id='dateOfBirth' type='date' name='dateOfBirth' className='Profile-Input' />
        </Form.Group>
        <Form.Group controlId='gender'>
          <Form.ControlLabel>Gender:</Form.ControlLabel>
          <Form.Control id='gender' type='text' name='gender' className='Profile-Input' />
        </Form.Group>
        <Form.Group controlId='driverLicenseNumber'>
          <Form.ControlLabel>Driver License Number:</Form.ControlLabel>
          <Form.Control
            id='driverLicenseNumber'
            type='text'
            name='driverLicenseNumber'
            className='Profile-Input'
          />
        </Form.Group>
        <Form.Group controlId='driverLicenseDocument'>
          <Form.ControlLabel>Driver License File:</Form.ControlLabel>
          <Document documents={driverLicenseDocuments} files={driverLicenseFiles} />
        </Form.Group>
        <Form.Group controlId='nationalIdentificationNumber'>
          <Form.ControlLabel>National Identification Number:</Form.ControlLabel>
          <Form.Control
            id='nationalIdentificationNumber'
            type='text'
            name='nationalIdentificationNumber'
            className='Profile-Input'
          />
        </Form.Group>
        <Form.Group controlId='nationalIdentificationDocument'>
          <Form.ControlLabel>National Identification File:</Form.ControlLabel>
          <Document
            documents={nationalIdentificationDocuments}
            files={nationalIdentificationFiles}
          />
        </Form.Group>
        <Form.Group controlId='passportNumber'>
          <Form.ControlLabel>Passport Number:</Form.ControlLabel>
          <Form.Control
            id='passportNumber'
            type='text'
            name='passportNumber'
            className='Profile-Input'
          />
        </Form.Group>
        <Form.Group controlId='passportDocument'>
          <Form.ControlLabel>Passport File:</Form.ControlLabel>
          <Document documents={passportDocuments} files={passportFiles} />
        </Form.Group>
        <Form.Group controlId='passportIssueDate'>
          <Form.ControlLabel>Passport Issue Date:</Form.ControlLabel>
          <Form.Control
            id='passportIssueDate'
            type='date'
            name='passportIssueDate'
            className='Profile-Input'
          />
        </Form.Group>
        <Form.Group controlId='passportExpirationDate'>
          <Form.ControlLabel>Passport Expiration Date:</Form.ControlLabel>
          <Form.Control
            id='passportExpirationDate'
            type='date'
            name='passportExpirationDate'
            className='Profile-Input'
          />
        </Form.Group>
        <Form.Group controlId='marriageStatus'>
          <Form.ControlLabel>Marriage Status:</Form.ControlLabel>
          <Form.Control
            id='marriageStatus'
            type='text'
            name='marriageStatus'
            className='Profile-Input'
          />
        </Form.Group>
        <Form.Group controlId='marriageCertificateNumber'>
          <Form.ControlLabel>Marriage Certificate Number:</Form.ControlLabel>
          <Form.Control
            id='marriageCertificateNumber'
            type='text'
            name='marriageCertificateNumber'
            className='Profile-Input'
          />
        </Form.Group>
        <Form.Group controlId='marriageCertificateDocument'>
          <Form.ControlLabel>Marriage Certificate File:</Form.ControlLabel>
          <Document documents={marriageCertificateDocuments} files={marriageCertificateFiles} />
        </Form.Group>
        <Form.Group controlId='numberOfDependent'>
          <Form.ControlLabel>Number of Dependent:</Form.ControlLabel>
          <Form.Control
            id='numberOfDependent'
            type='text'
            name='numberOfDependent'
            className='Profile-Input'
          />
        </Form.Group>
        <Form.Group controlId='otherDocuments'>
          <Form.ControlLabel>Other Documents:</Form.ControlLabel>
          <Document documents={otherDocuments} files={otherFiles} />
        </Form.Group>
        {!plainText && (
          <Form.Group>
            <Button type='button' appearance='primary' className='Profile-Button' onClick={onEdit}>
              Edit Profile
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}

export default Profile;
