/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';

import MainLayout from '../../layout/MainLayout/MainLayout';
import PageBody from '../../layout/PageBody/PageBody';

const NotFoundStyles = css`
  .NotFound {
    width: 100%;
    padding: 30px;
    min-height: 100vh;
  }
`;

function NotFound() {
  return (
    <PageBody
      headingText='Not Found'
      pageTitle='Not Found | DV Lottery Services'
      css={NotFoundStyles}
    >
      <div className='NotFound'>This page does not exist!</div>
    </PageBody>
  );
}

export default NotFound;
