/** @jsxImportSource @emotion/react */
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';
import { authStore } from '../../../../../stores';

import MainLayout from '../../../../layout/MainLayout/MainLayout';
import PageBody from '../../../../layout/PageBody/PageBody';

const DocumentsStyles = css`
  .Documents {
    width: 100%;
    padding: 30px;
    background: #fff;

    &-Heading = {
      height: 100;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

function Documents() {
  const { isAuthenticated } = authStore;
  return (
    <PageBody
      headingText='Documents'
      pageTitle='Documents | DV Lottery Services'
      css={DocumentsStyles}
    >
      <div className='Documents'> Documents</div>
    </PageBody>
  );
}

export default observer(Documents);
