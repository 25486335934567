/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';
import { Button, Panel, PanelGroup, Placeholder } from 'rsuite';

// dataStore
import { DataStore } from 'aws-amplify';
import { Case as CaseModel, Application as ApplicationModel } from '../../../../../models';

// store
import { authStore } from '../../../../../stores';

// components
import LoaderMessage from '../../../../elements/LoaderMessage/LoaderMessage';
import PageBody from '../../../../layout/PageBody/PageBody';
import Case from '../Case';

const SettingsStyles = css`
  width: 100%;
  .Settings {
    background: #fff;
    &-Heading = {
      height: 100;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

function Settings() {
  const { authUser } = authStore;
  const [isLoading, setIsLoading] = useState(true);
  const [extendedCase, setExtendedCase] = useState({} as CaseModel);

  const params = useParams();
  const userID = params?.userID || '';
  const caseID = params?.caseID || '';

  const navigate = useNavigate();

  const location = useLocation();

  // queries
  const fetchCases = async () => {
    let data: CaseModel[] = [];
    try {
      data = (await DataStore.query(CaseModel)).filter((c) => c.id === caseID);
    } catch (error) {
      console.log('fetch cases error:', error);
      navigate(-1);
    }
    return data;
  };

  const fetchApplications = async () => {
    let data: ApplicationModel[] = [];
    try {
      data = (await DataStore.query(ApplicationModel)).filter((a) => a.caseID === caseID);
      return data;
    } catch (error) {
      console.log('fetch applications error:', error);
      navigate(-1);
    }
    return data;
  };

  const fetchData = async () => {
    try {
      const cases = await fetchCases();
      const applications = await fetchApplications();
      setExtendedCase({ ...cases[0], Applications: applications });
    } catch (error) {
      console.log('fetch data error:', error);
      navigate(-1);
    }
  };

  // subscriptions
  const subscriptions = () => {
    // case
    const caseSubscription = DataStore.observe(CaseModel).subscribe(() => fetchData());
    const applicationSubscription = DataStore.observe(ApplicationModel).subscribe(() =>
      fetchData(),
    );
    return [caseSubscription, applicationSubscription];
  };

  // fetch data on componentmount and location change
  useEffect(() => {
    setIsLoading(true);
    try {
      fetchData();
    } catch (error) {
      console.log('fetch data error:', error);
    }
    setIsLoading(false);
  }, [location]);

  // call data subcriptions / unsubscriptions
  useEffect(() => {
    // when component did mount
    const subs = subscriptions();

    // when component did unmount
    return () => {
      subs.forEach((s: any) => s.unsubscribe());
    };
  }, []);

  return (
    <PageBody
      headingText='Case Settings'
      pageTitle='Case | DV Lottery Services'
      css={SettingsStyles}
    >
      <div className='Settings'>
        {!isLoading && (
          <Case
            data={extendedCase}
            header='Settings'
            toEdit={`/dashboard/${userID}/cases/${extendedCase.id}/edit`}
          />
        )}
        <LoaderMessage
          loadingMessage='Loading case data'
          doneMessage='Loading case data complete'
          isLoading={isLoading}
        />
      </div>
    </PageBody>
  );
}

export default observer(Settings);
