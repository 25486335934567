/** @jsxImportSource @emotion/react */
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { css, jsx } from '@emotion/react';

// ui-library
import 'rsuite/styles/index.less';
import { authStore } from '../../../../../stores';

// components
import PageBody from '../../../../layout/PageBody/PageBody';

const TasksStyles = css`
  .Tasks {
    width: 100%;
    padding: 30px;
    background: #c5c6c7;

    &-Heading = {
      height: 100;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

function Tasks() {
  const { isAuthenticated } = authStore;
  return (
    <PageBody headingText='Tasks' pageTitle='Tasks | DV Lottery Services'>
      <div className='Tasks'>About Content</div>
    </PageBody>
  );
}

export default observer(Tasks);
