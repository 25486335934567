/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { Button, CheckPicker, Form } from 'rsuite';
import { Application as ApplicationModel } from '../../../../../models';

const ApplicationStyles = css`
  // color: #fff;
  // background: #2a2c37;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #e5e5ea;

  .Application {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: upperApplication;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

const selectData = ['main', 'spouse', 'children', 'parents', 'siblings', 'fiance'].map((item) => ({
  label: item,
  value: item,
}));

interface IApplicationProps {
  data?: ApplicationModel;
  order?: number;
  header?: any;
  toEdit?: string;
  plainText?: boolean;
}

const initialState: ApplicationModel = {
  id: '',
  type: '',
  caseID: '',
  Contacts: null,
  Relationships: null,
  Profiles: null,
  Educations: null,
  Employments: null,
  Addresses: null,
};

function Application({
  data = initialState,
  order = 0,
  header = `Application ${order + 1}`,
  toEdit = 'edit',
  plainText = false,
}: IApplicationProps) {
  const [formValue, setFormValue] = useState(initialState);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    setFormValue(data);
  }, [data, order]);

  const onEdit = () => {
    navigate(toEdit, { state: { data } });
  };

  if (formValue === ({} as ApplicationModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={ApplicationStyles}>
      <Form ref={formRef} readOnly formValue={formValue} fluid>
        <h4>{header}</h4>
        <Form.Group controlId='id'>
          <Form.ControlLabel>Id:</Form.ControlLabel>
          <Form.Control id='id' type='text' name='id' className='Application-Input' />
        </Form.Group>
        <Form.Group controlId='type'>
          <Form.ControlLabel>Type:</Form.ControlLabel>
          <Form.Control id='type' type='text' name='type' className='Application-Input' />
        </Form.Group>
        <Form.Group controlId='caseID'>
          <Form.ControlLabel>Case ID:</Form.ControlLabel>
          <Form.Control id='caseID' type='text' name='caseID' className='Application-Input' />
        </Form.Group>
        {!plainText && (
          <Form.Group>
            <Button
              type='button'
              appearance='primary'
              className='Application-Button'
              onClick={onEdit}
            >
              Edit Application
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}

export default Application;
