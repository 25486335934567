// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ServiceType = {
  "TYPE1": "TYPE1",
  "TYPE2": "TYPE2",
  "TYPE3": "TYPE3",
  "TYPE4": "TYPE4",
  "TYPE5": "TYPE5",
  "TYPE6": "TYPE6",
  "TYPE7": "TYPE7",
  "TYPE8": "TYPE8",
  "TYPE9": "TYPE9",
  "TYPE10": "TYPE10"
};

const { Relationship, Profile, Payment, Touchpoint, Case, Application, Contact, Education, Employment, Address, Deadline, Billing, Service, S3Object, Document } = initSchema(schema);

export {
  Relationship,
  Profile,
  Payment,
  Touchpoint,
  Case,
  Application,
  Contact,
  Education,
  Employment,
  Address,
  Deadline,
  Billing,
  Service,
  ServiceType,
  S3Object,
  Document
};