/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { css, jsx } from '@emotion/react';

// ui-library
import 'rsuite/styles/index.less';
import { AutoComplete, Input, InputGroup, Panel, PanelGroup } from 'rsuite';
import SearchIcon from '@rsuite/icons/legacy/Search';

// dataStore
import { DataStore } from 'aws-amplify';

// components
import PageBody from '../../layout/PageBody/PageBody';

const FaqsStyles = css`
  .Faqs {
    width: 100%;
    padding-bottom: 50px;
    background: #fff;
    min-height: 100vh;
    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }

    &-Search {
      min-height: 400px;
      border: 2px solid #e5e5ea;
      margin-bottom: 20px;
      padding: 50px;
      background: gray;
      text-align: left;
      width: 100%;
      clear: right;

      &-Header {
        font-size: 70px;
        color: white;
        padding-bottom: 20px;
      }

      &-Input {
        width: 50%;
      }
    }

    &-PanelGroup {
      width: 100%;
      text-align: left;
    }
  }
`;

export interface IQuestion {
  question: string;
  answer: string;
}

const initialState: IQuestion[] = [
  {
    question: 'What is the Diversity Lottery or DV Lottery program?',
    answer:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
  },
  {
    question: 'Who is eligible for the DV Lottery program?',
    answer:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
  },
  {
    question: 'What is the cost for participating in the DV Lottery program?',
    answer:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
  },
  {
    question: 'What is the cost for participating in the DV Lottery program?',
    answer:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
  },
  {
    question: 'What is the cost for participating in the DV Lottery program?',
    answer:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
  },
];

function Faqs() {
  const [questions, setQuestions] = useState(initialState);
  const [search, setSearch] = useState('');

  // // queries
  // const fetchQuestions = async () => {
  //   try {
  //     const data = await DataStore.query(QuestionModel);
  //     setQuestions(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // // subscriptions
  // const subscriptions = async () => {
  //   const questionSubscription = DataStore.observe(QuestionModel).subscribe(() => fetchQuestions());

  //   return [questionSubscription];
  // };

  // useEffect(() => {
  //   fetchQuestions();
  // }, []);

  // useEffect(() => {
  //   // when component did mount
  //   const subs = subscriptions();

  //   // when component did unmount
  //   return () => {
  //     subs.forEach((s: any) => s.unsubscribe());
  //   };
  // }, []);

  const onSearch = (q: IQuestion) => {
    const val = `${q.question} ${q.answer}`.toLocaleLowerCase();
    return val.includes(search.toLocaleLowerCase());
  };

  return (
    <PageBody headingText='FAQs' pageTitle='FAQs | DV Lottery Services' css={FaqsStyles}>
      <div className='Faqs'>
        <div className='Faqs-Search'>
          <span className='Faqs-Search-Header'>How can we help?</span>
          <InputGroup className='Faqs-Search-Input'>
            <Input
              type='text'
              placeholder='Search Questions'
              size='lg'
              onChange={(e) => setSearch(e)}
            />
            <InputGroup.Button>
              <SearchIcon />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <PanelGroup accordion bordered className='Faqs-PanelGroup'>
          {questions
            .filter((q: IQuestion) => onSearch(q))
            .map((question: IQuestion) => (
              <Panel header={question.question}>{question.answer}</Panel>
            ))}
        </PanelGroup>
      </div>
    </PageBody>
  );
}

export default observer(Faqs);
