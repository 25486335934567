/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { Button, Form, Stack } from 'rsuite';
import { Contact as ContactModel } from '../../../../../../models';

const ContactStyles = css`
  // color: #fff;
  // background: #2a2c37;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #e5e5ea;

  .Contact {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   // color: #fff;
    //   // background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   border: 1px solid;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }

    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }

    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

interface IContactProps {
  data?: ContactModel;
  order?: number;
  header?: any;
  toEdit?: string;
  plainText?: boolean;
}

const initialState: ContactModel = {
  id: '',
  email: '',
  phone: '',
  website: '',
  applicationID: '',
};

function Contact({
  data = initialState,
  order = 0,
  header = `Contact ${order + 1}`,
  toEdit = 'edit',
  plainText = false,
}: IContactProps) {
  const [formValue, setFormValue] = useState(initialState);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    setFormValue(data);
  }, [data, order]);

  const onEdit = () => {
    navigate(toEdit, { state: { data } });
  };

  if (formValue === ({} as ContactModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={ContactStyles}>
      <Form ref={formRef} readOnly formValue={formValue} fluid>
        <h4>{header}</h4>
        <Form.Group controlId='id'>
          <Form.Control id='id' type='hidden' name='id' className='Contact-Input' />
        </Form.Group>
        <Form.Group controlId='email'>
          <Form.ControlLabel>Email:</Form.ControlLabel>
          <Form.Control id='email' type='text' name='email' className='Contact-Input' />
        </Form.Group>
        <Form.Group controlId='phone'>
          <Form.ControlLabel>Phone:</Form.ControlLabel>
          <Form.Control id='phone' type='text' name='phone' className='Contact-Input' />
        </Form.Group>
        <Form.Group controlId='website'>
          <Form.ControlLabel>Website:</Form.ControlLabel>
          <Form.Control id='website' type='text' name='website' className='Contact-Input' />
        </Form.Group>
        {!plainText && (
          <Form.Group>
            <Button type='button' appearance='primary' className='Contact-Button' onClick={onEdit}>
              Edit Contact
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}

export default Contact;
