/** @jsxImportSource @emotion/react */
import React, { Ref, useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Button, ButtonToolbar, Form, Modal, Schema, Uploader } from 'rsuite';
import { FileType } from 'rsuite/esm/Uploader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// import amplify
import { DataStore } from 'aws-amplify';
import { Education as EducationModel } from '../../../../../../models';

// store
import { authStore } from '../../../../../../stores';

import {
  DocumentModel,
  fetchFiles,
  handleFiles,
  deleteFiles,
  updateDocuments,
} from '../../../../../../utils/DocumentUtils';

const EducationEditStyles = css`
  .EducationEdit {
    color: #fff;
    background: #2a2c37;
    border-radius: 6px;
    padding: 15px;

    &-Label {
      width: 100%;
    }

    &-Input {
      width: 100%;
      border: 1px solid transparent;
      color: #fff;
      background: #1d1e26;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 6px;
      outline: 0;
      &:focus {
        border-color: #50fa7b;
      }
    }
    &-Textarea {
      min-height: 80px;
      resize: none;
    }
    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

interface IEducationEditProps {
  isEdit: boolean;
}

const initialFileState: FileType[] = [
  {
    name: 'Capture.JPG',
    fileKey: '_lp0lth4zyk',
    url: 'example.com',
  },
];

const initialState: EducationModel = {
  id: '',
  degree: null,
  degreeDocument: null,
  institution: null,
  dateStart: null,
  dateEnd: null,
  applicationID: '',
};

const model = Schema.Model({
  degree: Schema.Types.StringType().isRequired('This field is required.'),
  dateStart: Schema.Types.DateType().isRequired('This field is required.'),
  dateEnd: Schema.Types.DateType(),
  institution: Schema.Types.StringType().isRequired('This field is required.'),
  applicationID: Schema.Types.StringType().isRequired('This field is required.'),
});

function EducationEdit({ isEdit }: IEducationEditProps) {
  const { isAuthenticated, authUser } = authStore;

  const [isLoading, setIsLoading] = useState('null');
  const [formValue, setFormValue] = useState({} as EducationModel);
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [files, setFiles] = useState([] as FileType[]);
  const [documents, setDocuments] = useState([] as DocumentModel[]);
  const [documentsStored, setDocumentsStored] = useState([] as DocumentModel[]);
  const [filesStored, setFilesStored] = useState([] as FileType[]);

  const params = useParams();
  const applicationID = params?.applicationID || '';

  const { state } = useLocation();

  const data: EducationModel = state?.data;

  const navigate = useNavigate();

  const formRef = useRef() as any;

  const uploaderRef: Ref<any> = useRef();

  useEffect(() => {
    if (!isEdit) {
      setFormValue({ ...initialState, applicationID });
    } else {
      const documents: DocumentModel[] = [];
      const document = data?.degreeDocument as DocumentModel;
      if (document !== undefined && document !== null) {
        documents.push(document);
      }
      // fetch stored files
      fetchFiles(documents).then((apiFiles) => {
        setFiles(apiFiles);
        setFilesStored(apiFiles);
      });

      // set documents
      setDocumentsStored(documents);
      setDocuments(documents);

      // set form values
      setFormValue(data);
    }
  }, [data, applicationID]);

  const onChange = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const onCheck = (error: any) => {
    if (Object.keys(error).length > 0) {
      setDisabled(true);
      setFormError({ ...formError, ...error });
    } else {
      setDisabled(false);
    }
  };

  const handlerError = (error: any) => {
    window.alert(`Error occurs: ${JSON.stringify(error)}`);
  };

  const onCreate = async (e: any) => {
    // upload files
    await handleFiles(isEdit, files, filesStored, documents);

    formRef.current.check(async (error: any) => {
      // check error
      if (Object.keys(error).length > 0) {
        setDisabled(true);
        setFormError({ ...formError, ...error });
        return;
      }
      // create
      try {
        // handle store
        await DataStore.save(new EducationModel({ ...formValue, degreeDocument: documents[0] }));
        setDisabled(false);
        setModalOpen(false);
        console.log('A contact was successfully created');

        // navigate
        navigate(-1);
      } catch (error) {
        handlerError(error);
      }
    });
  };

  const onClear = () => {
    if (!window.confirm(`Really clear the form`)) {
      return;
    }
    setFormValue(initialState);
  };

  const onCancelCreate = () => {
    if (!window.confirm(`Really cancel the form creation`)) {
      return;
    }
    setFormValue(initialState);
    setModalOpen(false);
    navigate(-1);
  };

  const onUpdate = async () => {
    // check form on update
    if (!formRef.current.check()) {
      return;
    }

    // update files
    await handleFiles(isEdit, files, filesStored, documents);

    // update formValues
    try {
      /* Models in DataStore are immutable. To update a record you must use the copyOf function
      to apply updates to the item’s fields rather than mutating the instance directly */
      const currentItem: any = await DataStore.query(EducationModel, formValue.id);
      await DataStore.save(
        EducationModel.copyOf(currentItem, (updated) => {
          updated.degree = formValue.degree;
          updated.degreeDocument = documents[0];
          updated.institution = formValue.institution;
          updated.dateStart = formValue.dateStart;
          updated.dateEnd = formValue.dateEnd;
        }),
      );
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onCancelUpdate = () => {
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onDelete = async () => {
    // delete files
    await deleteFiles(filesStored);

    // confirm action
    if (!window.confirm(`Really delete ${formValue.id}`)) {
      return;
    }
    // delete
    try {
      const modelToDelete: any = await DataStore.query(EducationModel, formValue.id);
      DataStore.delete(modelToDelete);
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onModalClose = () => {
    if (!window.confirm(`Really want to close the modal`)) {
      return;
    }
    setFormValue(initialState);
    setModalOpen(false);
    navigate(-1);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  if (formValue === ({} as EducationModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={EducationEditStyles}>
      <Modal open={modalOpen} onClose={onModalClose} size='md'>
        <Modal.Header>
          <Modal.Title>
            {isEdit ? 'Edit an existing Education' : 'Create a new Education'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={onChange}
            onCheck={onCheck}
            model={model}
            fluid
          >
            <h2>Education</h2>
            <Form.Group controlId='id'>
              <Form.Control id='id' type='hidden' name='id' className='EducationEdit-Input' />
            </Form.Group>
            <Form.Group controlId='degree'>
              <Form.ControlLabel>Degree:</Form.ControlLabel>
              <Form.Control id='degree' type='text' name='degree' className='Education-Input' />
            </Form.Group>
            <Form.Group controlId='degreeDocuments'>
              <Form.ControlLabel>Degree Certificate:</Form.ControlLabel>
              <Form.Control
                id='degreeDocuments'
                type='hidden'
                name='degreeDocuments'
                className='Education-Input'
              />
              <Uploader
                fileList={files}
                autoUpload={false}
                action='/#'
                onChange={(newFiles: FileType[]) => {
                  const newDocuments = updateDocuments(newFiles, documents);
                  setFiles(newFiles);
                  setDocuments(newDocuments);
                }}
                ref={uploaderRef}
                disableMultipart
                multiple={false}
              />
            </Form.Group>
            <Form.Group controlId='institution'>
              <Form.ControlLabel>Institution:</Form.ControlLabel>
              <Form.Control
                id='institution'
                type='text'
                name='institution'
                className='Education-Input'
              />
            </Form.Group>
            <Form.Group controlId='dateStart'>
              <Form.ControlLabel>Date Start:</Form.ControlLabel>
              <Form.Control
                id='dateStart'
                type='date'
                name='dateStart'
                className='Education-Input'
              />
            </Form.Group>
            <Form.Group controlId='dateEnd'>
              <Form.ControlLabel>Date End:</Form.ControlLabel>
              <Form.Control id='dateEnd' type='date' name='dateEnd' className='Education-Input' />
            </Form.Group>
            <Form.Group controlId='applicationID'>
              <Form.ControlLabel>Applcation ID:</Form.ControlLabel>
              <Form.Control
                id='applicationID'
                type='text'
                name='applicationID'
                className='Education-Input'
                disabled
              />
            </Form.Group>
            {!isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`EducationEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onCreate}
                    disabled={disabled}
                  >
                    Create
                  </Button>
                  <Button type='button' className='EducationEdit-Button' onClick={onClear}>
                    Clear
                  </Button>
                  <Button type='button' className='EducationEdit-Button' onClick={onCancelCreate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}

            {isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`EducationEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onUpdate}
                    disabled={disabled}
                  >
                    Update
                  </Button>
                  <Button type='button' className='EducationEdit-Button' onClick={onDelete}>
                    Delete
                  </Button>
                  <Button type='button' className='EducationEdit-Button' onClick={onCancelUpdate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EducationEdit;
