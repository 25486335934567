/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';

import PageBody from '../../layout/PageBody/PageBody';

const AboutStyles = css`
  .About {
    background: white;
    min-height: 100vh;
  }
`;

function About() {
  return (
    <PageBody headingText='About' css={AboutStyles}>
      <div className='About'>About Content</div>
    </PageBody>
  );
}

export default About;
