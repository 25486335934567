/** @jsxImportSource @emotion/react */
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { css, jsx } from '@emotion/react';

// ui-library
import 'rsuite/styles/index.less';
import { Col, Panel, Row } from 'rsuite';

// components
import MainLayout from '../../layout/MainLayout/MainLayout';
import PageBody from '../../layout/PageBody/PageBody';

const HomeStyles = css`
  .Home {
    width: 100%;
    padding: 30px;
    background: #fff;
    min-height: 100vh;
    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }

    &-MainText {
      font: 30px strong;
      text-align: justify;
      padding-bottom: 20px;
    }

    &-Panel-Card {
      width: 100%;
      display: 'inline-block';
      text-align: left;

      &-Header {
        // font: 24px strong;
        // color: rgb(0, 56, 117);
      }
    }
  }
`;

function Home() {
  const styles = {
    content: {
      padding: '1em',
      minHeight: '700px',
      maxWidth: '1280px',
      margin: '0 auto',
      textAlign: 'center',
      justifyContent: 'center',
      maintext: {
        fontSize: '32px',
      },
      subtext: {
        paddingTop: '1em',
        paddingBottom: '1em',
        fontSize: '32px',
      },
    },
  };
  return (
    <PageBody headingText='Home' pageTitle='Home | DV Lottery Services' css={HomeStyles}>
      <div className='Home'>
        <Row>
          <div className='Home-MainText'>
            Welcome to the U.S. Diversity Lottery Services! We are a private service with an aim to
            provide assistance to all needing candidates with the entire DV Lottery process. We are
            assembled by a team of experts with experience and knowledge in the field from both the
            US and Cambodia.
          </div>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <Panel shaded bordered bodyFill className='Home-Panel-Card'>
              <img
                src={`${process.env.PUBLIC_URL}/front_page_image_1.jpg`}
                alt='newApplicant'
                width='100%'
              />
              <Panel header='New Applicant'>
                <p>
                  <small>
                    A suite of React components, sensible UI design, and a friendly development
                    experience.
                  </small>
                </p>
              </Panel>
            </Panel>
          </Col>
          <Col md={12} sm={12}>
            <Panel shaded bordered bodyFill className='Home-Panel-Card'>
              <img
                src={`${process.env.PUBLIC_URL}/front_page_image_2.jpg`}
                alt='newApplicant'
                width='100%'
              />
              <Panel header='Existing Applicant'>
                <p>
                  <small>
                    A suite of React components, sensible UI design, and a friendly development
                    experience.
                  </small>
                </p>
              </Panel>
            </Panel>
          </Col>
        </Row>
      </div>
    </PageBody>
  );
}
export default observer(Home);
