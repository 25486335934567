import { FileType } from "rsuite/esm/Uploader";
import { v4 as uuidv4 } from 'uuid';
import { S3Object } from '../models';
import { amplifyGetSignedURL, amplifyRemoveFile, amplifyUploadFile } from "../services/FileService";
import awsExports from '../aws-exports.js';

const { aws_user_files_s3_bucket: bucket, aws_user_files_s3_bucket_region: region } = awsExports;

// ********************************
// File
// ********************************

export type DocumentModel = {
  id: string;
  filename: string;
  file?: S3Object | null;
  success?: boolean | null;
};

export const createDocument = (file: FileType, s3Object?: S3Object) => {
  const document: {
    id: string;
    filename: string;
    file?: S3Object | null;
    success?: boolean | null;
  } = { id: '', filename: '' };

  document.id = s3Object?.key || uuidv4();
  document.filename = file?.name || '';
  document.file = s3Object || null;
  document.success = null;
  return document;
};

export const updateDocuments = (newFiles: FileType[], documents: DocumentModel[]): DocumentModel[] => {

  const newDocuments = (() => {
    if (documents.length > newFiles.length) {
      // remove a file
      return documents.filter((d) => newFiles.findIndex((f) => f.fileKey === d.id) > -1);
    }

    // add a file
    const newFile = newFiles.find((f) => documents.findIndex((d) => d.id === f.fileKey) === -1);
    const newS3Object: S3Object = {
      key: newFile !== undefined ? `${newFile.fileKey}` : uuidv4(),
      bucket,
      region,
    };

    if (newFile) {
      const newDocument = createDocument(newFile, newS3Object);
      return [...documents, newDocument];
    }

    return documents;
  })();

  return newDocuments;
};

export const fetchFiles = async (documents: DocumentModel[]): Promise<FileType[]> => {
  let apiFiles: FileType[] = [];

  if (documents.length > 0) {
    // fetch files from storage
    apiFiles = await Promise.all(
      documents.map(async (document: DocumentModel) => {
        const file: FileType = {};
        file.fileKey = document.id;
        file.name = document.filename;
        file.status = 'inited';
        file.progress = 100;
        file.url = await amplifyGetSignedURL(document.file || ({} as S3Object));
        return file;
      }),
    );
  }

  return apiFiles;
};

export const handleFiles = async ( isEdit: boolean, files:FileType[], filesStored: FileType[], documents: DocumentModel[]) => {
  let filesToUpload: FileType[] = [];
  let filesToDelete: FileType[] = [];

  // prepare files to upload/delete
  if (isEdit) {
    // if edit an existing form
    filesToDelete = filesStored.filter(
      (fileUploaded: FileType) =>
        files.findIndex((file: FileType) => file.fileKey === fileUploaded.fileKey) === -1,
    );

    filesToUpload = files.filter(
      (file: FileType) =>
        filesStored.findIndex(
          (fileUploaded: FileType) => fileUploaded.fileKey === file.fileKey,
        ) === -1,
    );

    // if create a new form
  } else {
    filesToUpload = files;
  }

  // upload files
  if (filesToUpload.length > 0) {
    await Promise.all(
      filesToUpload.map(async (file) => {
        // find the doc
        const document = documents.find((d) => d.id === file.fileKey) || ({} as DocumentModel);

        // upload the file
        await amplifyUploadFile(file?.blobFile, file?.fileKey as unknown as string);
        document.success = true;
      }),
    );
  }

  // delete files
  if (filesToDelete.length > 0) {
    await Promise.all(
      filesToDelete.map(async (file) => {
        // delete the file
        await amplifyRemoveFile(file?.fileKey as string);
      }),
    );
  }
};

export const deleteFiles = async (filesStored: FileType[]) => {
  let filesToDelete: FileType[] = [];
  filesToDelete = filesStored;

  // delete files
  if (filesToDelete.length > 0) {
    await Promise.all(
      filesToDelete.map(async (file) => {
        // delete the file
        await amplifyRemoveFile(file?.fileKey as string);
      }),
    );
  }
};
