import React from 'react';
import { Header } from 'rsuite';
import { translateDOMPositionXY } from 'rsuite/esm/DOMHelper';

function PageHeader() {
  const headerStyles = {
    height: 100,
    background: '#003875',
    color: ' #fff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as const;

  return (
    <Header style={headerStyles}>
      <h1>DV Lottery Services</h1>
    </Header>
  );
}

export default PageHeader;
