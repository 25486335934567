/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';

import PageBody from '../../layout/PageBody/PageBody';

const DVLotteryStyles = css`
  width: 100%;
  height: 100vh;
  .DVLottery {
  }
`;

function DVLottery() {
  return (
    <PageBody
      headingText='About DV Lottery'
      pageTitle='About DV Lottery | DV Lottery Services'
      css={DVLotteryStyles}
    >
      <div className='DVLottery'>About DV Lottery</div>
    </PageBody>
  );
}

export default DVLottery;
