/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { Button, Form } from 'rsuite';
import { Address as AddressModel } from '../../../../../../models';

const AddressStyles = css`
  // color: #fff;
  // background: #2a2c37;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #e5e5ea;

  .Address {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

interface IAddressProps {
  data?: AddressModel;
  order?: number;
  header?: any;
  toEdit?: string;
  plainText?: boolean;
}

const initialState: AddressModel = {
  id: '',
  streetLine1: null,
  streetLine2: null,
  commune: null,
  city: null,
  province: null,
  postalCode: null,
  country: null,
  applicationID: '',
};

function Address({
  data = initialState,
  order = 0,
  header = `Address ${order + 1}`,
  toEdit = 'edit',
  plainText = false,
}: IAddressProps) {
  const [formValue, setFormValue] = useState(initialState);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    setFormValue(data);
  }, [data, order]);

  const onEdit = () => {
    navigate(toEdit, { state: { data } });
  };

  if (formValue === ({} as AddressModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={AddressStyles}>
      <Form ref={formRef} readOnly formValue={formValue} fluid>
        <h4>{header}</h4>
        <Form.Group controlId='id'>
          <Form.Control id='id' type='hidden' name='id' className='Address-Input' />
        </Form.Group>
        <Form.Group controlId='streetLine1'>
          <Form.ControlLabel>Street Line 1:</Form.ControlLabel>
          <Form.Control id='streetLine1' type='text' name='streetLine1' className='Address-Input' />
        </Form.Group>
        <Form.Group controlId='streetLine2'>
          <Form.ControlLabel>Street Line 2:</Form.ControlLabel>
          <Form.Control id='streetLine2' type='text' name='streetLine2' className='Address-Input' />
        </Form.Group>
        <Form.Group controlId='commune'>
          <Form.ControlLabel>Commune:</Form.ControlLabel>
          <Form.Control id='commune' type='text' name='commune' className='Address-Input' />
        </Form.Group>
        <Form.Group controlId='city'>
          <Form.ControlLabel>City:</Form.ControlLabel>
          <Form.Control id='city' type='text' name='city' className='Address-Input' />
        </Form.Group>
        <Form.Group controlId='province'>
          <Form.ControlLabel>Province:</Form.ControlLabel>
          <Form.Control id='province' type='text' name='province' className='Address-Input' />
        </Form.Group>
        <Form.Group controlId='postalCode'>
          <Form.ControlLabel>Post Code:</Form.ControlLabel>
          <Form.Control id='postalCode' type='text' name='postalCode' className='Address-Input' />
        </Form.Group>
        <Form.Group controlId='country'>
          <Form.ControlLabel>Country:</Form.ControlLabel>
          <Form.Control id='country' type='text' name='country' className='Address-Input' />
        </Form.Group>
        {!plainText && (
          <Form.Group>
            <Button type='button' appearance='primary' className='Address-Button' onClick={onEdit}>
              Edit Address
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}

export default Address;
