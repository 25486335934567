/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';
import { Button, Table } from 'rsuite';
// import SortType from 'rsuite/table';

// dataStore
import { DataStore } from 'aws-amplify';
import { Payment as PaymentModel } from '../../../../models';

// store
import { authStore } from '../../../../stores';

// components
import PageBody from '../../../layout/PageBody/PageBody';
import Payment from './Payment';

const { Column, HeaderCell, Cell } = Table;

const PaymentsStyles = css`
  .Payments {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    &-Button {
      margin: 10px;
      // border: 2px solid #50fa7b;
      // color: #50fa7b;
      // background: none;
      // padding: 10px 15px;
      // border-radius: 6px;
      // outline: 0;
      // cursor: pointer;
      // font-weight: 600;
      // text-transform: uppercase;
      // nnn :disabled {
      //   color: grey;
      // }
    }
  }
`;

function Payments() {
  const { isAuthenticated } = authStore;
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([] as any);
  // const [sortColumn, setSortColumn] = useState();
  // const [sortType, setSortType] = useState();

  const params = useParams();
  const userID = params?.userID || '';

  const navigate = useNavigate();

  const location = useLocation();

  const mockData: any[] = [
    { id: 1, amount: 28, date: Date.parse('2019-01-01'), userID },
    { id: 2, amount: 58, date: Date.parse('2019-03-01'), userID },
    { id: 3, amount: 12, date: Date.parse('2022-07-01'), userID },
    { id: 4, amount: 500, date: Date.parse('2019-02-01'), userID },
    { id: 5, amount: 87, date: Date.parse('2010-01-01'), userID },
  ];

  // queries
  const fetchPayments = async () => {
    try {
      const data = (await DataStore.query(PaymentModel)).filter((x) => x.id === userID);

      // setPayments(data);
      setPayments(mockData);
      setIsLoading(false);
    } catch (error) {
      console.log('fetch payment error:', error);
      navigate(-1);
    }
  };

  // subscriptions
  const subscriptions = () => {
    // payment
    const paymentSubscription = DataStore.observe(PaymentModel).subscribe(() => fetchPayments());
    return [paymentSubscription];
  };

  // fetch data on componentmount and location change
  useEffect(() => {
    setIsLoading(true);
    try {
      fetchPayments();
    } catch (error) {
      console.log('fetch data error:', error);
    }
    setIsLoading(false);
  }, [location]);

  // call data subcriptions / unsubscriptions
  useEffect(() => {
    // when component did mount
    const subs = subscriptions();

    // when component did unmount
    return () => {
      subs.forEach((s: any) => s.unsubscribe());
    };
  }, []);

  // const getData = (data: any) => {
  //   if (sortColumn && sortType) {
  //     const resp = data.sort((a: any, b: any) => {
  //       let x = a[sortColumn];
  //       let y = b[sortColumn];
  //       if (typeof x === 'string') {
  //         x = x.charCodeAt(0);
  //       }
  //       if (typeof y === 'string') {
  //         y = y.charCodeAt(0);
  //       }
  //       if (sortType === 'asc') {
  //         return x - y;
  //       }
  //       return y - x;
  //     });
  //     console.log(resp);
  //     return resp;
  //   }
  //   return data;
  // };

  const onRowClick = (data: any) => {
    console.log(data);
  };

  const onRowDownload = (id: any) => {
    console.log('Download row:', id);
  };

  const onRowView = (id: any) => {
    console.log('View row:', id);
    navigate(`/settings/${userID}/payments/${id}`);
  };

  // const onSortColumn = (sortColumn: any, sortType: any) => {
  //   setSortColumn(sortColumn);
  //   setSortType(sortType);
  // };

  return (
    <PageBody headingText='Payments' pageTitle='Payment | DV Lottery Services' css={PaymentsStyles}>
      <div className='Payments'>
        {!isLoading && (
          <div css={PaymentsStyles}>
            <Link
              to={`/settings/${userID}/payments/new`}
              state={((): { data: any } => ({ data: { userID } }))()}
            >
              <Button type='button' appearance='ghost' className='Payments-Button'>
                Make a payment
              </Button>
            </Link>
            <Table
              height={400}
              data={mockData} // data={getData(payments)}
              onRowClick={(rowData: any) => onRowClick(rowData)}
              className='Payments-Table'
              // sortColumn={sortColumn}
              // sortType={sortType}
              // onSortColumn={onSortColumn}
            >
              {/* <Column flexGrow={1} sortable> */}
              <Column flexGrow={1}>
                <HeaderCell>Id</HeaderCell>
                <Cell dataKey='id' />
              </Column>

              {/* <Column flexGrow={1} fixed sortable> */}
              <Column flexGrow={1}>
                <HeaderCell>Amount</HeaderCell>
                <Cell dataKey='amount' />
              </Column>

              {/* <Column flexGrow={2} fixed sortable> */}
              <Column flexGrow={2}>
                <HeaderCell>Date</HeaderCell>
                <Cell dataKey='date' />
              </Column>

              {/* <Column flexGrow={2}>
                <HeaderCell>User ID</HeaderCell>
                <Cell dataKey='userID' />
              </Column> */}
              <Column flexGrow={1}>
                <HeaderCell>View Detail</HeaderCell>
                <Cell>
                  {(rowData: any) => (
                    <span>
                      <button type='button' onClick={() => onRowView(rowData.id)}>
                        View
                      </button>
                    </span>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1}>
                <HeaderCell>Download</HeaderCell>
                <Cell>
                  {(rowData: any) => (
                    <span>
                      <button type='button' onClick={() => onRowDownload(rowData.id)}>
                        Download
                      </button>
                    </span>
                  )}
                </Cell>
              </Column>
            </Table>
          </div>
        )}
      </div>
    </PageBody>
  );
}

export default observer(Payments);
