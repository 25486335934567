/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';

import { Footer } from 'rsuite';
import { getYear } from 'rsuite/esm/utils/dateUtils';

const PageFooterStyles = css`
  .PageFooter {
    width: 100%;
    padding: 30px;
    background: #f4f5f7;
  }
`;

function PageFooter() {
  const year = new Date().getFullYear();
  return (
    <Footer css={PageFooterStyles}>
      <div className='PageFooter'>&copy; Diversity Lottery Services @ {year}</div>
    </Footer>
  );
}

export default PageFooter;
