/** @jsxImportSource @emotion/react */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { css, jsx } from '@emotion/react';

// ui-library
import 'rsuite/styles/index.less';
// import { Steps } from 'rsuite';

// // dataStore
// import { DataStore } from 'aws-amplify';
// import {
//   Case as CaseModel,
//   Touchpoint as TouchpointModel,
//   Deadline as DeadlineModel,
// } from '../../../../../models';

// store
import { authStore } from '../../../../../stores';

// components
import PageBody from '../../../../layout/PageBody/PageBody';

const StatusStyles = css`
  .Status {
    width: 100%;
    padding: 30px;
    background: #c5c6c7;

    &-Heading = {
      height: 100;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

// const steps = [
//   {
//     title: 'Step 1: Submit an Entry',
//     description: 'October 5, 2022 @ 12:00 noon EST to November 8, 2022 @ 12:00 noon EST',
//   },
//   { title: 'Step 2: Selection of Applicants', description: 'By May 7, 2023' },
//   {
//     title: 'Step 3: If You Are Selected',
//     description: 'From May 7, 2022 through September 30, 2023',
//   },
//   { title: 'Step 4: Confirm Your Qualifications', description: 'TBD' },
//   {
//     title: 'Step 5: Submit Your Immigrant Visa and Alien Registration Application',
//     description: 'TBD',
//   },
//   { title: 'Step 6: Submit Supporting Documents', description: 'TBD' },
//   { title: 'Step 7: Interview', description: 'TBD' },
//   { title: 'Step 8: Prepare for the Interview', description: 'TBD' },
//   { title: 'Step 9: Applicant Interview', description: 'TBD' },
//   { title: 'Step 10: After the Interview', description: 'TBD' },
// ];

// const touchpoints = [
//   { title: 'Registration', description: 'register with us' },
//   { title: 'Eligibility', description: 'eligibility evaluation' },
//   { title: 'Initial Data Collection', description: 'application completion' },
//   { title: 'Initial Application Submission', description: 'application completion' },
//   { title: 'Application Outcome', description: 'lottery outcome' },
//   { title: 'Data Collection', description: 'gathering more information' },
//   { title: 'Information submission', description: 'submitting information to the embassy' },
//   { title: 'Interview date', description: 'interview date selection' },
//   { title: 'Interview prep', description: 'preparation for the interview' },
//   { title: 'Interview completion', description: 'completion the interview' },
//   { title: 'Interview outcome', description: 'interview outcome' },
//   { title: 'Submit Supporting Documents', description: 'final paperwork preparation' },
//   { title: 'Travel plan', description: 'travel plan' },
//   { title: 'Completion', description: 'completion' },
// ];

// const stages = [
//   {
//     title: 'Stage 1: Application',
//     description: 'October 5, 2022 @ 12:00 noon EST to November 8, 2022 @ 12:00 noon EST',
//   },
//   {
//     title: 'Stage 2: Lottery',
//     description: 'October 5, 2022 @ 12:00 noon EST to November 8, 2022 @ 12:00 noon EST',
//   },
//   {
//     title: 'Stage 3: Acceptance',
//     description: 'October 5, 2022 @ 12:00 noon EST to November 8, 2022 @ 12:00 noon EST',
//   },
//   {
//     title: 'Stage 4: Visa Interview',
//     description: 'October 5, 2022 @ 12:00 noon EST to November 8, 2022 @ 12:00 noon EST',
//   },
//   {
//     title: 'Stage 5: Final',
//     description: 'October 5, 2022 @ 12:00 noon EST to November 8, 2022 @ 12:00 noon EST',
//   },
// ];

function Status() {
  const { isAuthenticated } = authStore;
  return (
    <PageBody headingText='Status' pageTitle='Status | DV Lottery Services'>
      <div className='Status'>Status Content</div>
    </PageBody>
  );
  // const [isLoading, setIsLoading] = useState(false);
  // const [extendedCase, setExtendedCase] = useState({} as CaseModel);
  // const [touchpoint, setTouchpoint] = useState(0);

  // const params = useParams();
  // const userID = params?.userID || '';
  // const caseID = params?.caseID || '';

  // const navigate = useNavigate();

  // const location = useLocation();

  // // queries
  // const fetchCases = async () => {
  //   let data: CaseModel[] = [];
  //   try {
  //     data = (await DataStore.query(CaseModel)).filter((c) => c.id === caseID);
  //   } catch (error) {
  //     console.log('fetch cases error:', error);
  //     navigate(-1);
  //   }
  //   return data;
  // };

  // // subscriptions
  // const subscriptions = () => {
  //   const caseSubscription = DataStore.observe(CaseModel).subscribe(() => fetchCases());

  //   return [caseSubscription];
  // };

  // // call data subcriptions / unsubscriptions
  // useEffect(() => {
  //   // when component did mount
  //   const subs = subscriptions();

  //   // when component did unmount
  //   return () => {
  //     subs.forEach((s: any) => s.unsubscribe());
  //   };
  // }, []);

  // // automatically fetch application setting data when page load
  // useEffect(() => {
  //   fetchCases();
  // }, []);

  // const getStage = (touchpoint: number) => {
  //   if (touchpoint <= 4) return 0;
  //   if (touchpoint > 4 && touchpoint <= 5) return 1;
  //   if (touchpoint > 5 && touchpoint <= 7) return 2;
  //   if (touchpoint > 7 && touchpoint <= 11) return 3;
  //   if (touchpoint > 11) return 4;
  //   return 0;
  // };

  // const getStep = (touchpoint: number) => {
  //   if (touchpoint <= 4) return 0;
  //   if (touchpoint > 4 && touchpoint <= 5) return 1;
  //   if (touchpoint > 5 && touchpoint <= 7) return 2;
  //   if (touchpoint > 7 && touchpoint <= 11) return 3;
  //   if (touchpoint > 11) return 4;
  //   return 0;
  // };

  // return (
  //   <PageBody headingText='Status' pageTitle='Status | DV Lottery Services'>
  //     <div className='Status'>
  //       <Steps
  //         current={getStage(touchpoint)}
  //         vertical
  //         style={{
  //           textAlign: 'left',
  //           width: '400px',
  //           display: 'inline-table',
  //           verticalAlign: 'top',
  //           padding: '10px',
  //         }}
  //       >
  //         {stages.map((s) => (
  //           <Steps.Item title={s.title} description={s.description} />
  //         ))}
  //       </Steps>
  //       <Steps
  //         current={getStep(touchpoint)}
  //         vertical
  //         style={{
  //           textAlign: 'left',
  //           width: '400px',
  //           display: 'inline-table',
  //           verticalAlign: 'top',
  //           padding: '10px',
  //         }}
  //       >
  //         {steps.map((s) => (
  //           <Steps.Item title={s.title} description={s.description} />
  //         ))}
  //       </Steps>
  //       <Steps
  //         current={touchpoint}
  //         vertical
  //         style={{
  //           textAlign: 'left',
  //           width: '400px',
  //           display: 'inline-table',
  //           verticalAlign: 'top',
  //           padding: '10px',
  //         }}
  //       >
  //         {touchpoints.map((s) => (
  //           <Steps.Item title={s.title} description={s.description} />
  //         ))}
  //       </Steps>
  //     </div>
  //   </PageBody>
  // );
}

export default observer(Status);
