/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { css, jsx } from '@emotion/react';
import { Button, ButtonToolbar, Form, Modal, Schema } from 'rsuite';

// dataStore
import { DataStore } from 'aws-amplify';
import { Address as AddressModel } from '../../../../../../models';

// store
import { authStore } from '../../../../../../stores';

const AddressEditStyles = css`
  .AddressEdit {
    color: #fff;
    background: #2a2c37;
    border-radius: 6px;
    padding: 15px;

    &-Label {
      width: 100%;
    }

    &-Input {
      width: 100%;
      border: 1px solid transparent;
      color: #fff;
      background: #1d1e26;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 6px;
      outline: 0;
      &:focus {
        border-color: #50fa7b;
      }
    }
    &-Textarea {
      min-height: 80px;
      resize: none;
    }
    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

interface IAddressEditProps {
  isEdit: boolean;
}

const initialState: AddressModel = {
  id: '',
  streetLine1: null,
  streetLine2: null,
  commune: null,
  city: null,
  province: null,
  postalCode: null,
  country: null,
  applicationID: '',
};

const model = Schema.Model({
  // province: Schema.Types.StringType().isRequired('This field is required'),
});

function AddressEdit({ isEdit }: IAddressEditProps) {
  const { isAuthenticated, authUser } = authStore;

  const [formValue, setFormValue] = useState({} as AddressModel);
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);

  const params = useParams();
  const applicationID = params?.applicationID || '';

  const { state } = useLocation();

  const data: AddressModel = state?.data;

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    if (!isEdit) {
      setFormValue({ ...initialState, applicationID });
    } else {
      setFormValue({ ...data });
    }
  }, [data]);

  const onChange = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const onCheck = (error: any) => {
    if (Object.keys(error).length > 0) {
      setDisabled(true);
      setFormError({ ...formError, ...error });
    } else {
      setDisabled(false);
    }
  };

  const handlerError = (error: any) => {
    window.alert(`Error occurs: ${JSON.stringify(error)}`);
  };

  const onCreate = async (e: any) => {
    formRef.current.check(async (error: any) => {
      // check error
      if (Object.keys(error).length > 0) {
        setDisabled(true);
        setFormError({ ...formError, ...error });
        return;
      }
      // create
      try {
        await DataStore.save(new AddressModel(formValue));
        setDisabled(false);
        setModalOpen(false);
        console.log('A contact was successfully created');

        // navigate
        navigate(-1);
      } catch (error) {
        handlerError(error);
      }
    });
  };

  const onClear = () => {
    if (!window.confirm(`Really clear the form`)) {
      return;
    }
    setFormValue(initialState);
  };

  const onCancelCreate = () => {
    if (!window.confirm(`Really cancel the form creation`)) {
      return;
    }
    setFormValue(initialState);
    setModalOpen(false);
    navigate(-1);
  };

  const onUpdate = async () => {
    // check form on update
    if (!formRef.current.check()) {
      return;
    }
    // delete
    try {
      /* Models in DataStore are immutable. To update a record you must use the copyOf function
      to apply updates to the item’s fields rather than mutating the instance directly */
      const currentItem: any = await DataStore.query(AddressModel, formValue.id);
      await DataStore.save(
        AddressModel.copyOf(currentItem, (updated) => {
          updated.streetLine1 = formValue.streetLine1;
          updated.streetLine2 = formValue.streetLine2;
          updated.commune = formValue.commune;
          updated.city = formValue.city;
          updated.province = formValue.province;
          updated.postalCode = formValue.postalCode;
          updated.country = formValue.country;
        }),
      );
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onCancelUpdate = () => {
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onDelete = async () => {
    // confirm action
    if (!window.confirm(`Really delete ${formValue.id}`)) {
      return;
    }
    // delete
    try {
      const modelToDelete: any = await DataStore.query(AddressModel, formValue.id);
      DataStore.delete(modelToDelete);
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onModalClose = () => {
    if (!window.confirm(`Really want to close the modal`)) {
      return;
    }
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  if (formValue === ({} as AddressModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={AddressEditStyles}>
      <Modal open={modalOpen} onClose={onModalClose} size='md'>
        <Modal.Header>
          <Modal.Title>{isEdit ? 'Edit an existing contact' : 'Create a new Address'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={onChange}
            onCheck={onCheck}
            model={model}
            fluid
          >
            <h2>Address</h2>
            <Form.Group controlId='id'>
              <Form.Control id='id' type='hidden' name='id' className='Address-Input' />
            </Form.Group>
            <Form.Group controlId='streetLine1'>
              <Form.ControlLabel>Street Line 1:</Form.ControlLabel>
              <Form.Control
                id='streetLine1'
                type='text'
                name='streetLine1'
                className='Address-Input'
              />
            </Form.Group>
            <Form.Group controlId='streetLine2'>
              <Form.ControlLabel>Street Line 2:</Form.ControlLabel>
              <Form.Control
                id='streetLine2'
                type='text'
                name='streetLine2'
                className='Address-Input'
              />
            </Form.Group>
            <Form.Group controlId='commune'>
              <Form.ControlLabel>Commune:</Form.ControlLabel>
              <Form.Control id='commune' type='text' name='commune' className='Address-Input' />
            </Form.Group>
            <Form.Group controlId='city'>
              <Form.ControlLabel>City:</Form.ControlLabel>
              <Form.Control id='city' type='text' name='city' className='Address-Input' />
            </Form.Group>
            <Form.Group controlId='province'>
              <Form.ControlLabel>Province:</Form.ControlLabel>
              <Form.Control id='province' type='text' name='province' className='Address-Input' />
            </Form.Group>
            <Form.Group controlId='postalCode'>
              <Form.ControlLabel>Post Code:</Form.ControlLabel>
              <Form.Control
                id='postalCode'
                type='text'
                name='postalCode'
                className='Address-Input'
              />
            </Form.Group>
            <Form.Group controlId='country'>
              <Form.ControlLabel>Country:</Form.ControlLabel>
              <Form.Control id='country' type='text' name='country' className='Address-Input' />
            </Form.Group>
            <Form.Group controlId='applicationID'>
              <Form.ControlLabel>Applcation ID:</Form.ControlLabel>
              <Form.Control
                id='applicationID'
                type='text'
                name='applicationID'
                className='Address-Input'
                disabled
              />
            </Form.Group>
            {!isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`AddressEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onCreate}
                    disabled={disabled}
                  >
                    Create
                  </Button>
                  <Button type='button' className='AddressEdit-Button' onClick={onClear}>
                    Clear
                  </Button>
                  <Button type='button' className='AddressEdit-Button' onClick={onCancelCreate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}

            {isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`AddressEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onUpdate}
                    disabled={disabled}
                  >
                    Update
                  </Button>
                  <Button type='button' className='AddressEdit-Button' onClick={onDelete}>
                    Delete
                  </Button>
                  <Button type='button' className='AddressEdit-Button' onClick={onCancelUpdate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddressEdit;
