/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Button, ButtonToolbar, Form, Modal, Schema, SelectPicker } from 'rsuite';
import UserIcon from '@rsuite/icons/UserBadge';
import Uploader, { FileType } from 'rsuite/esm/Uploader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// dataStore
import { DataStore } from 'aws-amplify';
import { Profile as ProfileModel } from '../../../../../../models';

// store
import { authStore } from '../../../../../../stores';
import {
  deleteFiles,
  DocumentModel,
  fetchFiles,
  handleFiles,
  updateDocuments,
} from '../../../../../../utils/DocumentUtils';

const ProfileEditStyles = css`
  .ProfileEdit {
    color: #fff;
    background: #2a2c37;
    border-radius: 6px;
    padding: 15px;

    &-Label {
      width: 100%;
    }

    &-Input {
      width: 100%;
      border: 1px solid transparent;
      color: #fff;
      background: #1d1e26;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 6px;
      outline: 0;
      &:focus {
        border-color: #50fa7b;
      }
    }
    &-Textarea {
      min-height: 80px;
      resize: none;
    }
    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

const genderSelectData = ['male', 'female'].map((item) => ({
  label: item,
  value: item,
}));

const marriageStatusSelectData = ['married', 'divorced', 'separated', 'widowed', 'single'].map(
  (item) => ({
    label: item,
    value: item,
  }),
);

interface IProfileEditProps {
  isEdit: boolean;
}

const initialState: ProfileModel = {
  id: '',
  firstnameKH: null,
  lastnameKH: null,
  firstname: null,
  lastname: null,
  dateOfBirth: null,
  gender: null,
  driverLicenseNumber: null,
  driverLicenseDocument: null,
  nationalIdentificationNumber: null,
  nationalIdentificationDocument: null,
  passportNumber: null,
  passportDocument: null,
  passportIssueDate: null,
  passportExpirationDate: null,
  marriageStatus: null,
  marriageCertificateNumber: null,
  marriageCertificateDocument: null,
  numberOfDependent: null,
  passportPhotoDocument: null,
  otherDocuments: null,
  applicationID: '',
};

const model = Schema.Model({
  // numberOfDependent: Schema.Types.NumberType().isRequired('This field is required.'),
});

function ProfileEdit({ isEdit }: IProfileEditProps) {
  const { isAuthenticated, authUser } = authStore;

  const [formValue, setFormValue] = useState({} as ProfileModel);
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [passportPhotoFiles, setPassportPhotoFiles] = useState([] as FileType[]);
  const [passportPhotoDocuments, setPassportPhotoDocuments] = useState([] as DocumentModel[]);
  const [passportPhotoFilesStored, setPassportPhotoFilesStored] = useState([] as FileType[]);
  const [driverLicenseFiles, setDriverLicenseFiles] = useState([] as FileType[]);
  const [driverLicenseFilesStored, setDriverLicenseFilesStored] = useState([] as FileType[]);
  const [driverLicenseDocuments, setDriverLicenseDocuments] = useState([] as DocumentModel[]);
  const [nationalIdentificationFiles, setNationalIdentificationFiles] = useState([] as FileType[]);
  const [nationalIdentificationFilesStored, setNationalIdentificationFilesStored] = useState(
    [] as FileType[],
  );
  const [nationalIdentificationDocuments, setNationalIdentificationDocuments] = useState(
    [] as DocumentModel[],
  );
  const [passportFiles, setPassportFiles] = useState([] as FileType[]);
  const [passportFilesStored, setPassportFilesStored] = useState([] as FileType[]);
  const [passportDocuments, setPassportDocuments] = useState([] as DocumentModel[]);
  const [marriageCertificateFiles, setMarriageCertificateFiles] = useState([] as FileType[]);
  const [marriageCertificateFilesStored, setMarriageCertificateFilesStored] = useState(
    [] as FileType[],
  );
  const [marriageCertificateDocuments, setMarriageCertificateDocuments] = useState(
    [] as DocumentModel[],
  );
  const [otherFiles, setOtherFiles] = useState([] as FileType[]);
  const [otherFilesStored, setOtherFilesStored] = useState([] as FileType[]);
  const [otherDocuments, setOtherDocuments] = useState([] as DocumentModel[]);

  const params = useParams();
  const applicationID = params?.applicationID || '';

  const { state } = useLocation();

  const data: ProfileModel = state?.data;

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    if (!isEdit) {
      setFormValue({ ...initialState, applicationID });
    } else {
      // get docs
      const passportPhotoDocuments =
        data?.passportPhotoDocument === undefined || data?.passportPhotoDocument === null
          ? ([] as DocumentModel[])
          : [data?.passportPhotoDocument as DocumentModel];

      const driverLicenseDocuments =
        data?.driverLicenseDocument === undefined || data?.driverLicenseDocument === null
          ? ([] as DocumentModel[])
          : [data?.driverLicenseDocument as DocumentModel];

      const nationalIdentificationDocuments =
        data?.nationalIdentificationDocument === undefined ||
        data?.nationalIdentificationDocument === null
          ? ([] as DocumentModel[])
          : [data?.nationalIdentificationDocument as DocumentModel];

      const passportDocuments =
        data?.passportDocument === undefined || data?.passportDocument === null
          ? ([] as DocumentModel[])
          : [data?.passportDocument as DocumentModel];

      const marriageCertificateDocuments =
        data?.marriageCertificateDocument === undefined ||
        data?.marriageCertificateDocument === null
          ? ([] as DocumentModel[])
          : [data?.marriageCertificateDocument as DocumentModel];

      const otherDocuments =
        data?.otherDocuments === undefined || data?.otherDocuments === null
          ? ([] as DocumentModel[])
          : (data?.otherDocuments as DocumentModel[]);

      // fetch stored files
      fetchFiles(passportPhotoDocuments).then((apiFiles) => {
        setPassportPhotoFiles(apiFiles);
        setPassportPhotoFilesStored(apiFiles);
      });

      fetchFiles(driverLicenseDocuments).then((apiFiles) => {
        setDriverLicenseFiles(apiFiles);
        setDriverLicenseFilesStored(apiFiles);
      });

      fetchFiles(nationalIdentificationDocuments).then((apiFiles) => {
        setNationalIdentificationFiles(apiFiles);
        setNationalIdentificationFilesStored(apiFiles);
      });

      fetchFiles(passportDocuments).then((apiFiles) => {
        setPassportFiles(apiFiles);
        setPassportFilesStored(apiFiles);
      });

      fetchFiles(marriageCertificateDocuments).then((apiFiles) => {
        setMarriageCertificateFiles(apiFiles);
        setMarriageCertificateFilesStored(apiFiles);
      });

      fetchFiles(otherDocuments).then((apiFiles) => {
        setOtherFiles(apiFiles);
        setOtherFilesStored(apiFiles);
      });

      // set documents and form states
      setPassportPhotoDocuments(passportPhotoDocuments);
      setDriverLicenseDocuments(driverLicenseDocuments);
      setNationalIdentificationDocuments(nationalIdentificationDocuments);
      setPassportDocuments(passportDocuments);
      setMarriageCertificateDocuments(marriageCertificateDocuments);
      setOtherDocuments(otherDocuments);

      // set form values
      setFormValue({ ...data });
    }
  }, [data]);

  const onChange = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const onCheck = (error: any) => {
    if (Object.keys(error).length > 0) {
      setDisabled(true);
      setFormError({ ...formError, ...error });
    } else {
      setDisabled(false);
    }
  };

  const handlerError = (error: any) => {
    window.alert(`Error occurs: ${JSON.stringify(error)}`);
  };

  const onCreate = async (e: any) => {
    // upload files
    await handleFiles(isEdit, passportPhotoFiles, passportPhotoFilesStored, passportPhotoDocuments);
    await handleFiles(isEdit, driverLicenseFiles, driverLicenseFilesStored, driverLicenseDocuments);
    await handleFiles(
      isEdit,
      nationalIdentificationFiles,
      nationalIdentificationFilesStored,
      nationalIdentificationDocuments,
    );
    await handleFiles(isEdit, passportFiles, passportFilesStored, passportDocuments);
    await handleFiles(
      isEdit,
      marriageCertificateFiles,
      marriageCertificateFilesStored,
      marriageCertificateDocuments,
    );
    await handleFiles(isEdit, otherFiles, otherFilesStored, otherDocuments);

    formRef.current.check(async (error: any) => {
      // check error
      if (Object.keys(error).length > 0) {
        setDisabled(true);
        setFormError({ ...formError, ...error });
        return;
      }
      // create
      try {
        // handle store
        await DataStore.save(
          new ProfileModel({
            ...formValue,
            passportPhotoDocument: passportPhotoDocuments[0],
            driverLicenseDocument: driverLicenseDocuments[0],
            nationalIdentificationDocument: nationalIdentificationDocuments[0],
            passportDocument: passportDocuments[0],
            marriageCertificateDocument: marriageCertificateDocuments[0],
            otherDocuments,
          }),
        );
        setDisabled(false);
        setModalOpen(false);
        console.log('A contact was successfully created');

        // navigate
        navigate(-1);
      } catch (error) {
        handlerError(error);
      }
    });
  };

  const onClear = () => {
    if (!window.confirm(`Really clear the form`)) {
      return;
    }
    setFormValue(initialState);
  };

  const onCancelCreate = () => {
    if (!window.confirm(`Really cancel the form creation`)) {
      return;
    }
    setFormValue(initialState);
    setModalOpen(false);
    navigate(-1);
  };

  const onUpdate = async () => {
    // check form on update
    if (!formRef.current.check()) {
      return;
    }

    // upload files
    await handleFiles(isEdit, passportPhotoFiles, passportPhotoFilesStored, passportPhotoDocuments);
    await handleFiles(isEdit, driverLicenseFiles, driverLicenseFilesStored, driverLicenseDocuments);
    await handleFiles(
      isEdit,
      nationalIdentificationFiles,
      nationalIdentificationFilesStored,
      nationalIdentificationDocuments,
    );
    await handleFiles(isEdit, passportFiles, passportFilesStored, passportDocuments);
    await handleFiles(
      isEdit,
      marriageCertificateFiles,
      marriageCertificateFilesStored,
      marriageCertificateDocuments,
    );
    await handleFiles(isEdit, otherFiles, otherFilesStored, otherDocuments);

    // update formValues
    try {
      /* Models in DataStore are immutable. To update a record you must use the copyOf function
      to apply updates to the item’s fields rather than mutating the instance directly */
      const currentItem: any = await DataStore.query(ProfileModel, formValue.id);
      await DataStore.save(
        ProfileModel.copyOf(currentItem, (updated) => {
          updated.firstname = formValue.firstname;
          updated.lastname = formValue.lastname;
          updated.firstnameKH = formValue.firstnameKH;
          updated.lastnameKH = formValue.lastnameKH;
          updated.dateOfBirth = formValue.dateOfBirth;
          updated.gender = formValue.gender;
          updated.driverLicenseNumber = formValue.driverLicenseNumber;
          updated.driverLicenseDocument = driverLicenseDocuments[0];
          updated.nationalIdentificationNumber = formValue.nationalIdentificationNumber;
          updated.nationalIdentificationDocument = nationalIdentificationDocuments[0];
          updated.passportNumber = formValue.passportNumber;
          updated.passportDocument = passportDocuments[0];
          updated.passportIssueDate = formValue.passportIssueDate;
          updated.passportExpirationDate = formValue.passportExpirationDate;
          updated.marriageStatus = formValue.marriageStatus;
          updated.marriageCertificateNumber = formValue.marriageCertificateNumber;
          updated.marriageCertificateDocument = marriageCertificateDocuments[0];
          updated.numberOfDependent = formValue.numberOfDependent;
          updated.passportPhotoDocument = passportPhotoDocuments[0];
          updated.otherDocuments = otherDocuments;
        }),
      );
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onCancelUpdate = () => {
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onDelete = async () => {
    // delete files
    await deleteFiles(passportPhotoFilesStored);
    await deleteFiles(driverLicenseFilesStored);
    await deleteFiles(nationalIdentificationFilesStored);
    await deleteFiles(passportFilesStored);
    await deleteFiles(marriageCertificateFilesStored);
    await deleteFiles(otherFilesStored);

    // confirm action
    if (!window.confirm(`Really delete ${formValue.id}`)) {
      return;
    }
    // delete
    try {
      const modelToDelete: any = await DataStore.query(ProfileModel, formValue.id);
      DataStore.delete(modelToDelete);
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onModalClose = () => {
    if (!window.confirm(`Really want to close the modal`)) {
      return;
    }
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  if (formValue === ({} as ProfileModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={ProfileEditStyles}>
      <Modal open={modalOpen} onClose={onModalClose} size='md'>
        <Modal.Header>
          <Modal.Title>{isEdit ? 'Edit an existing contact' : 'Create a new Profile'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {JSON.stringify(formValue)} */}
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={onChange}
            onCheck={onCheck}
            model={model}
            fluid
          >
            <h2>Profile</h2>
            <Form.Group controlId='id'>
              <Form.Control id='id' type='hidden' name='id' className='Profile-Input' />
            </Form.Group>
            <Form.Group controlId='passportPhotoDocument'>
              <Form.ControlLabel>Portrait File:</Form.ControlLabel>
              <Form.Control
                id='passportPhotoDocument'
                type='hidden'
                name='passportPhotoDocument'
                className='Profile-Input'
              />
              <Uploader
                fileList={passportPhotoFiles}
                autoUpload={false}
                action='#'
                onChange={(newFiles: FileType[]) => {
                  const newDocuments = updateDocuments(newFiles, passportPhotoDocuments);
                  setPassportPhotoFiles(newFiles);
                  setPassportPhotoDocuments(newDocuments);
                }}
                disableMultipart
                multiple={false}
                listType='picture'
              />
            </Form.Group>
            <Form.Group controlId='firstname'>
              <Form.ControlLabel>Firstname (EN):</Form.ControlLabel>
              <Form.Control id='firstname' type='text' name='firstname' className='Profile-Input' />
            </Form.Group>
            <Form.Group controlId='lastname'>
              <Form.ControlLabel>Lastname (EN):</Form.ControlLabel>
              <Form.Control id='lastname' type='text' name='lastname' className='Profile-Input' />
            </Form.Group>
            <Form.Group controlId='firstnameKH'>
              <Form.ControlLabel>Firstname (KH):</Form.ControlLabel>
              <Form.Control
                id='firstnameKH'
                type='text'
                name='firstnameKH'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='lastnameKH'>
              <Form.ControlLabel>Lastname (KH):</Form.ControlLabel>
              <Form.Control
                id='lastnameKH'
                type='text'
                name='lastnameKH'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='dateOfBirth'>
              <Form.ControlLabel>DOB:</Form.ControlLabel>
              <Form.Control
                id='dateOfBirth'
                type='date'
                name='dateOfBirth'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='gender'>
              <Form.ControlLabel>Gender:</Form.ControlLabel>
              <Form.Control
                id='gender'
                accepter={SelectPicker}
                data={genderSelectData}
                name='gender'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='driverLicenseNumber'>
              <Form.ControlLabel>Driver License Number:</Form.ControlLabel>
              <Form.Control
                id='driverLicenseNumber'
                type='text'
                name='driverLicenseNumber'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='driverLicenseDocument'>
              <Form.ControlLabel>Driver License File:</Form.ControlLabel>
              <Form.Control
                id='driverLicenseDocument'
                type='hidden'
                name='driverLicenseDocument'
                className='Profile-Input'
              />
              <Uploader
                fileList={driverLicenseFiles}
                autoUpload={false}
                action='#'
                onChange={(newFiles: FileType[]) => {
                  const newDocuments = updateDocuments(newFiles, driverLicenseDocuments);
                  setDriverLicenseFiles(newFiles);
                  setDriverLicenseDocuments(newDocuments);
                }}
                disableMultipart
                multiple={false}
              />
            </Form.Group>
            <Form.Group controlId='nationalIdentificationNumber'>
              <Form.ControlLabel>National Identification Number:</Form.ControlLabel>
              <Form.Control
                id='nationalIdentificationNumber'
                type='text'
                name='nationalIdentificationNumber'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='nationalIdentificationDocument'>
              <Form.ControlLabel>National Identification File:</Form.ControlLabel>
              <Form.Control
                id='nationalIdentificationDocument'
                type='hidden'
                name='nationalIdentificationDocument'
                className='Profile-Input'
              />
              <Uploader
                fileList={nationalIdentificationFiles}
                autoUpload={false}
                action='#'
                onChange={(newFiles: FileType[]) => {
                  const newDocuments = updateDocuments(newFiles, nationalIdentificationDocuments);
                  setNationalIdentificationFiles(newFiles);
                  setNationalIdentificationDocuments(newDocuments);
                }}
                disableMultipart
                multiple={false}
              />
            </Form.Group>
            <Form.Group controlId='passportNumber'>
              <Form.ControlLabel>Passport Number:</Form.ControlLabel>
              <Form.Control
                id='passportNumber'
                type='text'
                name='passportNumber'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='passportDocument'>
              <Form.ControlLabel>Passport File:</Form.ControlLabel>
              <Form.Control
                id='passportDocument'
                type='hidden'
                name='passportDocument'
                className='Profile-Input'
              />
              <Uploader
                fileList={passportFiles}
                autoUpload={false}
                action='#'
                onChange={(newFiles: FileType[]) => {
                  const newDocuments = updateDocuments(newFiles, passportDocuments);
                  setPassportFiles(newFiles);
                  setPassportDocuments(newDocuments);
                }}
                disableMultipart
                multiple={false}
              />
            </Form.Group>
            <Form.Group controlId='passportIssueDate'>
              <Form.ControlLabel>Passport Issue Date:</Form.ControlLabel>
              <Form.Control
                id='passportIssueDate'
                type='date'
                name='passportIssueDate'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='passportExpirationDate'>
              <Form.ControlLabel>Passport Expiration Date:</Form.ControlLabel>
              <Form.Control
                id='passportExpirationDate'
                type='date'
                name='passportExpirationDate'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='marriageStatus'>
              <Form.ControlLabel>Marriage Status:</Form.ControlLabel>
              <Form.Control
                id='marriageStatus'
                accepter={SelectPicker}
                data={marriageStatusSelectData}
                name='marriageStatus'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='marriageCertificateNumber'>
              <Form.ControlLabel>Marriage Certificate Number:</Form.ControlLabel>
              <Form.Control
                id='marriageCertificateNumber'
                type='text'
                name='marriageCertificateNumber'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='marriageCertificateDocument'>
              <Form.ControlLabel>Marriage Certificate File:</Form.ControlLabel>
              <Form.Control
                id='marriageCertificateDocument'
                type='hidden'
                name='marriageCertificateDocument'
                className='Profile-Input'
              />
              <Uploader
                fileList={marriageCertificateFiles}
                autoUpload={false}
                action='#'
                onChange={(newFiles: FileType[]) => {
                  const newDocuments = updateDocuments(newFiles, marriageCertificateDocuments);
                  setMarriageCertificateFiles(newFiles);
                  setMarriageCertificateDocuments(newDocuments);
                }}
                disableMultipart
                multiple={false}
              />
            </Form.Group>
            <Form.Group controlId='numberOfDependent'>
              <Form.ControlLabel>Number of Dependent:</Form.ControlLabel>
              <Form.Control
                id='numberOfDependent'
                type='number'
                name='numberOfDependent'
                className='Profile-Input'
              />
            </Form.Group>
            <Form.Group controlId='applicationID'>
              <Form.ControlLabel>Applcation ID:</Form.ControlLabel>
              <Form.Control
                id='applicationID'
                type='text'
                name='applicationID'
                className='Profile-Input'
                disabled
              />
            </Form.Group>
            <Form.Group controlId='otherDocuments'>
              <Form.ControlLabel>Other Documents:</Form.ControlLabel>
              <Form.Control
                id='otherDocuments'
                type='hidden'
                name='otherDocuments'
                className='Profile-Input'
              />
              <Uploader
                fileList={otherFiles}
                autoUpload={false}
                action='#'
                onChange={(newFiles: FileType[]) => {
                  const newDocuments = updateDocuments(newFiles, otherDocuments);
                  setOtherFiles(newFiles);
                  setOtherDocuments(newDocuments);
                }}
                disableMultipart
                multiple={false}
              />
            </Form.Group>
            {!isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`ProfileEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onCreate}
                    disabled={disabled}
                  >
                    Create
                  </Button>
                  <Button type='button' className='ProfileEdit-Button' onClick={onClear}>
                    Clear
                  </Button>
                  <Button type='button' className='ProfileEdit-Button' onClick={onCancelCreate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}

            {isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`ProfileEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onUpdate}
                    disabled={disabled}
                  >
                    Update
                  </Button>
                  <Button type='button' className='ProfileEdit-Button' onClick={onDelete}>
                    Delete
                  </Button>
                  <Button type='button' className='ProfileEdit-Button' onClick={onCancelUpdate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProfileEdit;
