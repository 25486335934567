/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Authenticator as AmplifyAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { css, jsx } from '@emotion/react';

// ui-library
import '@aws-amplify/ui-react/styles.css';

// amplify
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { amplifyGetUser } from '../../../services/AuthenticationService';

// store
import { authStore } from '../../../stores';

// components
import PageBody from '../../layout/PageBody/PageBody';

const AuthenticatorStyles = css`
  .Authenticator {
    width: 100%;
    padding: 20px;
    background: white;
    min-height: 100vh;

    &-Panel-Card {
      width: 100%;
      min-height: 500px;
      height: 100vh;
      display: 'inline-block';
      text-align: left;

      &-Header {
        font: 24px strong;
        color: rgb(0, 56, 117);
      }
    }

    // &-Panel-Map {
    //   padding: 0;
    //   margin: 0;
    // }
  }
`;

function Authenticator() {
  // auth status from remote
  const { route } = useAuthenticator((context) => [context.route]);

  // auth status from local store
  const { isAuthenticated } = authStore;

  // locations
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (route === 'authenticated') {
      if (!isAuthenticated) {
        amplifyGetUser();
      }
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <PageBody
      headingText='Authenticator'
      pageTitle='Authenticator | DV Lottery Services'
      css={AuthenticatorStyles}
    >
      <div className='Authenticator'>
        <AmplifyAuthenticator />
      </div>
    </PageBody>
  );
}

export default observer(Authenticator);
