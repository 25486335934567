/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { Button, Form, Table } from 'rsuite';
import { Payment as PaymentModel } from '../../../../models';

const PaymentStyles = css`
  // color: #fff;
  // background: #2a2c37;

  .Payment {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

interface IPaymentProps {
  data?: PaymentModel;
  order?: number;
  header?: any;
  toEdit?: string;
  plainText?: boolean;
}

const initialState: PaymentModel = {
  id: '',
  amount: '0',
  date: '',
  userID: '',
};

function Payment({
  data = initialState,
  order = 0,
  header = `Payment ${order + 1}`,
  toEdit = 'edit',
  plainText = false,
}: IPaymentProps) {
  const [formValue, setFormValue] = useState(initialState);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    setFormValue(data);
  }, [data, order]);

  const onEdit = () => {
    navigate(toEdit, { state: { data } });
  };

  if (formValue === ({} as PaymentModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={PaymentStyles}>
      <Form ref={formRef} readOnly formValue={formValue} fluid>
        <h4>{header}</h4>
        <Form.Group controlId='id'>
          <Form.Control id='id' type='hidden' name='id' className='Payment-Input' />
        </Form.Group>
        <Form.Group controlId='amount'>
          <Form.ControlLabel>Amount:</Form.ControlLabel>
          <Form.Control id='amount' type='text' name='amount' className='Payment-Input' />
        </Form.Group>
        <Form.Group controlId='date'>
          <Form.ControlLabel>Date:</Form.ControlLabel>
          <Form.Control id='date' type='text' name='date' className='Payment-Input' />
        </Form.Group>
        <Form.Group controlId='userID'>
          <Form.Control id='userID' type='hidden' name='userID' className='Billing-Input' />
        </Form.Group>
        {!plainText && (
          <Form.Group>
            <Button type='button' appearance='primary' className='Payment-Button' onClick={onEdit}>
              Edit Payment
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}

export default Payment;
