/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Button, ButtonToolbar, Form, Modal, Schema } from 'rsuite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// dataStore
import { DataStore } from 'aws-amplify';
import { Billing as BillingModel } from '../../../../models';

// store
import { authStore } from '../../../../stores';

const BillingEditStyles = css`
  .BillingEdit {
    color: #fff;
    background: #2a2c37;
    border-radius: 6px;
    padding: 15px;

    &-Label {
      width: 100%;
    }

    &-Input {
      width: 100%;
      border: 1px solid transparent;
      color: #fff;
      background: #1d1e26;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 6px;
      outline: 0;
      &:focus {
        border-color: #50fa7b;
      }
    }
    &-Textarea {
      min-height: 80px;
      resize: none;
    }
    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

interface IBillingEditProps {
  isEdit: boolean;
}

const initialState: BillingModel = {
  id: '',
  amount: '0',
  dateBilling: '',
  dateDue: '',
  userID: '',
};

const model = Schema.Model({
  amount: Schema.Types.NumberType().isRequired('This field is required.'),
  dateBilling: Schema.Types.DateType().isRequired('This field is required'),
  dateDue: Schema.Types.DateType().isRequired('This field is required'),
  userID: Schema.Types.StringType().isRequired('This field is required'),
});

function BillingEdit({ isEdit }: IBillingEditProps) {
  const { isAuthenticated, authUser } = authStore;

  const [formValue, setFormValue] = useState({} as BillingModel);
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);

  const { state } = useLocation();

  const data: BillingModel = state?.data;

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    if (!isEdit) {
      const { userID } = data;
      setFormValue({ ...initialState, userID });
    } else {
      setFormValue({ ...data });
    }
  }, [data]);

  const onChange = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const onCheck = (error: any) => {
    if (Object.keys(error).length > 0) {
      setDisabled(true);
      setFormError({ ...formError, ...error });
    } else {
      setDisabled(false);
    }
  };

  const handlerError = (error: any) => {
    window.alert(`Error occurs: ${JSON.stringify(error)}`);
  };

  const onCreate = async (e: any) => {
    formRef.current.check(async (error: any) => {
      // check error
      if (Object.keys(error).length > 0) {
        setDisabled(true);
        setFormError({ ...formError, ...error });
        return;
      }
      // create
      try {
        await DataStore.save(new BillingModel(formValue));
        setDisabled(false);
        setModalOpen(false);
        console.log('A contact was successfully created');

        // navigate
        navigate(-1);
      } catch (error) {
        handlerError(error);
      }
    });
  };

  const onClear = () => {
    if (!window.confirm(`Really clear the form`)) {
      return;
    }
    setFormValue(initialState);
  };

  const onCancelCreate = () => {
    if (!window.confirm(`Really cancel the form creation`)) {
      return;
    }
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onUpdate = async () => {
    // check form on update
    if (!formRef.current.check()) {
      return;
    }
    // delete
    try {
      /* Models in DataStore are immutable. To update a record you must use the copyOf function
      to apply updates to the item’s fields rather than mutating the instance directly */
      const currentItem: any = await DataStore.query(BillingModel, formValue.id);
      await DataStore.save(
        BillingModel.copyOf(currentItem, (updated) => {
          updated.amount = formValue.amount;
          updated.dateBilling = formValue.dateBilling;
          updated.dateDue = formValue.dateDue;
        }),
      );
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onCancelUpdate = () => {
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onDelete = async () => {
    // confirm action
    if (!window.confirm(`Really delete ${formValue.id}`)) {
      return;
    }
    // delete
    try {
      const modelToDelete: any = await DataStore.query(BillingModel, formValue.id);
      DataStore.delete(modelToDelete);
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onModalClose = () => {
    if (!window.confirm(`Really want to close the modal`)) {
      return;
    }
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  if (formValue === ({} as BillingModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={BillingEditStyles}>
      <Modal open={modalOpen} onClose={onModalClose} size='md'>
        <Modal.Header>
          <Modal.Title>{isEdit ? 'Edit an existing contact' : 'Create a new Billing'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={onChange}
            onCheck={onCheck}
            model={model}
            fluid
          >
            <h2>Billing</h2>
            <Form.Group controlId='id'>
              <Form.Control id='id' type='hidden' name='id' className='BillingEdit-Input' />
            </Form.Group>
            <Form.Group controlId='amount'>
              <Form.ControlLabel>Amount:</Form.ControlLabel>
              <Form.Control
                id='amount'
                type='number'
                step='0.01'
                name='amount'
                className='Billing-Input'
              />
            </Form.Group>
            <Form.Group controlId='dateDue'>
              <Form.ControlLabel>Date Due:</Form.ControlLabel>
              <Form.Control id='dateDue' type='date' name='dateDue' className='Billing-Input' />
            </Form.Group>
            <Form.Group controlId='dateBilling'>
              <Form.ControlLabel>Date Billing:</Form.ControlLabel>
              <Form.Control
                id='dateBilling'
                type='date'
                name='dateBilling'
                className='Billing-Input'
              />
            </Form.Group>
            <Form.Group controlId='userID'>
              <Form.ControlLabel>User ID:</Form.ControlLabel>
              <Form.Control
                id='userID'
                type='text'
                name='userID'
                className='Billing-Input'
                disabled
              />
            </Form.Group>
            {!isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`BillingEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onCreate}
                    disabled={disabled}
                  >
                    Create
                  </Button>
                  <Button type='button' className='BillingEdit-Button' onClick={onClear}>
                    Clear
                  </Button>
                  <Button type='button' className='BillingEdit-Button' onClick={onCancelCreate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}

            {isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`BillingEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onUpdate}
                    disabled={disabled}
                  >
                    Update
                  </Button>
                  <Button type='button' className='BillingEdit-Button' onClick={onDelete}>
                    Delete
                  </Button>
                  <Button type='button' className='BillingEdit-Button' onClick={onCancelUpdate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default BillingEdit;
