import React, { useMemo, useState, useEffect } from 'react';
import { Router, RouteObject, useRoutes, Navigate, Route } from 'react-router-dom';

// styles
import './App.css';
import './App.less';
import 'rsuite/styles/index.less';
// ui libary elements
import { CustomProvider } from 'rsuite';

// routes
import { Authenticator } from '@aws-amplify/ui-react';
import { AppRoutes } from './routes';

// get authUser
import { amplifyGetUser } from './services/AuthenticationService';

// import stores
import { authStore } from './stores';
import MainLayout from './components/layout/MainLayout/MainLayout';

function App() {
  const theme = 'light';

  useEffect(() => {
    amplifyGetUser();
  }, []);
  return (
    <CustomProvider theme={theme}>
      <div className='App'>
        <Authenticator.Provider>
          <MainLayout>
            <AppRoutes />
          </MainLayout>
        </Authenticator.Provider>
      </div>
    </CustomProvider>
  );
}

export default App;