import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, RouteObject, useLocation } from 'react-router-dom';

import { observer } from 'mobx-react';
// ui library
import 'rsuite/styles/index.less';
import { Container, Header, Footer, Sidebar, Sidenav, Content, Navbar, Nav } from 'rsuite';
import CogIcon from '@rsuite/icons/legacy/Cog';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import DashboardIcon from '@rsuite/icons/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import MagicIcon from '@rsuite/icons/legacy/Magic';
import CalendarIcon from '@rsuite/icons/Calendar';
import EmailIcon from '@rsuite/icons/Email';
import LocationIcon from '@rsuite/icons/Location';
import SiteIcon from '@rsuite/icons/Site';
import TaskIcon from '@rsuite/icons/Task';
import PeopleSpeakerIcon from '@rsuite/icons/PeopleSpeaker';
import AdminIcon from '@rsuite/icons/Admin';
import ExploreIcon from '@rsuite/icons/Explore';
import DetailIcon from '@rsuite/icons/Detail';
import DataAuthorizeIcon from '@rsuite/icons/DataAuthorize';
import PlusIcon from '@rsuite/icons/Plus';

// dataStore
import { DataStore } from 'aws-amplify';
import { Case as CaseModel, Application as ApplicationModel } from '../../../models';

// import stores
import { authStore } from '../../../stores';
import { amplifySignOut } from '../../../services/AuthenticationService';

// app routes
import { AppRoutes } from '../../../routes';
import LoaderMessage from '../../elements/LoaderMessage/LoaderMessage';

function NavToggle({ expand, onChange }: { expand: any; onChange: any }) {
  return (
    <Navbar appearance='subtle' className='nav-toggle'>
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
          {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
}

// const NavLink = React.forwardRef(({ href, children, ...rest }: any, ref) => (
//   <NavLink ref={ref} to={href} {...rest}>
//     {children}
//   </NavLink>
// ));

const headerStyles = {
  paddingTop: 35,
  paddingBottom: 20,
  fontSize: 16,
  height: 100,
  background: '#003875',
  color: ' #fff',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  logo: {
    width: 30,
    height: 30,
  },
} as const;

function PageSidebar() {
  const { isAuthenticated, authUser } = authStore;
  const { sub: userID } = authUser?.attributes || ':userID';

  const routes = AppRoutes();

  const location = useLocation();

  const [expand, setExpand] = useState(true);
  const [isLoading, setIsLoading] = useState('null');
  const [cases, setCases] = useState([] as any);
  const [applications, setApplications] = useState([] as any);
  const [eventKey, setEventKey] = useState('');
  const [eventKeyPrev, setEventKeyPrev] = useState('');
  // const [canAddApplication, setCanAddApplication] = useState(true);

  // queries
  const fetchCases = async () => {
    try {
      const data = await DataStore.query(CaseModel);
      console.log('case data:', data);
      setCases(data);
      setIsLoading('null');
    } catch (error) {
      console.log('fetch cases error:', error);
    }
  };

  const fetchApplications = async () => {
    try {
      const data = await DataStore.query(ApplicationModel);
      console.log('application data:', data);
      setApplications(data);
      // setCanAddApplication((a) => {
      //   if (data && data.length > 0) {
      //     return data.findIndex((d) => d.type === 'main') === -1;
      //   }
      //   return a;
      // });
      setIsLoading('null');
    } catch (error) {
      console.log('fetch applications error:', error);
    }
  };

  // subscriptions
  const subscriptions = () => {
    // case
    const caseSubscription = DataStore.observe(CaseModel).subscribe(() => fetchCases());
    const applicationSubscription = DataStore.observe(CaseModel).subscribe(() =>
      fetchApplications(),
    );
    return [caseSubscription, applicationSubscription];
  };

  // switch data fetching on eventKey change
  useEffect(() => {
    (() => {
      switch (eventKey) {
        case 'dashboard':
          fetchCases();
          break;
        case 'application':
          fetchApplications();
          break;
        default:
          break;
      }
    })();
  }, [eventKey]);

  // call data subcriptions / unsubscriptions
  useEffect(() => {
    // when component did mount
    const subs = subscriptions();

    // when component did unmount
    return () => {
      subs.forEach((s: any) => s.unsubscribe());
    };
  }, []);

  const onMenuSelect = (eventKey: any) => {
    console.log('menu event:', eventKey);
    if (eventKey !== eventKeyPrev) {
      setIsLoading(eventKey);
      setEventKey(eventKey);
      setEventKeyPrev(eventKey);
    }
  };

  const activeLink = location.pathname;

  return (
    <Sidebar
      style={{ display: 'flex', flexDirection: 'column' }}
      width={expand ? 260 : 70}
      collapsible
    >
      <Sidenav.Header>
        <div style={headerStyles}>
          <img style={headerStyles.logo} src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt='' />
        </div>
      </Sidenav.Header>
      <Sidenav expanded={expand} defaultOpenKeys={['3']} appearance='subtle'>
        <Sidenav.Body>
          <Nav>
            <Nav.Item
              eventKey='1'
              icon={<SiteIcon />}
              style={{ textAlign: 'left' }}
              as={NavLink}
              to='/'
              active={activeLink === '/'}
            >
              Home
            </Nav.Item>
            <Nav.Item
              eventKey='2'
              icon={<ExploreIcon />}
              style={{ textAlign: 'left' }}
              as={NavLink}
              to='/dvlottery'
              active={activeLink === '/dvlottery'}
            >
              What&rsquo;s DV Lottery?
            </Nav.Item>
            <Nav.Item
              eventKey='3'
              icon={<DetailIcon />}
              style={{ textAlign: 'left' }}
              as={NavLink}
              to='/about'
              active={activeLink === '/about'}
            >
              About
            </Nav.Item>
            <Nav.Item
              eventKey='4'
              icon={<EmailIcon />}
              style={{ textAlign: 'left' }}
              as={NavLink}
              to='/contactUs'
              active={activeLink === '/contactUs'}
            >
              Contact Us
            </Nav.Item>
            <Nav.Item
              eventKey='5'
              icon={<PeopleSpeakerIcon />}
              style={{ textAlign: 'left' }}
              as={NavLink}
              to='/faqs'
              active={activeLink === '/faqs'}
            >
              FAQs
            </Nav.Item>
            {isAuthenticated && (
              <Nav.Menu
                eventKey='6'
                trigger='hover'
                title='Dashboard'
                icon={<DashboardIcon />}
                placement='rightStart'
                onClick={() => onMenuSelect('dashboard')}
              >
                {isLoading !== 'dashboard' && (
                  <>
                    {cases.length > 0 &&
                      cases.map((c: any, cIndex: string) => (
                        <Nav.Menu
                          eventKey={`6-${cIndex + 1}`}
                          trigger='hover'
                          title={`Case ${cIndex + 1}`}
                          placement='rightStart'
                          onClick={() => onMenuSelect('application')}
                        >
                          <Nav.Item
                            eventKey={`6-${cIndex + 1}-1`}
                            as={NavLink}
                            to={`/dashboard/${userID}/cases/${c.id}/settings`}
                            active={activeLink === `/${userID}/dashboard/cases/${c.id}/settings`}
                          >
                            Settings
                          </Nav.Item>
                          <Nav.Item
                            eventKey={`6-${cIndex + 1}-2`}
                            as={NavLink}
                            to={`/dashboard/${userID}/cases/${c.id}/status`}
                            active={activeLink === `/${userID}/dashboard/cases/${c.id}/status`}
                          >
                            Status
                          </Nav.Item>
                          <Nav.Item
                            eventKey={`6-${cIndex + 1}-3`}
                            as={NavLink}
                            to={`/dashboard/${userID}/cases/${c.id}/tasks`}
                            active={activeLink === `/dashboard/${userID}/cases/${c.id}/taks`}
                          >
                            Tasks
                          </Nav.Item>
                          <Nav.Item
                            eventKey={`6-${cIndex + 1}-4`}
                            as={NavLink}
                            to={`/dashboard/${userID}/cases/${c.id}/documents`}
                            active={activeLink === `/dashboard/${userID}/cases/${c.id}/documents`}
                          >
                            Documents
                          </Nav.Item>
                          {isLoading !== 'application' && (
                            <Nav.Item
                              eventKey={`6-${cIndex + 1}-5`}
                              as={NavLink}
                              to={`/dashboard/${userID}/cases/${c.id}/applications`}
                              active={
                                activeLink === `/dashboard/${userID}/cases/${c.id}/applications`
                              }
                            >
                              Applications
                            </Nav.Item>
                          )}
                          <LoaderMessage
                            loadingMessage='Loading application menu'
                            doneMessage='Loading application menu'
                            isLoading={isLoading === 'application'}
                          />
                        </Nav.Menu>
                      ))}
                    <Nav.Item
                      eventKey='6-0'
                      icon={<PlusIcon />}
                      style={{ textAlign: 'left' }}
                      as={NavLink}
                      to={`/dashboard/${userID}/cases/new`}
                      state={((): { data: CaseModel } => ({
                        data: { id: '', userID },
                      }))()}
                      active={activeLink === `/dashboard/${userID}/cases/new`}
                    >
                      Case New
                    </Nav.Item>
                  </>
                )}
                <LoaderMessage
                  loadingMessage='Loading dashboard menu'
                  doneMessage='Loading dashboard menu'
                  isLoading={isLoading === 'dashboard'}
                />
              </Nav.Menu>
            )}
            {isAuthenticated && (
              <>
                <Nav.Menu
                  eventKey='7'
                  trigger='hover'
                  title='Settings'
                  icon={<GearCircleIcon />}
                  placement='rightStart'
                >
                  <Nav.Item
                    eventKey='7-1'
                    as={NavLink}
                    to={`/settings/${userID}/account`}
                    active={activeLink === `/settings/${userID}/account`}
                  >
                    Account
                  </Nav.Item>
                  <Nav.Item
                    eventKey='7-2'
                    as={NavLink}
                    to={`/settings/${userID}/billings`}
                    active={activeLink === `/settings/${userID}/billings`}
                  >
                    Billings
                  </Nav.Item>
                  <Nav.Item
                    eventKey='7-3'
                    as={NavLink}
                    to={`/settings/${userID}/payments`}
                    active={activeLink === `/settings/${userID}/payments`}
                  >
                    Payments
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Item
                  eventKey='8'
                  icon={<AdminIcon />}
                  style={{ textAlign: 'left' }}
                  as={NavLink}
                  to='/administrator'
                  active={activeLink === '/administrator'}
                >
                  Administrator
                </Nav.Item>
              </>
            )}
            {isAuthenticated ? (
              <Nav.Item
                eventKey='9'
                icon={<DataAuthorizeIcon />}
                style={{ textAlign: 'left' }}
                as={NavLink}
                to='/authenticator'
                onClick={amplifySignOut}
                active={activeLink === '/authenticator'}
              >
                Logout
              </Nav.Item>
            ) : (
              <Nav.Item
                eventKey='9'
                icon={<DataAuthorizeIcon />}
                style={{ textAlign: 'left' }}
                as={NavLink}
                to='/authenticator'
                active={activeLink === '/authenticator'}
              >
                Login/Register
              </Nav.Item>
            )}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
      <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
    </Sidebar>
  );
}

export default observer(PageSidebar);
