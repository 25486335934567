/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';
import { Button, ButtonToolbar, Divider, IconButton, Modal, Panel, Stack, Steps } from 'rsuite';
import PageNextIcon from '@rsuite/icons/PageNext';
import PagePreviousIcon from '@rsuite/icons/PagePrevious';

// dataStore
import { DataStore } from 'aws-amplify';
import {
  Application as ApplicationModel,
  Contact as ContactModel,
  Address as AddressModel,
  Education as EducationModel,
  Employment as EmploymentModel,
  Relationship as RelationshipModel,
  Profile as ProfileModel,
} from '../../../../../models';

// store
import { authStore } from '../../../../../stores';

// components
import LoaderMessage from '../../../../elements/LoaderMessage/LoaderMessage';
import MainLayout from '../../../../layout/MainLayout/MainLayout';
import Application from './Application';
import { Contact } from './Contact';
import { Address } from './Address';
import { Education } from './Education';
import { Employment } from './Employment';
import { Relationship } from './Relationship';
import { Profile } from './Profile';

const ApplicationTabStyles = css`
  width: 100%;
  padding: 20px;

  .ApplicationTab {
    &-Button {
      margin: 10px;

      // border: 2px solid #50fa7b;
      // color: #50fa7b;
      // background: none;
      // padding: 10px 15px;
      // border-radius: 6px;
      // outline: 0;
      // cursor: pointer;
      // font-weight: 600;
      // text-transform: uppercase;

      // :disabled {
      //   color: grey;
      // }
    }
  }
`;

enum AppFormType {
  null = 'NULL',
  Contact = 'CONTACT',
  Address = 'ADDRESS',
  Education = 'EDUCATION',
  Employment = 'EMPLOYMENT',
  Relationship = 'RELATIONSHIP',
  Profile = 'PROFILE',
}

interface IApplicationTabProps {
  applicationID: string;
  fromTab: -1 | 0 | 1;
  previous: () => void;
  next: () => void;
  submit: () => void;
}

function ApplicationTab({ applicationID, fromTab, previous, next, submit }: IApplicationTabProps) {
  const { authUser } = authStore;

  const [isLoading, setIsLoading] = useState('null');
  const [applications, setApplications] = useState([] as any);
  const [contacts, setContacts] = useState([] as any);
  const [addresses, setAddresses] = useState([] as any);
  const [educations, setEducations] = useState([] as any);
  const [employments, setEmployments] = useState([] as any);
  const [relationships, setRelationships] = useState([] as any);
  const [profiles, setProfiles] = useState([] as any);
  const [eventKey, setEventKey] = useState('');
  const [eventKeyPrev, setEventKeyPrev] = useState('');
  const [step, setStep] = useState(0);
  const [previousApplicationID, setPreviousApplicationID] = useState('');
  const [nextApplicationID, setNextApplicationID] = useState('');

  const params = useParams();
  const userID = params?.userID || '';
  const caseID = params?.caseID || '';

  const navigate = useNavigate();

  const location = useLocation();

  // queries
  const fetchApplications = async () => {
    try {
      const data = (await DataStore.query(ApplicationModel)).filter((x) => x.caseID === caseID);
      const currentIndex = data.findIndex((x) => x.id === applicationID);
      const previousIndex = Math.max(currentIndex - 1, 0);
      const nextIndex = Math.min(currentIndex + 1, data.length - 1);

      setApplications([data[currentIndex]]);
      setPreviousApplicationID(data[previousIndex]?.id || data[currentIndex]?.id);
      setNextApplicationID(data[nextIndex]?.id || data[currentIndex]?.id);
      setIsLoading('null');
    } catch (error) {
      console.log('fetch application error:', error);
      navigate(-1);
    }
  };
  const fetchContacts = async () => {
    try {
      const data = (await DataStore.query(ContactModel)).filter(
        (x) => x.applicationID === applicationID,
      );
      console.log(data);
      setContacts(data);
      setIsLoading('null');
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAddresses = async () => {
    try {
      const data = (await DataStore.query(AddressModel)).filter(
        (x) => x.applicationID === applicationID,
      );
      console.log(data);
      setAddresses(data);
      setIsLoading('null');
    } catch (error) {
      console.log(error);
    }
  };
  const fetchEducations = async () => {
    try {
      const data = (await DataStore.query(EducationModel)).filter(
        (x) => x.applicationID === applicationID,
      );
      console.log(data);
      setEducations(data);
      setIsLoading('null');
    } catch (error) {
      console.log(error);
    }
  };
  const fetchEmployments = async () => {
    try {
      const data = (await DataStore.query(EmploymentModel)).filter(
        (x) => x.applicationID === applicationID,
      );
      console.log(data);
      setEmployments(data);
      setIsLoading('null');
    } catch (error) {
      console.log(error);
    }
  };
  const fetchRelationships = async () => {
    try {
      const data = (await DataStore.query(RelationshipModel)).filter(
        (x) => x.applicationID === applicationID,
      );
      console.log(data);
      setRelationships(data);
      setIsLoading('null');
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProfiles = async () => {
    try {
      const data = (await DataStore.query(ProfileModel)).filter(
        (x) => x.applicationID === applicationID,
      );
      console.log(data);
      setProfiles(data);
      setIsLoading('null');
    } catch (error) {
      console.log(error);
    }
  };

  // subscriptions
  const subscriptions = () => {
    // cotact
    const applicationSubscription = DataStore.observe(ApplicationModel).subscribe(() =>
      fetchApplications(),
    );
    const contactSubscription = DataStore.observe(ContactModel).subscribe(() => fetchContacts());
    const addressSubscription = DataStore.observe(AddressModel).subscribe(() => fetchAddresses());
    const educationSubscription = DataStore.observe(EducationModel).subscribe(() =>
      fetchEducations(),
    );
    const employmentSubscription = DataStore.observe(EmploymentModel).subscribe(() =>
      fetchEmployments(),
    );
    const relationshipSubscription = DataStore.observe(RelationshipModel).subscribe(() =>
      fetchRelationships(),
    );
    const profileSubscription = DataStore.observe(ProfileModel).subscribe(() => fetchProfiles());
    return [
      applicationSubscription,
      contactSubscription,
      addressSubscription,
      educationSubscription,
      employmentSubscription,
      relationshipSubscription,
      profileSubscription,
    ];
  };

  // manually fetch data on eventKey change and location change
  useEffect(() => {
    (() => {
      switch (eventKey) {
        case 'application':
          fetchApplications();
          break;
        case 'contact':
          fetchContacts();
          break;
        case 'address':
          fetchAddresses();
          break;
        case 'education':
          fetchEducations();
          break;
        case 'employment':
          fetchEmployments();
          break;
        case 'relationship':
          fetchRelationships();
          break;
        case 'profile':
          fetchProfiles();
          break;
        default:
          break;
      }
    })();
  }, [eventKey, location]);

  // call data subcriptions / unsubscriptions
  useEffect(() => {
    // when component did mount
    const subs = subscriptions();

    // when component did unmount
    return () => {
      subs.forEach((s: any) => s.unsubscribe());
    };
  }, []);

  // set eventKey on panel select event
  const onPanelSelect = (eventKey: any) => {
    if (eventKey !== eventKeyPrev) {
      setIsLoading(eventKey);
      setEventKey(eventKey);
      setEventKeyPrev(eventKey);
    }
  };

  // automatically fetch application setting data when page load
  useEffect(() => {
    onPanelSelect('application');
    setStep((step) => {
      switch (fromTab) {
        case 0:
          return step;
        case -1:
          return 7;
        case 1:
          return 0;
        default:
          return step;
      }
    });
  }, []);

  const summary = () => (
    <div className='ApplicationTab-Summary'>
      <Panel
        header={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingTop: '20px',
              marginBottom: '24px',
            }}
          >
            <h3>Application</h3>
            <Button
              type='button'
              appearance='link'
              className='ApplicationTab-Button'
              onClick={() => setStep(0)}
            >
              Edit Application
            </Button>
          </div>
        }
        eventKey='application'
        id='application'
      >
        {isLoading !== 'application' && (
          <div>
            {applications.length ? (
              applications.map((data: ApplicationModel, index: number) => (
                <Application data={data} header='Settings' plainText />
              ))
            ) : (
              <div>No Application available</div>
            )}
          </div>
        )}
        <LoaderMessage
          loadingMessage='Loading application data'
          doneMessage='Loading application data complete'
          isLoading={isLoading === 'application'}
        />
      </Panel>
      <Panel
        header={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingTop: '20px',
              marginBottom: '24px',
            }}
          >
            <h3>Contact</h3>
            <Button
              type='button'
              appearance='link'
              className='ApplicationTab-Button'
              onClick={() => setStep(1)}
            >
              Edit contact
            </Button>
          </div>
        }
        eventKey='contact'
        id='contact'
      >
        {isLoading !== 'contact' && (
          <div>
            {contacts.length ? (
              contacts.map((data: ContactModel, index: number) => (
                <div key={data.id}>
                  <Contact data={data} order={index} plainText />
                  <br />
                </div>
              ))
            ) : (
              <div>No Contact available</div>
            )}
          </div>
        )}
        <LoaderMessage
          loadingMessage='Loading dashboard data'
          doneMessage='Loading dashboard data complete'
          isLoading={isLoading === 'contact'}
        />
      </Panel>
      <Panel
        header={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingTop: '20px',
              marginBottom: '24px',
            }}
          >
            <h3>Address</h3>
            <Button
              type='button'
              appearance='link'
              className='ApplicationTab-Button'
              onClick={() => setStep(2)}
            >
              Edit address
            </Button>
          </div>
        }
        eventKey='address'
        id='address'
      >
        {isLoading !== 'address' && (
          <div>
            {addresses.length ? (
              addresses.map((data: AddressModel, index: number) => (
                <div key={data.id}>
                  <Address data={data} order={index} plainText />
                  <br />
                </div>
              ))
            ) : (
              <div>No Addresses available</div>
            )}
          </div>
        )}
        <LoaderMessage
          loadingMessage='Loading dashboard data'
          doneMessage='Loading dashboard data complete'
          isLoading={isLoading === 'address'}
        />
      </Panel>
      <Panel
        header={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingTop: '20px',
              marginBottom: '24px',
            }}
          >
            <h3>Education</h3>
            <Button
              type='button'
              appearance='link'
              className='ApplicationTab-Button'
              onClick={() => setStep(3)}
            >
              Edit education
            </Button>
          </div>
        }
        eventKey='education'
        id='education'
      >
        {isLoading !== 'education' && (
          <div>
            {educations.length ? (
              educations.map((data: EducationModel, index: number) => (
                <div key={data.id}>
                  <Education data={data} order={index} plainText />
                  <br />
                </div>
              ))
            ) : (
              <div>No education available</div>
            )}
          </div>
        )}
        <LoaderMessage
          loadingMessage='Loading education data'
          doneMessage='Loading education data complete'
          isLoading={isLoading === 'education'}
        />
      </Panel>
      <Panel
        header={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingTop: '20px',
              marginBottom: '24px',
            }}
          >
            <h3>Employment</h3>
            <Button
              type='button'
              appearance='link'
              className='ApplicationTab-Button'
              onClick={() => setStep(4)}
            >
              Edit employment
            </Button>
          </div>
        }
        eventKey='employment'
        id='employment'
      >
        {isLoading !== 'employment' && (
          <div>
            {employments.length ? (
              employments.map((data: EmploymentModel, index: number) => (
                <div key={data.id}>
                  <Employment data={data} order={index} plainText />
                  <br />
                </div>
              ))
            ) : (
              <div>No employments available</div>
            )}
          </div>
        )}
        <LoaderMessage
          loadingMessage='Loading employment data'
          doneMessage='Loading employment data complete'
          isLoading={isLoading === 'employment'}
        />
      </Panel>
      <Panel
        header={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingTop: '20px',
              marginBottom: '24px',
            }}
          >
            <h3>Relationship</h3>
            <Button
              type='button'
              appearance='link'
              className='ApplicationTab-Button'
              onClick={() => setStep(5)}
            >
              Edit relationship
            </Button>
          </div>
        }
        eventKey='relationship'
        id='relationship'
      >
        {isLoading !== 'relationship' && (
          <div>
            {relationships.length ? (
              relationships.map((data: RelationshipModel, index: number) => (
                <div key={data.id}>
                  <Relationship data={data} order={index} plainText />
                  <br />
                </div>
              ))
            ) : (
              <div>No relationship available</div>
            )}
          </div>
        )}
        <LoaderMessage
          loadingMessage='Loading relationship data'
          doneMessage='Loading relationship data complete'
          isLoading={isLoading === 'relationship'}
        />
      </Panel>
      <Panel
        header={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingTop: '20px',
              marginBottom: '24px',
            }}
          >
            <h3>Profile</h3>
            <Button
              type='button'
              appearance='link'
              className='ApplicationTab-Button'
              onClick={() => setStep(6)}
            >
              Edit profile
            </Button>
          </div>
        }
        eventKey='profile'
        id='profile'
      >
        {isLoading !== 'profile' && (
          <div>
            {profiles.length ? (
              profiles.map((data: ProfileModel, index: number) => (
                <div key={data.id}>
                  <Profile data={data} order={index} plainText />
                  <br />
                </div>
              ))
            ) : (
              <div>No profile available</div>
            )}
          </div>
        )}
        <LoaderMessage
          loadingMessage='Loading profile data'
          doneMessage='Loading profile data complete'
          isLoading={isLoading === 'profile'}
        />
      </Panel>
    </div>
  );

  const getForms = () => [
    <Panel header={<h3>Application</h3>} eventKey='application' id='application' key='application'>
      {isLoading !== 'application' && (
        <div>
          {applications.length ? (
            applications.map((data: ApplicationModel, index: number) => (
              <Application
                data={data}
                header='Settings'
                toEdit={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/edit`}
              />
            ))
          ) : (
            <div>No Application available</div>
          )}
        </div>
      )}
      <LoaderMessage
        loadingMessage='Loading application data'
        doneMessage='Loading application data complete'
        isLoading={isLoading === 'application'}
      />
    </Panel>,
    <Panel header={<h3>Contact</h3>} eventKey='contact' id='contact' key='contact'>
      {isLoading !== 'contact' && (
        <div>
          <Link
            to={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/contacts/new`}
          >
            <Button type='button' appearance='ghost' className='ApplicationTab-Button'>
              Add a contact
            </Button>
          </Link>
          {contacts.length ? (
            contacts.map((data: ContactModel, index: number) => (
              <div key={data.id}>
                <Contact
                  data={data}
                  order={index}
                  toEdit={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/contacts/${data.id}/edit`}
                />
                <br />
              </div>
            ))
          ) : (
            <div>No Contact available</div>
          )}
        </div>
      )}
      <LoaderMessage
        loadingMessage='Loading dashboard data'
        doneMessage='Loading dashboard data complete'
        isLoading={isLoading === 'contact'}
      />
    </Panel>,
    <Panel header={<h3>Address</h3>} eventKey='address' id='address' key='address'>
      {isLoading !== 'address' && (
        <div>
          <Link
            to={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/addresses/new`}
          >
            <Button type='button' appearance='ghost' className='ApplicationTab-Button'>
              Add an address
            </Button>
          </Link>
          {addresses.length ? (
            addresses.map((data: AddressModel, index: number) => (
              <div key={data.id}>
                <Address
                  data={data}
                  order={index}
                  toEdit={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/addresses/${data.id}/edit`}
                />
                <br />
              </div>
            ))
          ) : (
            <div>No Addresses available</div>
          )}
        </div>
      )}
      <LoaderMessage
        loadingMessage='Loading dashboard data'
        doneMessage='Loading dashboard data complete'
        isLoading={isLoading === 'address'}
      />
    </Panel>,
    <Panel header={<h3>Education</h3>} eventKey='education' id='education' key='education'>
      {isLoading !== 'education' && (
        <>
          <Link
            to={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/educations/new`}
          >
            <Button type='button' appearance='ghost' className='ApplicationTab-Button'>
              Add an education
            </Button>
          </Link>
          {educations.length ? (
            educations.map((data: EducationModel, index: number) => (
              <div key={data.id}>
                <Education
                  data={data}
                  order={index}
                  toEdit={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/educations/${data.id}/edit`}
                />
                <br />
              </div>
            ))
          ) : (
            <div>No education available</div>
          )}
        </>
      )}
      <LoaderMessage
        loadingMessage='Loading education data'
        doneMessage='Loading education data complete'
        isLoading={isLoading === 'education'}
      />
    </Panel>,
    <Panel header={<h3>Employment</h3>} eventKey='employment' id='employment' key='employment'>
      {isLoading !== 'employment' && (
        <>
          <Link
            to={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/employments/new`}
          >
            <Button type='button' appearance='ghost' className='ApplicationTab-Button'>
              Add an employment
            </Button>
          </Link>
          {employments.length ? (
            employments.map((data: EmploymentModel, index: number) => (
              <div key={data.id}>
                <Employment
                  data={data}
                  order={index}
                  toEdit={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/employments/${data.id}/edit`}
                />
                <br />
              </div>
            ))
          ) : (
            <div>No employments available</div>
          )}
        </>
      )}
      <LoaderMessage
        loadingMessage='Loading employment data'
        doneMessage='Loading employment data complete'
        isLoading={isLoading === 'employment'}
      />
    </Panel>,
    <Panel
      header={<h3>Relationship</h3>}
      eventKey='relationship'
      id='relationship'
      key='relationship'
    >
      {isLoading !== 'relationship' && (
        <>
          <Link
            to={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/relationships/new`}
          >
            <Button type='button' appearance='ghost' className='ApplicationTab-Button'>
              Add a relationship
            </Button>
            <br />
          </Link>
          {relationships.length ? (
            relationships.map((data: RelationshipModel, index: number) => (
              <div key={data.id}>
                <Relationship
                  data={data}
                  order={index}
                  toEdit={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/relationships/${data.id}/edit`}
                />
                <br />
              </div>
            ))
          ) : (
            <div>No relationship available</div>
          )}
        </>
      )}
      <LoaderMessage
        loadingMessage='Loading relationship data'
        doneMessage='Loading relationship data complete'
        isLoading={isLoading === 'relationship'}
      />
    </Panel>,
    <Panel header={<h3>Profile</h3>} eventKey='profile' id='profile' key='profile'>
      {isLoading !== 'profile' && (
        <div>
          {profiles.length ? (
            profiles.map((data: ProfileModel, index: number) => (
              <div key={data.id}>
                <Profile
                  data={data}
                  order={index}
                  toEdit={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/profiles/${data.id}/edit`}
                />
                <br />
              </div>
            ))
          ) : (
            <>
              <Link
                to={`/dashboard/${userID}/cases/${caseID}/applications/${applicationID}/profiles/new`}
              >
                <Button type='button' appearance='ghost' className='ApplicationTab-Button'>
                  Add a profile
                </Button>
              </Link>
              <div>No profile available</div>
            </>
          )}
        </div>
      )}
      <LoaderMessage
        loadingMessage='Loading profile data'
        doneMessage='Loading profile data complete'
        isLoading={isLoading === 'profile'}
      />
    </Panel>,
    summary(),
  ];

  const fromStepToEventKey = (step: number) => {
    switch (step) {
      case 0:
        return 'application';
      case 1:
        return 'contact';
      case 2:
        return 'address';
      case 3:
        return 'education';
      case 4:
        return 'employment';
      case 5:
        return 'relationship';
      case 6:
        return 'profile';
      case 7:
        return 'summary';
      default:
        return null;
    }
  };

  // navigation
  const onBack = () => {
    onPanelSelect(fromStepToEventKey(Math.max(step - 1, 0)));
    setStep(Math.max(step - 1, 0));
  };

  const onContinue = () => {
    onPanelSelect(fromStepToEventKey(Math.min(step + 1, getForms().length - 1)));
    setStep(Math.min(step + 1, getForms().length - 1));
  };

  const onPreviousApplication = () => {
    previous();
    setStep(getForms().length - 1);
  };

  const onNextApplication = () => {
    next();
    setStep(0);
  };

  return (
    <div css={ApplicationTabStyles} className='ApplicationTab'>
      <Outlet />
      <Steps current={step} style={{ textAlign: 'left' }}>
        <Steps.Item title='Setting' />
        <Steps.Item title='Contact' />
        <Steps.Item title='Address' />
        <Steps.Item title='Education' />
        <Steps.Item title='Employment' />
        <Steps.Item title='Relationship' />
        <Steps.Item title='Profile' />
        <Steps.Item title='Summary' />
      </Steps>

      <Divider />

      {getForms()[step]}

      <Divider />

      <Stack justifyContent='space-between'>
        {step === 0 && applicationID !== previousApplicationID && (
          <IconButton
            icon={<PagePreviousIcon />}
            appearance='ghost'
            onClick={() => onPreviousApplication()}
          >
            Previous Application
          </IconButton>
        )}

        {step !== 0 && (
          <IconButton icon={<PagePreviousIcon />} onClick={() => onBack()}>
            Back
          </IconButton>
        )}

        {step !== getForms().length - 1 && (
          <IconButton
            icon={<PageNextIcon />}
            placement='right'
            appearance='primary'
            onClick={() => onContinue()}
          >
            Continue
          </IconButton>
        )}

        {step === getForms().length - 1 && applicationID !== nextApplicationID && (
          <IconButton
            icon={<PageNextIcon />}
            placement='right'
            appearance='ghost'
            onClick={() => onNextApplication()}
          >
            Next Application
          </IconButton>
        )}

        {step === getForms().length - 1 && applicationID === nextApplicationID && (
          <IconButton
            icon={<PageNextIcon />}
            placement='right'
            appearance='ghost'
            onClick={() => submit()}
          >
            Submit
          </IconButton>
        )}
      </Stack>
    </div>
  );
}

export default observer(ApplicationTab);
