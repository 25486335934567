/** @jsxImportSource @emotion/react */
import React, { Ref, useEffect, useRef, useState } from 'react';
import { Table } from 'rsuite';
import { useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { FileType } from 'rsuite/esm/Uploader';
import { S3Object } from '../../../models';

const { Column, HeaderCell, Cell } = Table;

const DocumentStyles = css`
  // color: #fff;
  // background: #2a2c37;
  border-radius: 6px;
  padding: 0px;
  border: 1px solid #e5e5ea;

  .Document {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }

    &-Table {
      color: black;
    }
  }
`;

type DocumentModel = {
  id: string;
  filename: string;
  file?: S3Object | null;
  success?: boolean | null;
};

interface IDocumentProps {
  documents: DocumentModel[];
  files: FileType[];
}

const initialState: FileType[] = [];

function Document({ documents, files }: IDocumentProps) {
  const navigate = useNavigate();

  const onRowDownload = (id: any) => {
    const file = files.find((f: FileType) => f.fileKey === id);
    return file?.url || '';
  };

  return (
    <div css={DocumentStyles}>
      <Table
        data={documents}
        className='Document-Table'
        autoHeight
        renderEmpty={() => <div style={{ background: 'white' }}>~ No files ~</div>}
      >
        <Column flexGrow={1}>
          <HeaderCell>Id</HeaderCell>
          <Cell dataKey='id' />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>filename</HeaderCell>
          <Cell dataKey='filename' />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Download</HeaderCell>
          <Cell>
            {(rowData: any) => (
              <span>
                <a href={(() => onRowDownload(rowData.id))()} target='blank'>
                  Download
                </a>
              </span>
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  );
}

export default Document;
