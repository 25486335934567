/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { css, jsx } from '@emotion/react';

// ui library
import 'rsuite/styles/index.less';
import { Form, Button, ButtonToolbar, Schema, Modal, InputGroup } from 'rsuite';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

// services
import {
  amplifyConfirmEmail,
  amplifyUpdatePassword,
} from '../../../../services/AuthenticationService';

// store
import { authStore } from '../../../../stores';

const ChangePasswordStyles = css`
  .ChangePassword {
    color: #fff;
    background: #2a2c37;
    border-radius: 6px;
    padding: 15px;

    &-Label {
      width: 300px;
    }

    &-Input {
      width: 300px;
      border: 1px solid transparent;
      color: #fff;
      background: #1d1e26;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 6px;
      outline: 0;
      &:focus {
        border-color: #50fa7b;
      }
    }
    &-Textarea {
      min-height: 80px;
      resize: none;
    }
    &-Button {
      width: 300px;
      // border: 2px solid #50fa7b;
      // color: blue;
      // background: none;
      // padding: 10px 15px;
      // border-radius: 6px;
      // outline: 0;
      // cursor: pointer;
      // font-weight: 600;
      // text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

interface IChangePassword {
  sub: string | null;
  oldPassword: string | null;
  newPassword: string | null;
  conPassword: string | null;
}

const initialState: IChangePassword = {
  sub: '',
  oldPassword: '',
  newPassword: '',
  conPassword: '',
};

const model = Schema.Model({
  sub: Schema.Types.StringType().isRequired('This field is required.'),
  oldPassword: Schema.Types.StringType().isRequired('This field is required.'),
  newPassword: Schema.Types.StringType().isRequired('This field is required.'),
  conPassword: Schema.Types.StringType()
    .addRule((value, data) => {
      if (value !== data.newPassword) {
        return false;
      }
      return true;
    }, 'The two passwords do not match')
    .isRequired('This field is required.'),
});

function ChangePassword() {
  const { isAuthenticated, authUser } = authStore;

  const [formValue, setFormValue] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [isPasswordChanging, setIsPasswordChanging] = useState(false);
  const [visiblePass, setVisiblePass] = useState('');
  const [visiblePassPrev, setVisiblePassPrev] = useState('');

  const navigate = useNavigate();

  const formRef = useRef() as any;

  const currentData: IChangePassword = {
    ...initialState,
    sub: authUser.attributes.sub,
  };

  useEffect(() => {
    setFormValue({
      ...currentData,
    });
  }, []);

  const onChange = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const onCheck = (error: any) => {
    if (Object.keys(error).length > 0) {
      setDisabled(true);
      setFormError({ ...formError, ...error });
    } else {
      setDisabled(false);
    }
  };

  const handlerError = (error: any) => {
    window.alert(`Error occurs: ${JSON.stringify(error)}`);
  };

  const onClear = () => {
    if (!window.confirm(`Really clear the form`)) {
      return;
    }
    setFormValue(initialState);
  };

  const onUpdate = async () => {
    // check form on update
    if (!formRef.current.check()) {
      return;
    }
    // update
    setIsPasswordChanging(true);
    try {
      await amplifyUpdatePassword(formValue.oldPassword, formValue.newPassword);
      setModalOpen(false);
      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
      setIsPasswordChanging(false);
    }
  };

  const onCancelUpdate = () => {
    setFormValue(currentData);
    setModalOpen(false);
    navigate(-1);
  };

  const onModalClose = () => {
    if (!window.confirm(`Really want to close the modal`)) {
      return;
    }
    setFormValue(currentData);
    setModalOpen(false);
    navigate(-1);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  const onPasswordIconUpdate = (visiblePass: string) => {
    if (visiblePass !== visiblePassPrev) {
      setVisiblePass(visiblePass);
      setVisiblePassPrev(visiblePass);
    } else {
      setVisiblePass('');
      setVisiblePassPrev('');
    }
  };

  if (formValue === ({} as IChangePassword)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={ChangePasswordStyles}>
      <Modal open={modalOpen} onClose={onModalClose} size='md'>
        <Modal.Header>
          <Modal.Title>Edit an existing account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={onChange}
            onCheck={onCheck}
            model={model}
            fluid
            className='ChangePassword-Form'
          >
            <h4>Account | Password </h4>
            <Form.Group controlId='sub'>
              <Form.ControlLabel>ID</Form.ControlLabel>
              <Form.Control
                id='sub'
                type='text'
                name='sub'
                className='ChangePassword-Input'
                disabled
              />
            </Form.Group>
            <Form.Group controlId='oldPassword'>
              <Form.ControlLabel>Old Password</Form.ControlLabel>
              <InputGroup>
                <Form.Control
                  id='oldPassword'
                  type={visiblePass === 'oldPassword' ? 'text' : 'password'}
                  name='oldPassword'
                  className='ChangePassword-Input'
                />
                <InputGroup.Addon onClick={() => onPasswordIconUpdate('oldPassword')}>
                  {visiblePass === 'oldPassword' ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Addon>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId='newPassword'>
              <Form.ControlLabel>New Password</Form.ControlLabel>
              <InputGroup>
                <Form.Control
                  id='newPassword'
                  type={visiblePass === 'newPassword' ? 'text' : 'password'}
                  name='newPassword'
                  autoComplete='off'
                  className='ChangePassword-Input'
                />
                <InputGroup.Addon onClick={() => onPasswordIconUpdate('newPassword')}>
                  {visiblePass === 'newPassword' ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Addon>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId='conPassword'>
              <Form.ControlLabel>Confirm Password</Form.ControlLabel>
              <InputGroup>
                <Form.Control
                  id='conPassword'
                  type={visiblePass === 'conPassword' ? 'text' : 'password'}
                  name='conPassword'
                  autoComplete='off'
                  className='ChangePassword-Input'
                />
                <InputGroup.Addon onClick={() => onPasswordIconUpdate('conPassword')}>
                  {visiblePass === 'conPassword' ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Addon>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <ButtonToolbar>
                <Button
                  type='button'
                  className={`ChangePassword-Button ${disabled ? 'disabled' : ''}`}
                  onClick={onUpdate}
                  disabled={disabled}
                >
                  Update
                </Button>
                <Button type='button' className='ChangePassword-Button' onClick={onCancelUpdate}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChangePassword;
