/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';

import { Content } from 'rsuite';
import { getYear } from 'rsuite/esm/utils/dateUtils';

const PageBodyStyles = css`
  .PageBody {
    width: 100%;
    min-height: 100vh;
    padding: 30px;
    background: #f4f5f7;

    &-Heading {
      height: 100;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }
  }
`;

function PageBody({ pageTitle, headingText, contentStyle = {}, children, ...rest }: any) {
  return (
    <Content style={{ ...contentStyle }} css={PageBodyStyles} {...rest}>
      <div className='PageBody'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <h2 className='PageBody-Heading'>{headingText}</h2>
        {children}
      </div>
    </Content>
  );
}

export default PageBody;
