import { notDeepEqual } from 'assert';
import React, { useEffect, useState, useRef } from 'react';

function LoaderMessage({ loadingMessage, doneMessage, isLoading }: any) {
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [showDoneMessage, setShowDoneMessage] = useState(false);
  const isLoadingPreviousValue = useRef(null);

  const styles = {
    loading: {
      fontSize: '2em',
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'center',
      margin: 0,
      paddingBottom: '3em',
    },
    visuallyHidden: {
      focus: 'not(:focus)',
      active: 'not(:active)',
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(100%)',
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    },
  } as const;

  useEffect(() => {
    let loadingMessageDelay: NodeJS.Timeout;
    let doneMessageDelay: NodeJS.Timeout;

    if (isLoading) {
      loadingMessageDelay = setTimeout(() => {
        setShowLoadingMessage(true);
      }, 400);
    } else if (isLoadingPreviousValue.current) {
      setShowDoneMessage(true);
      doneMessageDelay = setTimeout(() => {
        setShowLoadingMessage(false);
      }, 300);
    }

    isLoadingPreviousValue.current = isLoading;
    return () => {
      clearTimeout(loadingMessageDelay);
      clearTimeout(doneMessageDelay);
      setShowLoadingMessage(false);
      setShowDoneMessage(false);
    };
  }, [isLoading]);

  return (
    <div aria-live='assertive' aria-atomic='true'>
      {showLoadingMessage && <p style={styles.loading}>{loadingMessage}</p>}
      {showDoneMessage && <p style={styles.visuallyHidden}>{doneMessage}</p>}
    </div>
  );
}

export default LoaderMessage;
