/** @jsxImportSource @emotion/react */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'rsuite/styles/index.less';
import { css, jsx } from '@emotion/react';

import { authStore } from '../../../stores';

import PageBody from '../../layout/PageBody/PageBody';

const AdministratorStyles = css`
  width: 100%;
  min-height: 100vh;

  .Administrator {
    width: 100%;
    padding: 30px;
    min-height: 100vh;
  }
`;

function Administrator() {
  const { isAuthenticated } = authStore;
  return (
    <PageBody
      headingText='Administrator'
      pageTitle='Administrator | DV Lottery Services'
      css={AdministratorStyles}
    >
      <div className='Administrator'>Administrator</div>
    </PageBody>
  );
}
export default observer(Administrator);
