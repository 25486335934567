import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import 'rsuite/styles/index.less';

import MainLayout from '../../layout/MainLayout/MainLayout';

function RegisterButton() {
  return <MainLayout headingText='About'>About Content</MainLayout>;
}

export default RegisterButton;
