import { makeAutoObservable } from 'mobx';

class AuthStore {
  authUser: any;

  authState: any;

  isAuthenticated = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(state: any, user: any) {
    this.authState = state;
    this.authUser = { ...this.authState, ...user };
  }

  setIsAuthenticated(state: boolean) {
    this.isAuthenticated = state;
  }
}

// The 'new' keyword here to export ONLY one instace of CommonStore to be used for every import statement.
const authStore = new AuthStore();
export default authStore;
