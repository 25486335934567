/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { css, jsx } from '@emotion/react';

// ui-libray
import { Col, Panel, Row } from 'rsuite';

// google map
import {
  GoogleMap,
  InfoWindowF,
  MarkerClusterer,
  MarkerF,
  useLoadScript,
} from '@react-google-maps/api';

// components
import PageBody from '../../layout/PageBody/PageBody';

const ContactUsStyles = css`
  .ContactUs {
    width: 100%;
    padding: 20px;
    background: white;
    min-height: 100vh;

    &-Panel-Card {
      width: 100%;
      min-height: 500px;
      height: 100vh;
      display: 'inline-block';
      text-align: left;

      &-Header {
        font: 24px strong;
        color: rgb(0, 56, 117);
      }
    }

    // &-Panel-Map {
    //   padding: 0;
    //   margin: 0;
    // }
  }
`;

function ContactUs() {
  const [map, setMap] = useState(null as any);
  const [selected, setSelected] = useState({} as any);

  const mapRef = useRef();

  const onSelect = (item: any) => {
    // map.setCenter(item.location);
    // map.setZoom(18);

    console.log(map);
    setSelected(item);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAPQ4vKvmTuh_fCyk1jfyatmYryPzrClb4', // ,
    // ...otherOptions
  });

  const mapStyles = {
    minHeight: '500px',
    height: '100vh',
    width: '100%',
  };

  const defaultCenter = {
    lat: 13.5941617,
    lng: 102.9721893,
  };

  const locations = [
    {
      name: 'DV Lottery Services',
      addressLine1: '5500 Wissahickon Avnue',
      addressLine2: 'Philadelphia, PA 19144',
      url: 'https://www.google.com/maps/place/40.0263513,-75.1849167',
      location: {
        lat: 40.0267386,
        lng: -75.1861731,
      },
    },
    {
      name: 'ដី ពូទីចាស់ ផ្លូវលេខ១.៥',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.5941617,102.9721893',
      location: { lat: 13.5941617, lng: 102.9721893 },
    },
    {
      name: 'ដី ផ្លវលេខ២ (ម៉ា)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.594100,102.972217',
      location: { lat: 13.5941, lng: 102.972217 },
    },
    {
      name: 'ដី ផ្លវលេខ២ (ហ៊ុម 1)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.594072,102.972236',
      location: { lat: 13.594072, lng: 102.972236 },
    },
    {
      name: 'ដី ផ្លវលេខ២ (ហ៊ុម 2)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.594036,102.972256',
      location: { lat: 13.594036, lng: 102.972256 },
    },
    {
      name: 'ដី ផ្លូវលេខ២ (ឃ្លាំង)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.5941617,102.9721893',
      location: { lat: 13.5941617, lng: 102.9721893 },
    },
    {
      name: 'ដី ក្រោយសាលាខ្លាកូនថ្មី',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.60665,102.95803',
      location: { lat: 13.60665, lng: 102.95803 },
    },
    {
      name: 'ដី ផ្សាចំការគោ',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.603424,102.968498',
      location: { lat: 13.603424, lng: 102.968498 },
    },
    {
      name: 'ដី ជាប់សាលាក្រុង (ភា)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.610052,102.961539',
      location: { lat: 13.610052, lng: 102.961539 },
    },
    {
      name: 'ដី ជាប់សាលាក្រុង (គីមឡេង)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.610052,102.961539',
      location: { lat: 13.610052, lng: 102.961539 },
    },
    {
      name: 'ដី ព្រះពន្លា មាត់ស្ទឹង',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.585359,102.976254',
      location: { lat: 13.585359, lng: 102.976254 },
    },
    {
      name: 'ដី ផ្សាសិរីសោភ័ណ',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.5867947,102.9718283',
      location: { lat: 13.5867947, lng: 102.9718283 },
    },
    {
      name: 'ដី សែរសិនជាមួយ មាស សុភា (កាត់ចូលផ្លូវ30)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.562270,102.956898',
      location: { lat: 13.56227, lng: 102.956898 },
    },
    {
      name: 'ដី សែរសិនជាមួយ មាស សុភា (នៅសល់)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.562270,102.956898',
      location: { lat: 13.56227, lng: 102.956898 },
    },
    {
      name: 'ដី សែរសិនជាមួយ មាស សុភា (ចំនែកចិក)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.562270,102.956898',
      location: { lat: 13.56227, lng: 102.956898 },
    },
    {
      name: 'ដី សែរសិនជាមួយហេង (កាត់ចូលផ្លូវ30)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.559691,102.956548',
      location: { lat: 13.559691, lng: 102.956548 },
    },
    {
      name: 'ដី សែរសិនជាមួយហេង (នៅសល់)',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.559691,102.956548',
      location: { lat: 13.559691, lng: 102.956548 },
    },
    {
      name: 'ដី សែរសិន ផ្លូវ៦០ ច្រមុះជ្រូក',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.559691,102.956548',
      location: { lat: 13.559691, lng: 102.956548 },
    },
    {
      name: 'ដី ភ្នំតូច',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.4224698,103.016673',
      location: { lat: 13.4224698, lng: 103.016673 },
    },
    {
      name: 'ដី វត្តត្រាច',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      url: 'https://www.google.com/maps/place/13.665568,102.561039',
      location: { lat: 13.665568, lng: 102.561039 },
    },
  ];

  const renderMap = () => {
    console.log('OK');

    return (
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={12}
        center={defaultCenter}
        id='mainMap'
        onLoad={(mapInstance) => setMap(mapInstance)}
      >
        ...
        {locations.map((item) => (
          <MarkerF key={item.name} position={item.location} onClick={() => onSelect(item)} />
        ))}
        {selected.location && (
          <InfoWindowF position={selected.location} onCloseClick={() => setSelected({})}>
            <a
              href={selected.url}
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none' }}
            >
              <h4>{selected.name}</h4>
              <p>{selected.addressLine1}</p>
              <p>{selected.addressLine2}</p>
            </a>
          </InfoWindowF>
        )}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return (
    <PageBody
      headingText='Contact Us'
      pageTitle='Contact Us | DV Lottery Services'
      css={ContactUsStyles}
    >
      <div className='ContactUs'>
        <Row>
          <Col md={6} sm={6}>
            <Panel bordered className='ContactUs-Panel-Card'>
              <Panel>
                <div className='ContactUs-Panel-Card-Header'>DV Lottery Services</div>
              </Panel>
              <Panel header='Address'>
                <div>5500 Wissahickon Avenue</div>
                <div>Philadelphia, 19144</div>
              </Panel>

              <Panel header='Contact'>
                <div>(215)-1234-5678</div>
                <div>dvlottoservices@gmail.com</div>
              </Panel>
              <Panel header='Follow Us'>
                <div>https://www.facebook.com/dv-lotto-services</div>
                <div>https://twitter.com/oudammeas</div>
              </Panel>
            </Panel>
          </Col>
          <Col md={18} sm={18}>
            {isLoaded ? renderMap() : <div>Loading...</div>}
          </Col>
        </Row>
      </div>
    </PageBody>
  );
}

export default React.memo(ContactUs);
