import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import '@aws-amplify/ui-react/styles.css';
import { authStore } from '../stores';

export const amplifyGetUser = async () => {
  const { isAuthenticated } = authStore;
  try {
    const authUser = await Auth.currentAuthenticatedUser();
    // update authStore if not already authenticated
    if (!isAuthenticated) {
      authStore.setAuth(AuthState.SignedIn, authUser);
      authStore.setIsAuthenticated(true);
    }
    return authUser;
  } catch (error) {
    // update authStore
    const authState = AuthState.SignedOut;
    authStore.setAuth(authState, {});
    authStore.setIsAuthenticated(false);
    throw(error);
  }
}

export const amplifySignIn = async (username: string, password: string) => {
  try {
    const user = Auth.signIn(username, password);
    authStore.setAuth(AuthState.SignedIn, user);
    authStore.setIsAuthenticated(true);
  } catch (error) {
    console.warn('error signing in: ', error);
    throw(error);
  }
};

export const amplifySignOut = async () => {
  try {
    await Auth.signOut({ global: true});
    authStore.setAuth(AuthState.SignedOut, null);
    authStore.setIsAuthenticated(false);
  } catch (error) {
    console.warn('error signing out: ', error);
    throw(error);
  }
};

export const amplifyUpdateAttributes = async (attributes: any)  => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const status = await Auth.updateUserAttributes(user, {...attributes});
    const updatedUser = await Auth.currentAuthenticatedUser();
    authStore.setAuth(AuthState.SignedIn, updatedUser);
    return status;
  } catch (error) {
    console.warn('error updating user account: ', error);
    throw(error);
  }
}

export const amplifyUpdateEmail = async (email: { email: any})  => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const status = await Auth.updateUserAttributes(user, {...email})
    if (status === 'SUCCESS'){
      const updatedUser = await Auth.currentAuthenticatedUser();
      authStore.setAuth(AuthState.SignedIn, updatedUser);
    }
    return status;
  } catch (error) {
    console.log('error updating email: ', error);
    throw(error);
  }
}

export const amplifyConfirmEmail = async (code: any)  => {
  try {
    const status = await Auth.verifyCurrentUserAttributeSubmit('email', code);
    if (status === 'SUCCESS'){
      const updatedUser = await Auth.currentAuthenticatedUser();
      authStore.setAuth(AuthState.SignedIn, updatedUser);
    }
    return status;
  } catch (error) {
    console.warn('error confirming email: ', error);
    throw(error);
  }
}

export const amplifyUpdatePassword = async (oldPassword: any, newPassword: any)  => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const status = await Auth.changePassword(user, oldPassword, newPassword);
    return status;
  } catch (error) {
    console.warn('error changing password: ', error);
    throw(error);
  }
}

export const amplifyDeleteUser = async ()  => {
  try {
    const status = await Auth.deleteUser();
    return status;
  } catch (error) {
    console.warn('error deleting user account: ', error);
    throw(error);
  }
}