
import { Storage } from 'aws-amplify';
import { EagerS3Object, S3Object } from '../models';

export const amplifyGetSignedURL = async (s3Object: S3Object | EagerS3Object) => {
  let signedURL: any;
  const key = s3Object.key || '';
  try {
    signedURL = await Storage.get(key, {
      // expires: 900, // 15 mn which is default
    });
  } catch (error) {
    console.warn(error);
  }
  return signedURL;
};

export const amplifyUploadFile = async (file: File| undefined, key: string) => {
  let result: any = null;
  if (file) {
    try {
      result = await Storage.put(key, file, { contentType: file.type });
    } catch (error) {
      console.warn('Error uploading file', error);
      throw error;
    }
  }
  return result;
};

export const amplifyListFile = async (path: string) => {
  let result: any[] = [];
  try {
    result = await Storage.list(path);
  } catch (error) {
    console.warn(`Error listing files from specified path: ${path}`, error);
    throw error;
  }
  return result;
}

export const amplifyRemoveFile = async (key: string) => {
  let result: any = null;
  try {
    result = await Storage.remove(key);
  } catch (error) {
    console.warn(`Error removing files with key: ${key}`, error);
    throw error;
  }
  return result;
}

