/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';
import { Button, Panel, PanelGroup, Placeholder } from 'rsuite';

// dataStore
import { DataStore } from 'aws-amplify';
import { Payment as PaymentModel } from '../../../../models';

// store
import { authStore } from '../../../../stores';

// components
import LoaderMessage from '../../../elements/LoaderMessage/LoaderMessage';
import PageBody from '../../../layout/PageBody/PageBody';
import Payment from './Payment';

const PaymentPageStyles = css`
  width: 100%;
  .PaymentPage {
    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

function PaymentPage() {
  const { authUser } = authStore;
  const [isLoading, setIsLoading] = useState(true);
  const [payment, setPayment] = useState({} as PaymentModel);

  const params = useParams();
  const userID = params?.userID || '';

  const navigate = useNavigate();

  const location = useLocation();

  // queries
  const fetchPayment = async () => {
    let data: PaymentModel[] = [];
    try {
      data = (await DataStore.query(PaymentModel)).filter((c) => c.id === userID);
    } catch (error) {
      console.log('fetch cases error:', error);
      navigate(-1);
    }
    return data;
  };

  // subscriptions
  const subscriptions = () => {
    // payment
    const paymentSubscription = DataStore.observe(PaymentModel).subscribe(() => fetchPayment());
    return [paymentSubscription];
  };

  // fetch data on componentmount and location change
  useEffect(() => {
    setIsLoading(true);
    try {
      fetchPayment();
    } catch (error) {
      console.log('fetch data error:', error);
    }
    setIsLoading(false);
  }, [location]);

  // call data subcriptions / unsubscriptions
  useEffect(() => {
    // when component did mount
    const subs = subscriptions();

    // when component did unmount
    return () => {
      subs.forEach((s: any) => s.unsubscribe());
    };
  }, []);

  return (
    <PageBody
      headingText='PaymentPage'
      pageTitle='Payment | DV Lottery Services'
      css={PaymentPageStyles}
    >
      <div className='PaymentPage'>
        {!isLoading && (
          <Payment
            data={payment}
            header='Payment'
            toEdit={`/settings/${userID}/payments/${payment.id}/edit`}
          />
        )}
        <LoaderMessage
          loadingMessage='Loading payment data'
          doneMessage='Loading case payment complete'
          isLoading={isLoading}
        />
      </div>
    </PageBody>
  );
}

export default observer(PaymentPage);
