/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Button, ButtonToolbar, Form, Modal, Schema, SelectPicker } from 'rsuite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// dataStore
import { DataStore } from 'aws-amplify';
import {
  Application as ApplicationModel,
  Contact as ContactModel,
  Relationship as RelationshipModel,
  Profile as ProfileModel,
  Education as EducationModel,
  Employment as EmploymentModel,
  Address as AddressModel,
} from '../../../../../../models';

// store
import { authStore } from '../../../../../../stores';

const RelationshipEditStyles = css`
  .RelationshipEdit {
    // color: #fff;
    // background: #2a2c37;
    border-radius: 6px;
    padding: 15px;
    border: 1px solid #e5e5ea;

    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;

    //   :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

const typeSelectData = ['spouse', 'children', 'parents', 'siblings', 'fiance'].map((item) => ({
  label: item,
  value: item,
}));

interface IRelationshipEditProps {
  isEdit: boolean;
}

const initialState: RelationshipModel = {
  id: '',
  type: '',
  matchApplicationID: '',
  applicationID: '',
};

const model = Schema.Model({
  type: Schema.Types.StringType().isRequired('This field is required'),
});

function RelationshipEdit({ isEdit }: IRelationshipEditProps) {
  const { isAuthenticated, authUser } = authStore;

  const [formValue, setFormValue] = useState({} as RelationshipModel);
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [applicationType, setApplicationType] = useState('');

  const params = useParams();
  const caseID = params?.caseID || '';
  const applicationID = params?.applicationID || '';

  const { state } = useLocation();

  const data: RelationshipModel = state?.data;

  const navigate = useNavigate();

  const formRef = useRef() as any;

  const handlerError = (error: any) => {
    window.alert(`Error occurs: ${JSON.stringify(error)}`);
  };

  const getCorrespondingRelationshipType = (type: string): string | undefined => {
    switch (type) {
      case 'spouse':
        return 'spouse';
      case 'children':
        return 'parents';
      case 'parenets':
        return 'children';
      case 'siblings':
        return 'siblings';
      case 'fiance':
        return 'fiance';
      default:
        console.error('wrong relationship type');
        return undefined;
    }
  };

  const getApplicationType = async (applicationID: string): Promise<string | undefined> => {
    try {
      // fetch application
      const currentApplication = await DataStore.query(ApplicationModel, applicationID);
      return currentApplication?.type;
    } catch (error) {
      handlerError(error);
    }
    return undefined;
  };

  useEffect(() => {
    if (!isEdit) {
      setFormValue({ ...initialState, applicationID });
    } else {
      getApplicationType(applicationID).then((applicationType) => {
        if (applicationType === 'main') {
          setCanEdit(true);
        }
        setApplicationType((type) => applicationType || type);
      });
      setFormValue({ ...data });
    }
  }, [data]);

  const onChange = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const onCheck = (error: any) => {
    if (Object.keys(error).length > 0) {
      setDisabled(true);
      setFormError({ ...formError, ...error });
    } else {
      setDisabled(false);
    }
  };

  const onCreate = async (e: any) => {
    formRef.current.check(async (error: any) => {
      // check error
      if (Object.keys(error).length > 0) {
        setDisabled(true);
        setFormError({ ...formError, ...error });
        return;
      }
      // create
      try {
        // fetch the current application
        const currentApplication = await DataStore.query(ApplicationModel, applicationID);

        // create the match application
        const application = await DataStore.save(
          new ApplicationModel({
            type: formValue.type || 'main',
            caseID,
          }),
        );

        // create the match contact
        const contact = await DataStore.save(
          new ContactModel({
            applicationID: application.id,
          }),
        );

        // create the match application
        const profile = await DataStore.save(
          new ProfileModel({
            applicationID: application.id,
          }),
        );

        // create the match address
        const address = await DataStore.save(
          new AddressModel({
            applicationID: application.id,
          }),
        );

        // create the match employment
        const employment = await DataStore.save(
          new EmploymentModel({
            applicationID: application.id,
          }),
        );

        // create the match education
        const education = await DataStore.save(
          new EducationModel({
            applicationID: application.id,
          }),
        );

        // create the match relationship
        const relationship = await DataStore.save(
          new RelationshipModel({
            type: getCorrespondingRelationshipType(formValue.type as any),
            matchApplicationID: currentApplication?.id,
            applicationID: application.id,
          }),
        );

        // create the current relationship
        const currentRelationship = await DataStore.save(
          new RelationshipModel({ ...formValue, matchApplicationID: application.id }),
        );

        setDisabled(false);
        setModalOpen(false);
        console.log('A contact was successfully created');

        // navigate
        navigate(-1);
      } catch (error) {
        handlerError(error);
      }
    });
  };

  const onClear = () => {
    if (!window.confirm(`Really clear the form`)) {
      return;
    }
    setFormValue(initialState);
  };

  const onCancelCreate = () => {
    if (!window.confirm(`Really cancel the form creation`)) {
      return;
    }
    setFormValue(initialState);
    setModalOpen(false);
    navigate(-1);
  };

  const onUpdate = async () => {
    // check form on update
    if (!formRef.current.check()) {
      return;
    }
    // delete
    try {
      /* Models in DataStore are immutable. To update a record you must use the copyOf function
      to apply updates to the item’s fields rather than mutating the instance directly */
      const currentItem: any = await DataStore.query(RelationshipModel, formValue.id);
      await DataStore.save(
        RelationshipModel.copyOf(currentItem, (updated) => {
          updated.type = formValue.type;
          updated.matchApplicationID = formValue.matchApplicationID;
          updated.applicationID = formValue.applicationID;
        }),
      );
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onCancelUpdate = () => {
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onDelete = async () => {
    // confirm action
    if (!window.confirm(`Really delete ${formValue.id}`)) {
      return;
    }
    // delete
    try {
      const modelToDelete: any = await DataStore.query(RelationshipModel, formValue.id);
      DataStore.delete(modelToDelete);
      // delete the matchApplication if the application is of type "main"
      if (applicationType === 'main') {
        const appModelToDelete: any = await DataStore.query(
          ApplicationModel,
          formValue.matchApplicationID || '',
        );
        DataStore.delete(appModelToDelete);
      }
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onModalClose = () => {
    if (!window.confirm(`Really want to close the modal`)) {
      return;
    }
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  if (formValue === ({} as RelationshipModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={RelationshipEditStyles}>
      <Modal open={modalOpen} onClose={onModalClose} size='md'>
        <Modal.Header>
          <Modal.Title>
            {isEdit ? 'Edit an existing contact' : 'Create a new Relationship'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={onChange}
            onCheck={onCheck}
            model={model}
            fluid
          >
            <h2>Relationship</h2>
            <Form.Group controlId='id'>
              <Form.Control id='id' type='hidden' name='id' className='Relationship-Input' />
            </Form.Group>
            <Form.Group controlId='type'>
              <Form.ControlLabel>Type:</Form.ControlLabel>
              <Form.Control
                id='type'
                accepter={SelectPicker}
                data={typeSelectData}
                name='type'
                className='Relationship-Input'
                disabled={isEdit}
              />
            </Form.Group>
            {isEdit && (
              <Form.Group controlId='matchApplicationID'>
                <Form.ControlLabel>Match Application ID:</Form.ControlLabel>
                <Form.Control
                  id='matchApplicationID'
                  type='text'
                  name='matchApplicationID'
                  className='Relationship-Input'
                  disabled
                />
              </Form.Group>
            )}
            <Form.Group controlId='applicationID'>
              <Form.ControlLabel>Applcation ID:</Form.ControlLabel>
              <Form.Control
                id='applicationID'
                type='text'
                name='applicationID'
                className='Relationship-Input'
                disabled
              />
            </Form.Group>
            {!isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`RelationshipEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onCreate}
                    disabled={disabled}
                  >
                    Create
                  </Button>
                  <Button type='button' className='RelationshipEdit-Button' onClick={onClear}>
                    Clear
                  </Button>
                  <Button
                    type='button'
                    className='RelationshipEdit-Button'
                    onClick={onCancelCreate}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}

            {isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`RelationshipEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onUpdate}
                    disabled
                  >
                    Update
                  </Button>
                  <Button
                    type='button'
                    className='RelationshipEdit-Button'
                    onClick={onDelete}
                    disabled={!canEdit}
                  >
                    Delete
                  </Button>
                  <Button
                    type='button'
                    className='RelationshipEdit-Button'
                    onClick={onCancelUpdate}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RelationshipEdit;
