/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { css, jsx } from '@emotion/react';

// ui library
import 'rsuite/styles/index.less';
import { Form, Button, ButtonToolbar, Schema, Modal } from 'rsuite';

// services
import {
  amplifyUpdateAttributes,
  amplifyDeleteUser,
} from '../../../../services/AuthenticationService';

// store
import { authStore } from '../../../../stores';

const ChangeAccountStyles = css`
  .ChangeAccount {
    color: #fff;
    background: #2a2c37;
    border-radius: 6px;
    padding: 15px;

    &-Label {
      width: 300px;
    }

    &-Input {
      width: 300px;
      border: 1px solid transparent;
      color: #fff;
      background: #1d1e26;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 6px;
      outline: 0;
      &:focus {
        border-color: #50fa7b;
      }
    }
    &-Textarea {
      min-height: 80px;
      resize: none;
    }
    &-Button {
      width: 300px;
      // border: 2px solid #50fa7b;
      // color: blue;
      // background: none;
      // padding: 10px 15px;
      // border-radius: 6px;
      // outline: 0;
      // cursor: pointer;
      // font-weight: 600;
      // text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

interface IChangeAccount {
  sub: string | null;
  given_name: string | null;
  family_name: string | null;
}

const initialState: IChangeAccount = {
  sub: '',
  given_name: '',
  family_name: '',
};

const model = Schema.Model({
  sub: Schema.Types.StringType().isRequired('This field is required.'),
  given_name: Schema.Types.StringType().isRequired('This field is required'),
  family_name: Schema.Types.StringType().isRequired('This field is required'),
});

function ChangeAccount() {
  const { isAuthenticated, authUser } = authStore;

  const [formValue, setFormValue] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  const currentData: IChangeAccount = {
    sub: authUser.attributes.sub,
    given_name: authUser.attributes.given_name,
    family_name: authUser.attributes.family_name,
  };

  console.log('currentData', currentData);

  useEffect(() => {
    setFormValue({
      ...currentData,
    });
  }, [authUser]);

  const onChange = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const onCheck = (error: any) => {
    if (Object.keys(error).length > 0) {
      setDisabled(true);
      setFormError({ ...formError, ...error });
    } else {
      setDisabled(false);
    }
  };

  const handlerError = (error: any) => {
    window.alert(`Error occurs: ${JSON.stringify(error)}`);
  };

  const onClear = () => {
    if (!window.confirm(`Really clear the form`)) {
      return;
    }
    setFormValue(initialState);
  };

  const onUpdate = async () => {
    // check form on update
    if (!formRef.current.check()) {
      return;
    }
    // delete
    try {
      await amplifyUpdateAttributes({ ...formValue });
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onCancelUpdate = () => {
    setFormValue(currentData);
    setModalOpen(false);
    navigate(-1);
  };

  const onDelete = async () => {
    // confirm action
    if (!window.confirm(`Really delete ${formValue.sub}`)) {
      return;
    }
    // delete
    try {
      await amplifyDeleteUser();
      setModalOpen(false);
      // navigate
      navigate('/');
    } catch (error) {
      handlerError(error);
    }
  };

  const onModalClose = () => {
    if (!window.confirm(`Really want to close the modal`)) {
      return;
    }
    setFormValue(currentData);
    setModalOpen(false);
    navigate(-1);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  if (formValue === ({} as IChangeAccount)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={ChangeAccountStyles}>
      <Modal open={modalOpen} onClose={onModalClose} size='md'>
        <Modal.Header>
          <Modal.Title>Edit an existing account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={onChange}
            onCheck={onCheck}
            model={model}
            fluid
            className='ChangeAccount-Form'
          >
            <h4>Account | Information</h4>
            <Form.Group controlId='sub'>
              <Form.ControlLabel>ID</Form.ControlLabel>
              <Form.Control
                id='sub'
                type='text'
                name='sub'
                className='ChangeAccount-Input'
                disabled
              />
            </Form.Group>
            <Form.Group controlId='given_name'>
              <Form.ControlLabel>Firstname</Form.ControlLabel>
              <Form.Control
                id='given_name'
                type='text'
                name='given_name'
                className='ChangeAccount-Input'
              />
            </Form.Group>
            <Form.Group controlId='family_name'>
              <Form.ControlLabel>Lastname</Form.ControlLabel>
              <Form.Control
                id='family_name'
                type='text'
                name='family_name'
                className='ChangeAccount-Input'
              />
            </Form.Group>
            <Form.Group>
              <ButtonToolbar>
                <Button
                  type='button'
                  className={`ChangeAccount-Button ${disabled ? 'disabled' : ''}`}
                  onClick={onUpdate}
                  disabled={disabled}
                >
                  Update
                </Button>
                <Button type='button' className='ChangeAccount-Button' onClick={onDelete}>
                  Delete
                </Button>
                <Button type='button' className='ChangeAccount-Button' onClick={onCancelUpdate}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChangeAccount;
