import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { authStore } from '../../../stores';

export function RequireAuth({ children }: any) {
  const location = useLocation();
  // auth status from local
  const { isAuthenticated } = authStore;
  if (!isAuthenticated) {
    return <Navigate to='/authenticator' state={{ from: location }} replace />;
  }
  return children;
}
