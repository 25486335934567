/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { Button, CheckPicker, Form } from 'rsuite';
import { Case as CaseModel } from '../../../../models';

const CaseStyles = css`
  // color: #fff;
  // background: #2a2c37;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #e5e5ea;

  .Case {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

const selectData = [
  'TYPE1',
  'TYPE2',
  'TYPE3',
  'TYPE4',
  'TYPE5',
  'TYPE6',
  'TYPE7',
  'TYPE8',
  'TYPE9',
  'TYPE10',
].map((item) => ({
  label: item,
  value: item,
}));

interface ICaseProps {
  data?: CaseModel;
  order?: number;
  header?: any;
  toEdit?: string;
  plainText?: boolean;
}

const initialState: CaseModel = {
  id: '',
  userID: '',
  Applications: null,
  Deadline: null,
  services: [],
  Touchpoint: null,
};

function Case({
  data = initialState,
  order = 0,
  header = `Case ${order + 1}`,
  toEdit = 'edit',
  plainText = false,
}: ICaseProps) {
  const [formValue, setFormValue] = useState(initialState);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    const formattedData = { ...data };
    if (formattedData.Deadline) (formattedData.Deadline as unknown as any) = data.Deadline?.id;
    if (formattedData.Touchpoint)
      (formattedData.Touchpoint as unknown as any) = data.Touchpoint?.id;
    if (formattedData.Applications && formattedData.Applications.length > 0)
      (formattedData.Applications as unknown as any) = data.Applications?.map((a: any) => a?.id);

    setFormValue({ ...formattedData });
  }, [data, order]);

  const onEdit = () => {
    navigate(toEdit, { state: { data } });
  };

  if (formValue === ({} as CaseModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={CaseStyles}>
      <Form ref={formRef} readOnly formValue={formValue} fluid>
        <h4>{header}</h4>
        <Form.Group controlId='id'>
          <Form.Control id='id' type='hidden' name='id' className='Case-Input' />
        </Form.Group>
        <Form.Group controlId='services'>
          <Form.ControlLabel>Services:</Form.ControlLabel>
          <Form.Control id='services' type='text' name='services' className='Case-Input' />
        </Form.Group>
        <Form.Group controlId='Deadline'>
          <Form.ControlLabel>Deadline:</Form.ControlLabel>
          <Form.Control id='Deadline' type='text' name='Deadline' className='Case-Input' />
        </Form.Group>
        <Form.Group controlId='Touchpoint'>
          <Form.ControlLabel>Touchpoint:</Form.ControlLabel>
          <Form.Control id='Touchpoint' type='text' name='Touchpoint' className='Case-Input' />
        </Form.Group>
        <Form.Group controlId='userID'>
          <Form.ControlLabel>User ID:</Form.ControlLabel>
          <Form.Control id='userID' type='text' name='userID' className='Case-Input' />
        </Form.Group>
        <Form.Group controlId='Applications'>
          <Form.ControlLabel>Applications:</Form.ControlLabel>
          <Form.Control id='Applications' type='text' name='Applications' className='Case-Input' />
        </Form.Group>
        {!plainText && (
          <Form.Group>
            <Button type='button' appearance='primary' className='Case-Button' onClick={onEdit}>
              Edit Case
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}

export default Case;
