/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';
import { Button, Panel, PanelGroup, Placeholder } from 'rsuite';

// dataStore
import { DataStore } from 'aws-amplify';
import { Billing as BillingModel } from '../../../../models';

// store
import { authStore } from '../../../../stores';

// components
import LoaderMessage from '../../../elements/LoaderMessage/LoaderMessage';
import PageBody from '../../../layout/PageBody/PageBody';
import Billing from './Billing';

const BillingPageStyles = css`
  width: 100%;
  .BillingPage {
    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

function BillingPage() {
  const { authUser } = authStore;
  const [isLoading, setIsLoading] = useState(true);
  const [billing, setBilling] = useState({} as BillingModel);

  const params = useParams();
  const userID = params?.userID || '';

  const navigate = useNavigate();

  const location = useLocation();

  // queries
  const fetchBilling = async () => {
    let data: BillingModel[] = [];
    try {
      data = (await DataStore.query(BillingModel)).filter((c) => c.id === userID);
    } catch (error) {
      console.log('fetch cases error:', error);
      navigate(-1);
    }
    return data;
  };

  // subscriptions
  const subscriptions = () => {
    // payment
    const paymentSubscription = DataStore.observe(BillingModel).subscribe(() => fetchBilling());
    return [paymentSubscription];
  };

  // fetch data on componentmount and location change
  useEffect(() => {
    setIsLoading(true);
    try {
      fetchBilling();
    } catch (error) {
      console.log('fetch data error:', error);
    }
    setIsLoading(false);
  }, [location]);

  // call data subcriptions / unsubscriptions
  useEffect(() => {
    // when component did mount
    const subs = subscriptions();

    // when component did unmount
    return () => {
      subs.forEach((s: any) => s.unsubscribe());
    };
  }, []);

  return (
    <PageBody
      headingText='Billing'
      pageTitle='Billing | DV Lottery Services'
      css={BillingPageStyles}
    >
      <div className='BillingPage'>
        {!isLoading && (
          <Billing
            data={billing}
            header='Billing'
            toEdit={`/settings/${userID}/billings/${billing.id}/edit`}
          />
        )}
        <LoaderMessage
          loadingMessage='Loading billing data'
          doneMessage='Loading case billing complete'
          isLoading={isLoading}
        />
      </div>
    </PageBody>
  );
}

export default observer(BillingPage);
