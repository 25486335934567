/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { TabItem, Tabs } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react';
import { css, jsx } from '@emotion/react';

import 'rsuite/styles/index.less';
import {
  Button,
  ButtonToolbar,
  Modal,
} from 'rsuite';


// dataStore
import { DataStore } from 'aws-amplify';
import {
  Application as ApplicationModel,
  EagerApplication,
} from '../../../../../models';

// store
import { authStore } from '../../../../../stores';

// components
import LoaderMessage from '../../../../elements/LoaderMessage/LoaderMessage';
import PageBody from '../../../../layout/PageBody/PageBody';
import ApplicationTab from './ApplicationTab';

const ApplicationPageStyles = css`
  width: 100%;
  .ApplicationPage {
    background: #fff;
    &-Heading = {
      min-height: 100vh,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }

    &-Button {
      margin: 10px;

      // border: 2px solid #50fa7b;
      // color: #50fa7b;
      // background: none;
      // padding: 10px 15px;
      // border-radius: 6px;
      // outline: 0;
      // cursor: pointer;
      // font-weight: 600;
      // text-transform: uppercase;

      // :disabled {
      //   color: grey;
      // }
    }
  }
`;

enum AppFormType {
  null = 'NULL',
  Contact = 'CONTACT',
  Address = 'ADDRESS',
  Education = 'EDUCATION',
  Employment = 'EMPLOYMENT',
  Relationship = 'RELATIONSHIP',
  Profile = 'PROFILE',
}

function ApplicationPage() {
  const { authUser } = authStore;

  const [isLoading, setIsLoading] = useState('null');
  const [applications, setApplications] = useState([] as any);
  const [currentTab, setCurrentTab] = useState(0);
  const [previousTab, setPreviousTab] = useState(0);
  const [submitModal, setSubmitModal] = useState(false);

  const params = useParams();
  const userID = params?.userID || '';
  const caseID = params?.caseID || '';

  const navigate = useNavigate();

  const location = useLocation();

  // queries
  const fetchApplications = async () => {
    try {
      const data = (await DataStore.query(ApplicationModel)).filter((x) => x.caseID === caseID);
      const currentIndex = 0;
      setApplications(data);
      setIsLoading('null');
    } catch (error) {
      console.log('fetch application error:', error);
      navigate(-1);
    }
  };

  // subscriptions
  const subscriptions = () => {
    // cotact
    const applicationSubscription = DataStore.observe(ApplicationModel).subscribe(() =>
      fetchApplications(),
    );

    return [applicationSubscription];
  };

  // call data subcriptions / unsubscriptions
  useEffect(() => {
    // when component did mount
    const subs = subscriptions();

    // when component did unmount
    return () => {
      subs.forEach((s: any) => s.unsubscribe());
    };
  }, []);

  // automatically fetch application setting data when page load
  useEffect(() => {
    fetchApplications();
  }, []);

  const onTab = (tab: number) => {
    setPreviousTab(currentTab);
    setCurrentTab(tab);
  };

  const onPrevious = () => {
    setPreviousTab(currentTab);
    setCurrentTab((tab) => Math.max(tab - 1, 0));
  };

  const onNext = () => {
    setPreviousTab(currentTab);
    setCurrentTab((tab) => Math.min(tab + 1, applications.length - 1));
  };

  const onFromTab = () => {
    switch (currentTab) {
      case previousTab:
        return 0;
      case previousTab - 1:
        return -1;
      case previousTab + 1:
        return 1;
      default:
        return 0;
    }
  };

  // submission
  const onSubmit = () => {
    console.log('Proceed with application submission');
    setSubmitModal(false);
  };

  const onCancelSubmit = () => {
    console.log('Cancel application Submission');
    setSubmitModal(false);
  };

  return (
    <PageBody
      headingText='Application'
      pageTitle='Applications | DV Lottery Services'
      css={ApplicationPageStyles}
    >
      <div className='ApplicationPage'>
        <Outlet />

        <Tabs currentIndex={currentTab}>
          {applications.map((a: EagerApplication, index: number) => (
            <TabItem
              title={`Application - ${index + 1} - ${a.type}`}
              style={{ padding: 20 }}
              onClick={() => onTab(index)}
              key={`${index + 1}-${a.id}`}
            >
              <ApplicationTab
                applicationID={a.id}
                previous={() => onPrevious()}
                next={() => onNext()}
                fromTab={onFromTab()}
                submit={() => setSubmitModal(true)}
              />
            </TabItem>
          ))}
        </Tabs>
        <Modal open={submitModal} onClose={onCancelSubmit} size='sm'>
          <Modal.Header>
            <Modal.Title>Application Submission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>
              When you submit this application, you will not be able to make any changes. Do you
              still want to proceed with the application submission?
            </span>
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>
              <Button type='button' className='ApplicationEdit-Button' onClick={onSubmit}>
                Yes
              </Button>
              <Button type='button' className='ApplicationEdit-Button' onClick={onCancelSubmit}>
                No
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Modal>
      </div>
    </PageBody>
  );
}

export default observer(ApplicationPage);
