import React, { memo, useState } from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';

import 'rsuite/styles/index.less';

import { Container } from 'rsuite';

// layout components
import PageSidebar from '../PageSidebar/PageSidebar';
import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';

const MainLayout = memo(({ children }: any) => {
  // anything
  console.log('ok');
  return (
    <div className='show-fake-browser sidebar-page'>
      <Container>
        <PageSidebar />
        <Container>
          <PageHeader />
          {children}
          <PageFooter />
        </Container>
      </Container>
    </div>
  );
});

export default MainLayout;
