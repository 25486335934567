/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { Button, Form, Table } from 'rsuite';
import { FileType } from 'rsuite/esm/Uploader';
import { Education as EducationModel, S3Object } from '../../../../../../models';
import { Document } from '../../../../../elements/Document';
import { DocumentModel, fetchFiles } from '../../../../../../utils/DocumentUtils';

const EducationStyles = css`
  // color: #fff;
  // background: #2a2c37;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #e5e5ea;

  .Education {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

interface IEducationProps {
  data?: EducationModel;
  order?: number;
  header?: any;
  toEdit?: string;
  plainText?: boolean;
}

const initialState: EducationModel = {
  id: '',
  degree: null,
  degreeDocument: null,
  institution: null,
  dateStart: null,
  dateEnd: null,
  applicationID: '',
};

function Education({
  data = initialState,
  order = 0,
  header = `Education ${order + 1}`,
  toEdit = 'edit',
  plainText = false,
}: IEducationProps) {
  const [formValue, setFormValue] = useState(initialState);
  const [files, setFiles] = useState([] as FileType[]);
  const [documents, setDocuments] = useState([] as DocumentModel[]);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    const documents: DocumentModel[] = [];
    const document = data?.degreeDocument as DocumentModel;
    if (document !== undefined && document !== null) {
      documents.push(document);
    }
    // fetch stored files
    fetchFiles(documents).then((apiFiles) => {
      setFiles(apiFiles);
    });

    // set documents and form states
    setDocuments(documents);

    setFormValue(data);
  }, [data, order]);

  const onEdit = () => {
    navigate(toEdit, { state: { data } });
  };

  if (formValue === ({} as EducationModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={EducationStyles}>
      <Form ref={formRef} readOnly formValue={formValue} fluid>
        <h4>{header}</h4>
        <Form.Group controlId='id'>
          <Form.Control id='id' type='hidden' name='id' className='Education-Input' />
        </Form.Group>
        <Form.Group controlId='degree'>
          <Form.ControlLabel>Degree:</Form.ControlLabel>
          <Form.Control id='degree' type='text' name='degree' className='Education-Input' />
        </Form.Group>
        <Form.Group controlId='degreeDocuments'>
          <Form.ControlLabel>Degree Certificate:</Form.ControlLabel>
          <Document documents={documents} files={files} />
        </Form.Group>
        <Form.Group controlId='institution'>
          <Form.ControlLabel>Institution:</Form.ControlLabel>
          <Form.Control
            id='institution'
            type='text'
            name='institution'
            className='Education-Input'
          />
        </Form.Group>
        <Form.Group controlId='dateStart'>
          <Form.ControlLabel>Date Start:</Form.ControlLabel>
          <Form.Control id='dateStart' type='text' name='dateStart' className='Education-Input' />
        </Form.Group>
        <Form.Group controlId='dateEnd'>
          <Form.ControlLabel>Date End:</Form.ControlLabel>
          <Form.Control id='dateEnd' type='text' name='dateEnd' className='Education-Input' />
        </Form.Group>

        {!plainText && (
          <Form.Group>
            <Button
              type='button'
              appearance='primary'
              className='Education-Button'
              onClick={onEdit}
            >
              Edit Education
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}

export default Education;
