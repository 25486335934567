import React from 'react';

// pages
import { Routes, Route } from 'react-router-dom';
import { Home } from '../components/pages/Home';
import { DVLottery } from '../components/pages/DVLottery';
import { About } from '../components/pages/About';
import { ContactUs } from '../components/pages/ContactUs';
import { Faqs } from '../components/pages/Faqs';
import { Status } from '../components/pages/Dashboard/Case/Status';
import { Tasks } from '../components/pages/Dashboard/Case/Tasks';
import { Documents } from '../components/pages/Dashboard/Case/Documents';
import {
  Account,
  ChangeAccount,
  ChangeEmail,
  ChangePassword,
} from '../components/pages/Settings/Account';
import { BillingEdit, BillingPage, Billings } from '../components/pages/Settings/Billings';
import { PaymentEdit, PaymentPage, Payments } from '../components/pages/Settings/Payments';
import { Administrator } from '../components/pages/Administrator';
import { Authenticator } from '../components/pages/Authenticator';
import { NotFound } from '../components/pages/NotFound';
import { RequireAuth } from '../components/pages/Authenticator/RequireAuth';
import { ContactEdit } from '../components/pages/Dashboard/Case/Application/Contact';
import { CaseEdit } from '../components/pages/Dashboard/Case';
import { ApplicationPage, ApplicationEdit } from '../components/pages/Dashboard/Case/Application';
import { Settings } from '../components/pages/Dashboard/Case/Settings';
import { AddressEdit } from '../components/pages/Dashboard/Case/Application/Address';
import { EducationEdit } from '../components/pages/Dashboard/Case/Application/Education';
import { EmploymentEdit } from '../components/pages/Dashboard/Case/Application/Employment';
import { RelationshipEdit } from '../components/pages/Dashboard/Case/Application/Relationship';
import { ProfileEdit } from '../components/pages/Dashboard/Case/Application/Profile';

function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Home />} id='home' />
      <Route path='/dvlottery' element={<DVLottery />} id='dvlottery' />
      <Route path='/about' element={<About />} id='about' />
      <Route path='/contactUs' element={<ContactUs />} id='contactUs' />
      <Route path='/faqs' element={<Faqs />} id='faqs' />
      <Route
        path='/dashboard/:userID/cases/new'
        element={
          <RequireAuth>
            <CaseEdit isEdit={false} />
          </RequireAuth>
        }
        id='caseNew'
      />
      <Route
        path='/dashboard/:userID/cases/:caseID/edit'
        element={
          <RequireAuth>
            <CaseEdit isEdit />
          </RequireAuth>
        }
        id='caseEdit'
      />
      <Route
        path='/dashboard/:userID/cases/:caseID/settings'
        element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        }
        id='general'
      />
      <Route
        path='/dashboard/:userID/cases/:caseID/status'
        element={
          <RequireAuth>
            <Status />
          </RequireAuth>
        }
        id='status'
      />
      <Route
        path='/dashboard/:userID/cases/:caseID/tasks'
        element={
          <RequireAuth>
            <Tasks />
          </RequireAuth>
        }
        id='tasks'
      />
      <Route
        path='/dashboard/:userID/cases/:caseID/documents'
        element={
          <RequireAuth>
            <Documents />
          </RequireAuth>
        }
        id='documents'
      />

      <Route
        path='/dashboard/:userID/cases/:caseID/applications/new'
        element={
          <RequireAuth>
            <ApplicationEdit isEdit={false} />
          </RequireAuth>
        }
        id='applicationNew'
      />
      <Route
        path='/dashboard/:userID/cases/:caseID/applications/:applicationID/edit'
        element={
          <RequireAuth>
            <ApplicationEdit isEdit />
          </RequireAuth>
        }
        id='applicationEdit'
      />
      <Route
        path='/dashboard/:userID/cases/:caseID/applications'
        element={
          <RequireAuth>
            <ApplicationPage />
          </RequireAuth>
        }
        id='applicationPage'
      >
        <Route
          path=':applicationID/contacts/new'
          element={
            <RequireAuth>
              <ContactEdit isEdit={false} />
            </RequireAuth>
          }
          id='contactNew'
        />
        <Route
          path=':applicationID/contacts/:formID/edit'
          element={
            <RequireAuth>
              <ContactEdit isEdit />
            </RequireAuth>
          }
          id='contactEdit'
        />
        <Route
          path=':applicationID/addresses/new'
          element={
            <RequireAuth>
              <AddressEdit isEdit={false} />
            </RequireAuth>
          }
        />
        <Route
          path=':applicationID/addresses/:formID/edit'
          element={
            <RequireAuth>
              <AddressEdit isEdit />
            </RequireAuth>
          }
        />
        <Route
          path=':applicationID/educations/new'
          element={
            <RequireAuth>
              <EducationEdit isEdit={false} />
            </RequireAuth>
          }
        />
        <Route
          path=':applicationID/educations/:formID/edit'
          element={
            <RequireAuth>
              <EducationEdit isEdit />
            </RequireAuth>
          }
        />
        <Route
          path=':applicationID/employments/new'
          element={
            <RequireAuth>
              <EmploymentEdit isEdit={false} />
            </RequireAuth>
          }
        />
        <Route
          path=':applicationID/employments/:formID/edit'
          element={
            <RequireAuth>
              <EmploymentEdit isEdit />
            </RequireAuth>
          }
        />
        <Route
          path=':applicationID/relationships/new'
          element={
            <RequireAuth>
              <RelationshipEdit isEdit={false} />
            </RequireAuth>
          }
        />
        <Route
          path=':applicationID/relationships/:formID/edit'
          element={
            <RequireAuth>
              <RelationshipEdit isEdit />
            </RequireAuth>
          }
        />
        <Route
          path=':applicationID/profiles/new'
          element={
            <RequireAuth>
              <ProfileEdit isEdit={false} />
            </RequireAuth>
          }
        />
        <Route
          path=':applicationID/profiles/:formID/edit'
          element={
            <RequireAuth>
              <ProfileEdit isEdit />
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path='/settings/:userID/account/'
        element={
          <RequireAuth>
            <Account />
          </RequireAuth>
        }
        id='account'
      />
      <Route
        path='/settings/:userID/account/change-account'
        element={
          <RequireAuth>
            <ChangeAccount />
          </RequireAuth>
        }
        id='change-account'
      />
      <Route
        path='/settings/:userID/account/change-email/update'
        element={
          <RequireAuth>
            <ChangeEmail isConfirm={false} />
          </RequireAuth>
        }
        id='change-email'
      />
      <Route
        path='/settings/:userID/account/change-email/confirm'
        element={
          <RequireAuth>
            <ChangeEmail isConfirm />
          </RequireAuth>
        }
        id='change-email'
      />
      <Route
        path='/settings/:userID/account/change-password'
        element={
          <RequireAuth>
            <ChangePassword />
          </RequireAuth>
        }
        id='change-password'
      />
      <Route
        path='/settings/:userID/billings'
        element={
          <RequireAuth>
            <Billings />
          </RequireAuth>
        }
        id='billings'
      />
      <Route
        path='/settings/:userID/billings/new'
        element={
          <RequireAuth>
            <BillingEdit isEdit={false} />
          </RequireAuth>
        }
        id='billingNew'
      />
      <Route
        path='/settings/:userID/billings/:billingID/edit'
        element={
          <RequireAuth>
            <BillingEdit isEdit />
          </RequireAuth>
        }
        id='billingEdit'
      />
      <Route
        path='/settings/:userID/billings/:billingID'
        element={
          <RequireAuth>
            <BillingPage />
          </RequireAuth>
        }
        id='billingPage'
      />
      <Route
        path='/settings/:userID/payments'
        element={
          <RequireAuth>
            <Payments />
          </RequireAuth>
        }
        id='payments'
      />
      <Route
        path='/settings/:userID/payments/new'
        element={
          <RequireAuth>
            <PaymentEdit isEdit={false} />
          </RequireAuth>
        }
        id='paymentNew'
      />
      <Route
        path='/settings/:userID/payments/:paymentID/edit'
        element={
          <RequireAuth>
            <PaymentEdit isEdit />
          </RequireAuth>
        }
        id='paymentEdit'
      />
      <Route
        path='/settings/:userID/payments/:paymentID'
        element={
          <RequireAuth>
            <PaymentPage />
          </RequireAuth>
        }
        id='paymentPage'
      />
      <Route
        path='/administrator'
        element={
          <RequireAuth>
            <Administrator />
          </RequireAuth>
        }
        id='administrator'
      />
      <Route path='/authenticator' element={<Authenticator />} id='authenticator' />
      <Route path='*' element={<NotFound />} id='not-found' />
    </Routes>
  );
}

export default AppRoutes;
