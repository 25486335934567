/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { css, jsx } from '@emotion/react';

// ui library
import 'rsuite/styles/index.less';
import { Form, Button, ButtonToolbar, Schema, Modal } from 'rsuite';

// services
import {
  amplifyUpdateEmail,
  amplifyConfirmEmail,
} from '../../../../services/AuthenticationService';

// store
import { authStore } from '../../../../stores';

const ChangeEmailStyles = css`
  .ChangeEmail {
    color: #fff;
    background: #2a2c37;
    border-radius: 6px;
    padding: 15px;

    &-Label {
      width: 300px;
    }

    &-Input {
      width: 300px;
      border: 1px solid transparent;
      color: #fff;
      background: #1d1e26;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 6px;
      outline: 0;
      &:focus {
        border-color: #50fa7b;
      }
    }
    &-Textarea {
      min-height: 80px;
      resize: none;
    }
    &-Button {
      width: 300px;
      // border: 2px solid #50fa7b;
      // color: blue;
      // background: none;
      // padding: 10px 15px;
      // border-radius: 6px;
      // outline: 0;
      // cursor: pointer;
      // font-weight: 600;
      // text-transform: uppercase;

      :disabled {
        color: grey;
      }
    }
  }
`;

interface IChangeEmailProps {
  isConfirm: boolean;
}

interface IChangeEmail {
  sub: string | null;
  email: string | null;
  code: number | null;
}

const initialState: IChangeEmail = {
  sub: '',
  email: '',
  code: null,
};

const subRule = Schema.Types.StringType().isRequired('This field is required.');
const emailRule = Schema.Types.StringType().isEmail().isRequired('This field is required.');
const codeRule = Schema.Types.NumberType().isRequired('This field is required.');

function ChangeEmail({ isConfirm }: IChangeEmailProps) {
  const { isAuthenticated, authUser } = authStore;

  const [formValue, setFormValue] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [isCodeSent, setIsCodeSent] = useState(isConfirm);
  const [isCodeSending, setIsCodeSending] = useState(false);
  const [isCodeConfirming, setIsCodeConfirming] = useState(false);

  const params = useParams();
  const userID = params?.userID || '';

  const navigate = useNavigate();

  const formRef = useRef() as any;

  const currentData: IChangeEmail = {
    sub: authUser.attributes.sub,
    email: authUser.attributes.email,
    code: null,
  };

  useEffect(() => {
    setFormValue({
      ...currentData,
    });
  }, []);

  const onChange = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const onCheck = (error: any) => {
    if (Object.keys(error).length > 0) {
      setDisabled(true);
      setFormError({ ...formError, ...error });
    } else {
      setDisabled(false);
    }
  };

  const handlerError = (error: any) => {
    window.alert(`Error occurs: ${JSON.stringify(error)}`);
  };

  const onClear = () => {
    if (!window.confirm(`Really clear the form`)) {
      return;
    }
    setFormValue(initialState);
  };

  const onUpdate = async () => {
    // update
    setIsCodeSending(true);
    try {
      await amplifyUpdateEmail({ email: formValue.email });
      setIsCodeSent(true);
      // navigate
      navigate(`/settings/${userID}/account/change-email/confirm`);
    } catch (error) {
      handlerError(error);
      setIsCodeSending(false);
      // navigate
      navigate(`/settings/${userID}/account/`);
    }
  };

  const onConfirm = async () => {
    // check form on update
    if (!formRef.current.check()) {
      return;
    }
    setIsCodeConfirming(true);
    try {
      await amplifyConfirmEmail(formValue.code);
      setModalOpen(false);
      // navigate
      navigate(`/settings/${userID}/account/`);
    } catch (error) {
      handlerError(error);
      setIsCodeConfirming(false);
      // navigate
      navigate(`/settings/${userID}/account/`);
    }
  };

  const onCancelUpdate = () => {
    setFormValue(currentData);
    setModalOpen(false);
    // navigate
    navigate(`/settings/${userID}/account/`);
  };

  // const onDelete = async () => {
  //   // confirm action
  //   if (!window.confirm(`Really delete ${formValue.sub}`)) {
  //     return;
  //   }
  //   // delete
  //   try {
  //     await amplifyDeleteEmail();
  //     setModalOpen(false);

  //     // navigate
  //     navigate('/');
  //   } catch (error) {
  //     handlerError(error);
  //   }
  // };

  const onModalClose = () => {
    if (!window.confirm(`Really want to close the modal`)) {
      return;
    }
    setFormValue(currentData);
    setModalOpen(false);
    // navigate
    navigate(`/settings/${userID}/account/`);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  if (formValue === ({} as IChangeEmail)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={ChangeEmailStyles}>
      <Modal open={modalOpen} onClose={onModalClose} size='md'>
        <Modal.Header>
          <Modal.Title>Edit an existing account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={onChange}
            onCheck={onCheck}
            fluid
            className='ChangeEmail-Form'
          >
            <h4>Account | Email</h4>
            {!isCodeSent ? (
              <>
                <Form.Group controlId='sub'>
                  <Form.ControlLabel>ID</Form.ControlLabel>
                  <Form.Control
                    id='sub'
                    type='text'
                    name='sub'
                    className='ChangeEmail-Input'
                    disabled
                    rule={subRule}
                  />
                </Form.Group>
                <Form.Group controlId='email'>
                  <Form.ControlLabel>Firstname</Form.ControlLabel>
                  <Form.Control
                    id='email'
                    type='text'
                    name='email'
                    className='ChangeEmail-Input'
                    rule={emailRule}
                  />
                </Form.Group>
              </>
            ) : (
              <Form.Group controlId='code'>
                <Form.ControlLabel>Confirmation Code</Form.ControlLabel>
                <Form.Control
                  id='code'
                  type='text'
                  name='code'
                  className='ChangeEmail-Input'
                  rule={codeRule}
                />
                <Form.HelpText>
                  Please check your email ({formValue.email}) for the confirmation code.
                </Form.HelpText>
              </Form.Group>
            )}
            <Form.Group>
              <ButtonToolbar>
                {!isCodeSent ? (
                  <>
                    <Button
                      type='button'
                      className={`ChangeEmail-Button ${disabled ? 'disabled' : ''}`}
                      onClick={onUpdate}
                      disabled={disabled}
                    >
                      Update
                    </Button>
                    <Button type='button' className='ChangeEmail-Button' onClick={onCancelUpdate}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    type='button'
                    className='ChangeEmail-Button'
                    onClick={onConfirm}
                    disabled={disabled}
                  >
                    Confirm
                  </Button>
                )}
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChangeEmail;
