import { observer } from 'mobx-react';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import 'rsuite/styles/index.less';
import { authStore } from '../../../stores';

import MainLayout from '../../layout/MainLayout/MainLayout';

function Applications() {
  const { isAuthenticated } = authStore;
  return <MainLayout headingText='Application'>Dashboard Content</MainLayout>;
}
export default observer(Applications);