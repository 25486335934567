/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Button, ButtonToolbar, Form, Modal, Schema, SelectPicker } from 'rsuite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// dataStore
import { DataStore } from 'aws-amplify';
import {
  Application as ApplicationModel,
  Contact as ContactModel,
  Relationship as RelationshipModel,
  Profile as ProfileModel,
  Education as EducationModel,
  Employment as EmploymentModel,
  Address as AddressModel,
} from '../../../../../models';

// store
import { authStore } from '../../../../../stores';

const ApplicationEditStyles = css`
  .ApplicationEdit {
    color: #fff;
    background: #2a2c37;
    border-radius: 6px;
    padding: 15px;

    &-Label {
      width: 100%;
    }

    &-Input {
      width: 100%;
      border: 1px solid transparent;
      color: #fff;
      background: #1d1e26;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 6px;
      outline: 0;
      &:focus {
        border-color: #50fa7b;
      }
    }
    &-Textarea {
      min-height: 80px;
      resize: none;
    }
    &-Button {
      border: 2px solid #50fa7b;
      color: #50fa7b;
      background: none;
      padding: 10px 15px;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      font-weight: 600;
      text-transform: upperApplication;

      :disabled {
        color: grey;
      }
    }
  }
`;

const typeSelectData = ['main', 'spouse', 'children', 'parents', 'siblings', 'fiance'].map(
  (item) => ({
    label: item,
    value: item,
  }),
);

interface IApplicationEditProps {
  isEdit: boolean;
}

const initialState: ApplicationModel = {
  id: '',
  type: '',
  caseID: '',
};

const model = Schema.Model({
  type: Schema.Types.StringType().isRequired('This field is required'),
});

function ApplicationEdit({ isEdit }: IApplicationEditProps) {
  const { isAuthenticated, authUser } = authStore;

  const [formValue, setFormValue] = useState({} as ApplicationModel);
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [canEdit, setCanEdit] = useState(false);

  const { state } = useLocation();

  const data: ApplicationModel = state?.data;

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    if (!isEdit) {
      const { caseID } = data;
      setFormValue({ ...initialState, caseID });
    } else {
      if (data.type === 'main') {
        setCanEdit(true);
      }
      setFormValue({ ...data });
    }
  }, [data]);

  const onChange = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const onCheck = (error: any) => {
    if (Object.keys(error).length > 0) {
      setDisabled(true);
      setFormError({ ...formError, ...error });
    } else {
      setDisabled(false);
    }
  };

  const handlerError = (error: any) => {
    window.alert(`Error occurs: ${JSON.stringify(error)}`);
  };

  const onCreate = async (e: any) => {
    formRef.current.check(async (error: any) => {
      // check error
      if (Object.keys(error).length > 0) {
        setDisabled(true);
        setFormError({ ...formError, ...error });
        return;
      }
      // create
      try {
        // create an application
        const currentItem = await DataStore.save(new ApplicationModel(formValue));

        // create a contact
        const contact = await DataStore.save(
          new ContactModel({
            applicationID: currentItem.id,
          }),
        );

        // // create a relationship
        // const relationship = await DataStore.save(
        //   new RelationshipModel({
        //     applicationID: currentItem.id,
        //   }),
        // );

        // create an application
        const profile = await DataStore.save(
          new ProfileModel({
            applicationID: currentItem.id,
          }),
        );

        // create an address
        const address = await DataStore.save(
          new AddressModel({
            applicationID: currentItem.id,
          }),
        );

        // create an employment
        const employment = await DataStore.save(
          new EmploymentModel({
            applicationID: currentItem.id,
          }),
        );

        // create an education
        const education = await DataStore.save(
          new EducationModel({
            applicationID: currentItem.id,
          }),
        );

        setDisabled(false);
        setModalOpen(false);
        console.log('A Application was successfully created');

        // navigate
        navigate(-1);
      } catch (error) {
        handlerError(error);
      }
    });
  };

  const onClear = () => {
    if (!window.confirm(`Really clear the form`)) {
      return;
    }
    setFormValue(initialState);
  };

  const onCancelCreate = () => {
    if (!window.confirm(`Really cancel the form creation`)) {
      return;
    }
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onUpdate = async () => {
    // check form on update
    if (!formRef.current.check()) {
      return;
    }
    // delete
    try {
      /* Models in DataStore are immutable. To update a record you must use the copyOf function
      to apply updates to the item’s fields rather than mutating the instance directly */
      const currentItem: any = await DataStore.query(ApplicationModel, formValue.id);
      await DataStore.save(
        ApplicationModel.copyOf(currentItem, (updated) => {
          updated.type = formValue.type || '';
        }),
      );
      setModalOpen(false);

      // navigate
      navigate(-1);
    } catch (error) {
      handlerError(error);
    }
  };

  const onCancelUpdate = () => {
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onDelete = async () => {
    // confirm action
    if (!window.confirm(`Really delete ${formValue.id}`)) {
      return;
    }
    // delete
    try {
      const modelToDelete: any = await DataStore.query(ApplicationModel, formValue.id);
      DataStore.delete(modelToDelete);
      setModalOpen(false);

      // navigate
      navigate(-2);
    } catch (error) {
      handlerError(error);
    }
  };

  const onModalClose = () => {
    if (!window.confirm(`Really want to close the modal`)) {
      return;
    }
    setFormValue(data);
    setModalOpen(false);
    navigate(-1);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  if (formValue === ({} as ApplicationModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={ApplicationEditStyles}>
      <Modal open={modalOpen} onClose={onModalClose} size='md'>
        <Modal.Header>
          <Modal.Title>
            {isEdit ? 'Edit an existing Application' : 'Create a new Application'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={onChange}
            onCheck={onCheck}
            model={model}
            fluid
          >
            <h2>Application</h2>
            <Form.Group controlId='id'>
              <Form.ControlLabel>Id:</Form.ControlLabel>
              <Form.Control
                id='id'
                type='text'
                name='id'
                className='ApplicationEdit-Input'
                disabled
              />
            </Form.Group>
            <Form.Group controlId='type'>
              <Form.ControlLabel>Type:</Form.ControlLabel>
              <Form.Control
                id='type'
                accepter={SelectPicker}
                data={typeSelectData}
                name='type'
                className='Application-Input'
                // disabled={isEdit}
              />
            </Form.Group>
            <Form.Group controlId='caseID'>
              <Form.ControlLabel>Case ID:</Form.ControlLabel>
              <Form.Control
                id='caseID'
                type='text'
                name='caseID'
                className='Application-Input'
                disabled
              />
            </Form.Group>
            <Form.Group controlId='_version'>
              <Form.Control
                id='_version'
                type='hidden'
                name='_version'
                className='ApplicationEdit-Input'
              />
            </Form.Group>
            {!isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`ApplicationEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onCreate}
                    disabled={disabled}
                  >
                    Create
                  </Button>
                  <Button type='button' className='ApplicationEdit-Button' onClick={onClear}>
                    Clear
                  </Button>
                  <Button type='button' className='ApplicationEdit-Button' onClick={onCancelCreate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}

            {isEdit && (
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    type='button'
                    className={`ApplicationEdit-Button ${disabled ? 'disabled' : ''}`}
                    onClick={onUpdate}
                    disabled
                  >
                    Update
                  </Button>
                  <Button
                    type='button'
                    className='ApplicationEdit-Button'
                    onClick={onDelete}
                    disabled={!canEdit}
                  >
                    Delete
                  </Button>
                  <Button type='button' className='ApplicationEdit-Button' onClick={onCancelUpdate}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ApplicationEdit;
