/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { Button, Form } from 'rsuite';
import { Billing as BillingModel } from '../../../../models';

const BillingStyles = css`
  // color: #fff;
  // background: #2a2c37;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #e5e5ea;

  .Billing {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

interface IBillingProps {
  data?: BillingModel;
  order?: number;
  header?: any;
  toEdit?: string;
  plainText?: boolean;
}

const initialState: BillingModel = {
  id: '',
  amount: '',
  dateBilling: '',
  dateDue: '',
  userID: '',
};

function Billing({
  data = initialState,
  order = 0,
  header = `Billing ${order + 1}`,
  toEdit = 'edit',
  plainText = false,
}: IBillingProps) {
  const [formValue, setFormValue] = useState(initialState);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    setFormValue(data);
  }, [data, order]);

  const onEdit = () => {
    navigate(toEdit, { state: { data } });
  };

  if (formValue === ({} as BillingModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={BillingStyles}>
      <Form ref={formRef} readOnly formValue={formValue} fluid>
        <h4>{header}</h4>
        <Form.Group controlId='id'>
          <Form.Control id='id' type='hidden' name='id' className='Billing-Input' />
        </Form.Group>
        <Form.Group controlId='amount'>
          <Form.ControlLabel>Amount:</Form.ControlLabel>
          <Form.Control id='amount' type='text' name='amount' className='Billing-Input' />
        </Form.Group>
        <Form.Group controlId='dateDue'>
          <Form.ControlLabel>Date Due:</Form.ControlLabel>
          <Form.Control id='dateDue' type='text' name='dateDue' className='Billing-Input' />
        </Form.Group>
        <Form.Group controlId='dateBilling'>
          <Form.ControlLabel>Date Billing:</Form.ControlLabel>
          <Form.Control id='dateBilling' type='text' name='dateBilling' className='Billing-Input' />
        </Form.Group>
        <Form.Group controlId='userID'>
          <Form.Control id='userID' type='hidden' name='userID' className='Billing-Input' />
        </Form.Group>
        {!plainText && (
          <Form.Group>
            <Button type='button' appearance='primary' className='Billing-Button' onClick={onEdit}>
              Edit Billing
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}

export default Billing;
