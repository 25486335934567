/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { css, jsx } from '@emotion/react';
import { Button, Form } from 'rsuite';
import { Relationship as RelationshipModel } from '../../../../../../models';

const RelationshipStyles = css`
  // color: #fff;
  // background: #2a2c37;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #e5e5ea;

  .Relationship {
    // &-Label {
    //   width: 100%;
    // }

    // &-Input {
    //   width: 100%;
    //   border: 1px solid transparent;
    //   color: #fff;
    //   background: #1d1e26;
    //   padding: 10px 15px;
    //   margin-bottom: 5px;
    //   border-radius: 6px;
    //   outline: 0;
    //   &:focus {
    //     border-color: #50fa7b;
    //   }
    // }
    // &-Textarea {
    //   min-height: 80px;
    //   resize: none;
    // }
    // &-Button {
    //   border: 2px solid #50fa7b;
    //   color: #50fa7b;
    //   background: none;
    //   padding: 10px 15px;
    //   border-radius: 6px;
    //   outline: 0;
    //   cursor: pointer;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   nnn :disabled {
    //     color: grey;
    //   }
    // }
  }
`;

interface IRelationshipProps {
  data?: RelationshipModel;
  order?: number;
  header?: any;
  toEdit?: string;
  plainText?: boolean;
}

const initialState: RelationshipModel = {
  id: '',
  type: '',
  matchApplicationID: '',
  applicationID: '',
};

function Relationship({
  data = initialState,
  order = 0,
  header = `Relationship ${order + 1}`,
  toEdit = 'edit',
  plainText = false,
}: IRelationshipProps) {
  const [formValue, setFormValue] = useState(initialState);

  const navigate = useNavigate();

  const formRef = useRef() as any;

  useEffect(() => {
    setFormValue(data);
  }, [data, order]);

  const onEdit = () => {
    navigate(toEdit, { state: { data } });
  };

  if (formValue === ({} as RelationshipModel)) {
    return <div>Loading...</div>;
  }

  return (
    <div css={RelationshipStyles}>
      <Form ref={formRef} readOnly formValue={formValue} fluid>
        <h4>{header}</h4>
        <Form.Group controlId='id'>
          <Form.Control id='id' type='hidden' name='id' className='Relationship-Input' />
        </Form.Group>
        <Form.Group controlId='type'>
          <Form.ControlLabel>Type:</Form.ControlLabel>
          <Form.Control id='type' type='text' name='type' className='Relationship-Input' />
        </Form.Group>
        <Form.Group controlId='matchApplicationID'>
          <Form.ControlLabel>Match Application ID:</Form.ControlLabel>
          <Form.Control
            id='matchApplicationID'
            type='text'
            name='matchApplicationID'
            className='Relationship-Input'
          />
        </Form.Group>
        <Form.Group controlId='applicationID'>
          <Form.ControlLabel>Applcation ID:</Form.ControlLabel>
          <Form.Control
            id='applicationID'
            type='text'
            name='applicationID'
            className='Relationship-Input'
          />
        </Form.Group>
        {!plainText && (
          <Form.Group>
            <Button
              type='button'
              appearance='primary'
              className='Relationship-Button'
              onClick={onEdit}
            >
              Edit Relationship
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}

export default Relationship;
